import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import thunk from "redux-thunk";

import customersReducers from "./reducers/customersReducers";
import invoicesReducers from "./reducers/invoicesReducers";
import itemsReducers from "./reducers/itemsReducers";
import { userData } from "./reducers/UserData";
import userReducers from "./reducers/userReducers";
import { userLoginStatusReducer } from "./reducers/usersReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  combineReducers({
    customersReducers,
    invoicesReducers,
    itemsReducers,
    userLoginStatusReducer,
    userReducers,
    userData: userData,
  }),
  composeEnhancers(applyMiddleware(thunk))
);
