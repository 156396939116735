import React, { useEffect, useState } from "react";
import EditProfileImage from "./EditProfileImage";
import { validationSchema } from "../../common/validate/Profile";
import { Formik } from "formik";
import { UserProfileAPI, UserProfileChange } from "../../services/helpers/users";
import Loader from "../common/Loader";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../store/actions/UserActions";
import { CircularProgress } from "@mui/material";
import CustomEditor from "../shared/CustomEditor";

let initialValues = {
  name: "",
  number: "",
  aadharnumber: "",
  pancard: "",
  gstin: "",
  website: "",
};

const ProfileEditForm = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [imgfile, setImgFile] = useState(null);
  const [userProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [profileInitialValues, setProfileInitialValues] = useState(initialValues);
  const dispatch = useDispatch();
  const [fileUploadError, setFileUploadError] = useState(false)
  const [termsAndCondition, setTermsAndConditions] = useState(``);

  const submitForm = (values) => {
    setIsSubmit(true);
    

    const form = new FormData();
    form.append("userId", Cookies.get("userId"));
    form.append("first_name", values.name || "");
    form.append("last_name", userProfile.last_name || "");
    form.append("contact_number", values.number || "");
    // form.append("aadhar_number", values.aadharnumber || "");
    form.append("trade_name", values.tradename || "");
    form.append("address", values.address || "");
    form.append("gst_in", values.gstin || "");
    form.append("pan_card", values.pancard || "");
    form.append("website", values.website || "");
    form.append("terms", termsAndCondition);

    if (imgfile) {
      form.append("file", imgfile);
    }

    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };

    UserProfileChange(form, paramsData, (res) => {
      setIsSubmit(false);
      if (res.status === true) {
        setLoading(true)
        getProfile();
        dispatch(updateUserProfile(true));
      } else {
        setFileUploadError(!fileUploadError)
        setLoading(true)
        setImgFile(null)
      }
    });
  };

  useEffect(() => {
    getProfile();
  }, [fileUploadError]);

  const getProfile = () => {
    const queryParams = {
      userId: Cookies.get("userId"),
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    UserProfileAPI(paramsData, (res) => {
      const user_data = {
        name: res.data.first_ame || "",
        number: Number.parseInt(res.data.contact_number) || "",
        tradename: res?.data?.trade_name || "",
        address: res?.data?.address || "",
        pancard: res.data.pan_card || "",
        gstin: res.data.gst_in || "",
        website: res?.data?.website || ""
      }

     setTermsAndConditions(res?.data?.terms);
     setProfileInitialValues(user_data);
      // setPreviewImage(res.data.img_url)
      setUserProfile(res.data);
      setLoading(false);
    });
  }

  return (
    <div className="text-black relative md:pt-32 pb-4 py-4 px-4  min-h-screen">
      {loading ? (
        <Loader classes="min-h-screen" />
      ) : (
        <>
        
          <EditProfileImage previewImage={previewImage} setPreviewImage={setPreviewImage} imgfile={imgfile} setImgFile={setImgFile} userProfile={userProfile} />
          <div className="mt-8">
            <Formik initialValues={profileInitialValues} enableReinitialize={true} validationSchema={validationSchema} onSubmit={submitForm} >
              {(formik) => {
                const { values, handleChange, handleSubmit, errors, touched, handleBlur } = formik;
                return (
                  <form
                    className="w-full  rounded-md rounded-t-none p-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="flex gap-4 -lg:flex-col">
                      <div className="mb-6 grow shrink basis-0">
                        <label htmlFor="name" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                          Contact Name
                        </label>
                        <input type="text" name="name" id="name" className="text-f-style" placeholder="Enter your Name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
                        {errors.name && touched.name && <span className="text-sm text-red-400">{errors.name}</span>}
                      </div>
                      <div className="mb-6 grow shrink basis-0">
                        <label htmlFor="number" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                          Contact Number
                        </label>
                        <input
                          type="number"
                          id="number"
                          name="number"
                          className="text-f-style"
                          placeholder="Enter your Number"
                          value={values.number ? values.number : "" }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.number && touched.number && <span className="text-sm text-red-400">{errors.number}</span>}
                      </div>
                    </div>
                    <div className="flex gap-4 -lg:flex-col">
                      <div className="mb-6 grow shrink basis-0">
                        <label htmlFor="tradename" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                          Trade Name
                        </label>
                        <input
                          type="text"
                          id="tradename"
                          name="tradename"
                          className="text-f-style"
                          placeholder="Enter your Trade Name"
                          value={values.tradename}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.tradename && touched.tradename && <span className="text-sm text-red-400">{errors.tradename}</span>}
                      </div>
                     
                      <div className="mb-6 grow shrink basis-0">
                        <label htmlFor="address" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                          Address
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          className="text-f-style"
                          placeholder="Enter your Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address && touched.address && <span className="text-sm text-red-400">{errors.address}</span>}
                      </div>
                     
                    </div>

                    <div className="flex gap-4 -lg:flex-col">
                      <div className="mb-6 grow shrink basis-0">
                        <label htmlFor="gstin" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                          GSTIN
                        </label>
                        <input type="text" id="gstin" name="gstin" className="text-f-style" placeholder="Enter your GSTIN " value={values.gstin} onChange={handleChange} onBlur={handleBlur} />
                        {errors.gstin && touched.gstin && <span className="text-sm text-red-400">{errors.gstin}</span>}
                      </div>
                      <div className="mb-6 grow shrink basis-0">
                        <label htmlFor="pancard" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                          Pan Card
                        </label>
                        <input
                          type="text"
                          id="pancard"
                          name="pancard"
                          className="text-f-style"
                          placeholder="Enter your Pan Card"
                          value={values.pancard}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.pancard && touched.pancard && <span className="text-sm text-red-400">{errors.pancard}</span>}
                      </div>
                    </div>
                    <div className="flex gap-4 -lg:flex-col">
                      <div className="mb-6 grow shrink basis-0">
                        <label htmlFor="website" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                          Website
                        </label>
                        <input type="text" id="website" name="website" className="text-f-style" placeholder="http://www.example.com" value={values.website} onChange={handleChange} onBlur={handleBlur} />
                        {errors.website && touched.website && <span className="text-sm text-red-400">{errors.website}</span>}
                      </div>
                    </div>
                    
                    <div className="flex gap-4 -lg:flex-col">
                      <div className="mb-6 grow shrink basis-0">
                        <label htmlFor="termsandcondition" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                        Terms and Conditions
                        </label>
                        <CustomEditor data={termsAndCondition} setData={setTermsAndConditions} setRaw={()=>{}}/>
                      </div>
                    </div>
                    

                    <div className="flex w-full justify-center mb-4 mt-12">
                      {isSubmit ? (
                        <button type="submit" className="text-f-submit w-[86px] py-2" disabled={isSubmit}>
                          <CircularProgress color="inherit" style={{ width: "18px", height: "18px" }} />
                        </button>
                      ) : (
                        <button type="submit" className="text-f-submit">
                          Update
                        </button>
                      )}
                    </div>
                  </form>
                );
              }}
            
            </Formik>
            
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileEditForm;
