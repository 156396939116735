const initialState = {
    isprofilechanged: false,
  };
  
  function UserReducers(state = initialState, action) {
    switch (action.type) {
      case "UserUpdate":
        return {
          ...state,
          isprofilechanged: action.payload,
        };
  
      default:
        return state;
    }
  }

  export default UserReducers;