import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { initialValues, validationSchema } from "../../../common/validate/Invoices/AddInvoices";

import { useNavigate } from "react-router-dom";
import { TextFieldDate } from "../../common/Field/TextField";
import { Autocomplete, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TextField as TextFieldMUI } from "@mui/material";
import { Box } from "@mui/system";
import RowTable from "./RowTable";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "./add.css";
import AddressModal from "./AddressModal";
import { CustomerContactList, CustomerDetailById } from "../../../services/helpers/customers";
import { ProductAllList } from "../../../services/helpers/products";
import { AddInvoiceAPI } from "../../../services/helpers/invoices";
import { errorToast2 } from "../../Toast";
import Cookies from "js-cookie";
import { TaxListAPI } from "../../../services/helpers/common";
import InvoiceForm from "./InvoiceForm";
const initialItem = {
  productUniqueId: "",
  productName: "",
  unit: "",
  rate: 0,
  sellingPrice: 0,
  productDescription: "",
  quantity: 0,
  description: ''
  // gst_id: 1,
};

const AddInvoice = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [subTotalV, setSubTotalV] = useState(0);

  const [itemlist, setItemList] = useState([initialItem]);
  const [address, setAddress] = useState({ type: 1, address: [] });

  const [itemListApi, setItemListApi] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [taxList, setTaxList] = useState([]);

  const navigate = useNavigate();

  const submitForm = (values) => {
    const { customer_id, invoice_date, terms, due_date, tax, tax_type, order_number, purchase_order, invoiceId } = values;
    const itemLists = itemlist
      ?.filter((item) => item.productUniqueId.length > 0)
      ?.map((item) => {
        return { productId: item.productUniqueId, quantity: item.quantity, gstPercentage: item.gst_percentage, description: item.description };
      });

    const payload = {
      userId: Cookies.get("userId"),
      customerId: customer_id,
      invoiceDate: invoice_date,
      invoiceTerms: terms,
      invoiceDueDate: due_date,
      // customerNotes: subject,
      tax: tax_type,
      taxType: tax,
      orderNumber: order_number,
      purchaseOrder: purchase_order,
      itemDetails: itemLists,
      invoiceId,
    };
    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    if (itemLists?.length > 0) {
      // const invalidItemGST = itemlist?.filter((item) => item.productUniqueId.length > 0)?.filter((gst) => gst.gst_id === undefined);
      const invalidItemGST = itemlist?.filter((item) => item.gst_percentage === undefined && item.productUniqueId !== "");
      if (invalidItemGST?.length > 0) {
        setItemList((prev) => {
          return prev.map((item, i) => {
            if (item.gst_percentage || item.gst_error) {
              let { gst_error, ...rest } = item;
              return {
                ...rest,
              };
            } else {
              return {
                ...item,
                gst_error: "Please select tax",
              };
            }
          });
        });
      } else {
        setIsSubmit(true);
        AddInvoiceAPI(payload, paramsData, (res) => {
          setIsSubmit(false);
          if (res?.status == true) {
            setTimeout(() => {
              navigate("/invoices");
            }, 200);
          }
        });
      }
    } else {
      errorToast2("At least select one item");
      setIsSubmit(false);
    }
  };

  const addItemHandle = () => {
    const item = itemlist.filter((item) => item.productUniqueId === "");
    if (item.length > 0) {
      alert("Please select item, first");
    } else {
      setItemList((prev) => [...prev, initialItem]);
    }
  };

  const updateItemList = (index, value) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          // return { ...value,productUniqueId: value.productUniqueId, total: value.sellingPrice * 1 * (value.quantity * 1) };
          return {
            ...value,
            productUniqueId: value.productUniqueId,
            total: value.sellingPrice * 1 * (value.quantity ? value.quantity : 1 * 1),
            quantity: value.quantity ? value.quantity : 1,
          };
        }
        return item;
      });
    });
  };

  const updateItemQuantity = (index, value) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          let calculatePercentage = item.gst_percentage ? item.sellingPrice * 1 * (value * 1) : item.sellingPrice * 1 * (value * 1);
          return {
            ...item,
            quantity: value,
            total: calculatePercentage,
          };
        }
        return item;
      });
    });
  };

  const updateItemDescription = (index, value) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            description: value,
          };
        }
        return item;
      });
    });
  }

  const updateItemPercentage = (index, value) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          let { gst_error, ...rest } = item;
          return {
            ...rest,
            gst_percentage: value?.percentage,
          };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    let TotalWithPercentage =
      itemlist.reduce(function (acc, item) {
        if ((item?.gst_percentage || item?.gst_percentage == 0) && item.quantity && item.sellingPrice) {
          return acc * 1 + item.sellingPrice * item.quantity + (item.gst_percentage / 100) * (item.sellingPrice * item.quantity);
        } else {
          return acc * 1;
        }
      }, 0) * 1;

    let subt =
      itemlist.reduce(function (acc, obj) {
        // console.log('reduce  checking item list ===>', acc, obj)
        if (obj.productUniqueId?.length > 0) {
          return acc * 1 + obj.total * 1;
        } else return acc * 1;
      }, 0) * 1;
    setSubTotalV(TotalWithPercentage);
  }, [itemlist]);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const AddressHandle = (e, type) => {
    e.preventDefault();
    handleOpen();
    setAddress((prev) => {
      return { address: prev.address, type: type };
    });
  };

  useEffect(() => {
    const paramsData = { queryParams: { userId: Cookies.get("userId") }, headers: {}, isAuthorized: true };
    CustomerContactList(paramsData, (res) => {
      const contactlist = res?.data.filter((item) => item?.is_deleted !== 1);
      setContactList(contactlist);
    });

    // const paramsDataTax = {
    //   queryParams: {},
    //   headers: {},
    //   isAuthorized: true,
    // };

    // TaxListAPI(paramsDataTax, (res) => {
    //   setTaxList(res?.data);
    // });

    const paramsDataP = { queryParams: { userId: Cookies.get("userId") }, headers: {}, isAuthorized: true };
    ProductAllList(paramsDataP, (res) => {
      setItemListApi(res?.data);
    });
  }, []);

  const getCustomerAddressHandle = (id) => {
    const queryParams = {
      customerId: id,
      userId: Cookies.get("userId"),
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    CustomerDetailById(paramsData, (res) => {
      setAddress((prev) => {
        return { address: res.data.customerAddress, type: prev.type };
      });
    });
  };
  return (
    <InvoiceForm
      getCustomerAddressHandle={getCustomerAddressHandle}
      AddressHandle={AddressHandle}
      handleClose={handleClose}
      handleOpen={handleOpen}
      updateItemPercentage={updateItemPercentage}
      updateItemQuantity={updateItemQuantity}
      updateItemList={updateItemList}
      addItemHandle={addItemHandle}
      submitForm={submitForm}
      taxList={taxList}
      contactList={contactList}
      itemListApi={itemListApi}
      address={address}
      itemlist={itemlist}
      subTotalV={subTotalV}
      modalOpen={modalOpen}
      isSubmit={isSubmit}
      setModalOpen={setModalOpen}
      setItemList={setItemList}
      updateItemDescription={updateItemDescription}
    />
  );
};

export default AddInvoice;
