import Customers from "../../pages/Customers";
import CustomerAdd from "../../pages/Customers/CustomerAdd";
import Dashboard from "../../pages/Dashboard";
import Login from "../../pages/Login";
import Profile from "../../pages/Profile";
import Register from "../../pages/Register";
import CustomerUpdate from "../../pages/Customers/CustomerUpdate";
import InvoiceAdd from "../../pages/Invoices/InvoiceAdd";
import ItemAdd from "../../pages/Items/ItemAdd";
import Invoices from "../../pages/Invoices";
import Items from "../../pages/Items";
import ItemUpdate from "../../pages/Items/ItemUpdate";
import Reset from "../../pages/reset";
import ResetPassword from "../../pages/reset/ResetPassword";
import InvoiceUpdate from "../../pages/Invoices/InvoiceUpdate";

const routes = [
  {
    id: "login",
    path: "/login",
    component: <Login />,
  },
  {
    id: "forgot_password",
    path: "/forgot-password",
    component: <Reset />,
  },
  {
    id: "reset_password",
    path: "/reset-password/:userid/:hash",
    component: <ResetPassword />,
  },
  {
    id: "register",
    path: "/register",
    component: <Register />,
  },
  {
    id: "dashboard",
    path: "/dashboard",
    component: <Dashboard />,
    protectedRoute: true,
  },
  {
    id: "customers",
    path: "/customers",
    component: <Customers />,
    protectedRoute: true,
  },
  {
    id: "customeradd",
    path: "/customer/new",
    component: <CustomerAdd />,
    protectedRoute: true,
  },
  {
    id: "customerupdate",
    path: "/customer/:id",
    component: <CustomerUpdate />,
    protectedRoute: true,
  },
  {
    id: "invoices",
    path: "/invoices",
    component: <Invoices />,
    protectedRoute: true,
  },
  {
    id: "invoiceadd",
    path: "/invoice/new",
    component: <InvoiceAdd />,
    protectedRoute: true,
  },
  {
    id: "invoiceupdate",
    path: "/invoice/:id",
    component: <InvoiceUpdate />,
    protectedRoute: true,
  },
  {
    id: "items",
    path: "/items",
    component: <Items />,
    protectedRoute: true,
  },
  {
    id: "itemadd",
    path: "/item/new",
    component: <ItemAdd />,
    protectedRoute: true,
  },
  {
    id: "itemupdate",
    path: "/item/:id",
    component: <ItemUpdate />,
    protectedRoute: true,
  },
  // {
  //   id: "customeradd",
  //   path: "/customer/:id",
  //   component: <CustomerUpdate />,
  //   protectedRoute: true,
  // },
  {
    id: "profile",
    path: "/profile",
    component: <Profile />,
    protectedRoute: true,
  },
];

export default routes;
