import * as yup from "yup";
import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdExpandMore } from "react-icons/md";
import UPIForm from "./UPIForm";
import { Formik } from "formik";
import { IoClose } from "react-icons/io5";
import { CircularProgress } from "@mui/material";
import { UserUPIUpdate } from "../../../services/helpers/users";
import Cookies from "js-cookie";
import { upiIDValidate, upiNumberValidate, upiTypeValidate } from "../../../common/helpers/accountValidate";
import { UPI_Error } from "../../../mockData";
import { errorToast } from "../../Toast";

export default function UPIList({ UPIDATA, setUPIDATA, RemoveUPIHandle, setLoading, setUPIRefrech }) {
  const [isSubmit, setIsSubmit] = useState(false);
  let defaultError = {
    upi_id: {},
    upi_number: {},
    upi_type: {},
  };
  const [errorUPI, setErrorUPI] = useState(UPI_Error);

  const submitForm = () => {
    setErrorUPI((prev) => UPI_Error);
    let newUPIDATA = UPIDATA.filter((data) => data.upi_id !== "" || data.upi_number !== "" || data.upi_type !== "");

    newUPIDATA.forEach((data, index) => {
      const UPIID = upiIDValidate(data.upi_id);
      const UPINumber = upiNumberValidate(data.upi_id);
      const UPITYPE = upiTypeValidate(data.upi_id);
      if (UPIID) {
        setErrorUPI((prev) => {
          return { ...prev, upi_id: { ...prev.upi_id, [data.id_r]: UPIID?.Error } };
        });
      }
      if (UPINumber) {
        setErrorUPI((prev) => {
          return { ...prev, upi_number: { ...prev.upi_number, [data.id_r]: UPINumber?.Error } };
        });
      }
      if (UPITYPE) {
        setErrorUPI((prev) => {
          return { ...prev, upi_type: { ...prev.upi_type, [data.id_r]: UPITYPE?.Error } };
        });
      }
    });

    setTimeout(() => {
      if (errorExpandedHandle() === null) {
        if (newUPIDATA.length > 0) {
          setIsSubmit(true);
          let upiDetails = newUPIDATA.map((upi) => {
            const { id_r, ...upis } = upi;
            return { ...upis, id: upi?.id || "" };
          });
          const payload = {
            user_id: Cookies.get("userId"),
            upiDetails: upiDetails,
          };
          const paramsData = {
            queryParams: {},
            headers: {},
            isAuthorized: true,
          };
          UserUPIUpdate(payload, paramsData, (res) => {
            setIsSubmit(false);
            if (res.status === true) {
              setUPIRefrech((prev) => !prev);
            }
          });
        } else {
          errorToast("Please fill account details");
        }
      } else {
        errorToast("Please check account details");
      }
    }, 200);

    // newUPIDATA.length < 1 &&
    //   newUPIDATA.push({
    //     upi_id: "",
    //     g_pay: "",
    //     phone_pay: "",
    //     paytm: "",
    //   });
  };

  const HandleChangeText = (e, id) => {
    let value = e.target.value;
    let name = e.target.name;
    setUPIDATA((prev) => {
      let newPrev = prev.map((upiprev, i) => {
        let checkChnage = upiprev.id_r === id;
        return checkChnage
          ? {
              ...upiprev,
              [name]: value,
            }
          : { ...upiprev };
      });
      return newPrev;
    });
  };

  const changeUPIType = (e, id) => {
    let value = e.target.value;
    let name = e.target.name;
    setUPIDATA((prev)=>{
      let newPrev = prev.map((upiprev, i) => {
        let checkChange = upiprev.id_r ===id;
        return checkChange ? {
          ...upiprev,
          upi_type : value,
        }
        : {
          ...upiprev,
        }
      })
      return newPrev;
    })
  };

  const HandleFocusText = (e, id) => {};

  const errorExpandedHandle = () => {
    let errorCount = [];
    Object.keys(errorUPI).forEach((err, i) => {
      Object.keys(errorUPI[err]).length > 0 && errorCount.push(i);
    });
    return errorCount.length == 0 ? null : errorCount.length;
  };
  useEffect(()=>{
    setErrorUPI((prev) => UPI_Error)
  },[UPIDATA])

  return (
    <div>
      <Formik initialValues={{}} validationSchema={yup.object().shape({})} onSubmit={submitForm}>
        {(formik) => {
          const { values, handleChange, handleSubmit, errors, touched, handleBlur } = formik;
          return (
            <form
              className="w-full  rounded-md rounded-t-none "
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              {UPIDATA.map((upi, i) => {
                return (
                  <Accordion key={upi.id_r} defaultExpanded={errorExpandedHandle() !== null ? true : i === 0 && true}>
                    <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel2a-content" id="panel2a-header" className="!bg-BrightGray !rounded-t-sm">
                      <Typography>UPI {i + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {UPIDATA.length > 1 && (
                        <div className="flex justify-end mt-2">
                          <button
                            type="button"
                            className="text-f-button flex items-center gap-1 bg-red-300 hover:bg-red-500 w-auto"
                            onClick={() => {
                              RemoveUPIHandle(upi);
                            }}
                          >
                            <IoClose stroke="white" />
                          </button>
                        </div>
                      )}
                      <UPIForm
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        HandleChangeText={HandleChangeText}
                        HandleFocusText={HandleFocusText}
                        changeUPIType={changeUPIType}
                        UPIDetails={upi}
                        errorUPI={errorUPI}
                        setErrorUPI={setErrorUPI}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
              <div className="flex w-full justify-center mb-4 mt-12">
                {isSubmit ? (
                  <button type="submit" className="text-f-submit w-[140px]" disabled={isSubmit}>
                    <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                  </button>
                ) : (
                  // accountSubmitStatus ?
                  <button type="submit" className="text-f-submit">
                    Update UPI
                  </button>
                  // :
                  // <button type="submit" className="text-f-submit">
                  //   Add
                  // </button>
                )}
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
