import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import ListView from "../../components/Invoices/ListView";
import GridView from "../../components/Invoices/GridView";
import { updateInvoiceID } from "../../store/actions/InvoiceAction";

const Invoices = () => {
  const [view, setView] = useState("list");
  const InvoicesState = useSelector((state) => state.invoicesReducers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateInvoiceID({ id: "" }));
  }, []);
  return (
    <>
      <DashboardHeader title="Invoices" view={view} setView={setView}>
        <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-white ">
          <div className={`flex ${InvoicesState.id && "lg:grid"} w-full `} style={{ gridTemplateColumns: "1fr 3fr" }}>
            <ListView />
            {InvoicesState.id && <GridView />}
          </div>
        </div>
      </DashboardHeader>
    </>
  );
};
export default Invoices;
