import Cookies from 'js-cookie'

class LocalStorage {
    setItem = (key, value) => {
        localStorage.setItem(key, value);
    }
    getItem = (key) => {
        return localStorage.getItem(key);
    }
    removeItem = (key) => {
        localStorage.removeItem(key)
    }
    setLogin = (login, token, userId, userName, email) => {
        Cookies.set('isLogin', login)
        Cookies.set('token', token)
        Cookies.set('userId', userId)
        Cookies.set('userName', userName)
        Cookies.set('email', email)
    }
    getLogin = () => {
        return {
            isLogin: Cookies.get("isLogin"),
            token: Cookies.get("token"),
            userId: Cookies.get("userId"),
            userName: Cookies.get("userName")
        }
    }
    setLogout = () => {
        return {
            isLogin: Cookies.remove("isLogin"),
            token: Cookies.remove("token"),
            userId: Cookies.remove("userId"),
            userName: Cookies.remove("userName"),
            email: Cookies.remove('email')
        }
    }
}

export default LocalStorage;