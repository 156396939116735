import { useEffect, useState } from "react";
import { RiArrowDownSFill } from "react-icons/ri";
import LocalStorage from "../../services/local_storage";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { UserProfileAPI } from "../../services/helpers/users";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { userLoginStatus } from "../../store/actions/UserActions";
const DNav = ({ title, view, setView=()=>{} }) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userReducers);
  const [dTitle, setDTitle] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const localstorage = new LocalStorage();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickLink = (pathname) => {
    navigate(pathname);
  };

  useEffect(() => {
    const queryParams = {
      userId: Cookies.get("userId"),
    };
    const paramsData = { queryParams, isAuthorized: true };
    UserProfileAPI(paramsData, (res) => {
      setUserProfile(res.data);
      setLoading(false);
    });
  }, [userState]);

  useEffect(() => {
    let pathname = location.pathname?.split("/");
    if (pathname?.includes("invoice") || pathname?.includes("invoices")) {
      setDTitle("Invoice");
    } else if (pathname?.includes("customer") || pathname?.includes("customers")) {
      setDTitle("Customer");
    } else if (pathname?.includes("item") || pathname?.includes("items")) {
      setDTitle("Item");
    } else if (pathname?.includes("dashboard")) {
      setDTitle("Dashboard");
    } else if (pathname?.includes("profile")) {
      setDTitle("profile");
    } else {
      setDTitle("");
    }
  }, [location.pathname]);

  return (
    <nav className="md:pl-64 absolute top-0 left-0 w-full z-10 bg-transparent hidden md:flex-row md:flex-nowrap md:justify-start md:flex items-center p-4 bg-PoliceBlue shadow-md">
      <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
        <div className="text-white text-lg uppercase inline-block font-semibold">
          {dTitle && (
            <span
              className="text-center mb-8 text-black text-xl font-semibold relative after:content-[''] after:w-[16px] after:h-[16px] after:bg-AppleHover after:absolute after:left-[-20px] after:top-[-4px] after:mb-[12px] before:content-[''] before:w-[16px] before:h-[16px] before:bg-Apple before:absolute before:mb-[12px] before:left-[-30px] before:top-[-12px] 
        before:z-10"
              onClick={() => {
                if (view == "grid") {
                  setView("list");
                } else {
                  setView("grid");
                }
              }}
            >
              {dTitle}
            </span>
          )}
        </div>
        <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
          <div className="relative">
            <div className="text-blueGray-500 block">
              <div className="items-center flex cursor-pointer" onClick={handleClick}>
                <span className="w-10 h-10 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
                  <img alt="..." className="w-full rounded-full align-middle border-none shadow-lg h-full" src={userProfile?.profile_pic || " "} />
                </span>
                <p className="text-black font-medium text-sm pl-1">{userProfile?.first_ame}</p> <RiArrowDownSFill className="text-2xl text-white" />
              </div>
            </div>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                disableScrollLock: false,
              }}
              MenuProps={{
                disableScrollLock: false,
              }}
            >
              <MenuItem
                onClick={() => {
                  clickLink("/profile");
                  handleClose();
                }}
              >
                <div className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 cursor-pointer">Profile</div>
              </MenuItem>
              <MenuItem>
                <div
                  className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 cursor-pointer"
                  onClick={() => {
                    localstorage.setLogout();
                    dispatch(userLoginStatus(false));
                    handleClose();
                  }}
                >
                  Logout
                </div>
              </MenuItem>
            </Menu>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default DNav;
