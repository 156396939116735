import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { HEAD_CELLS_INVOICES } from "../../../mockData";

const MuiTableHeadCustomer = (props) => {
  const { order, orderBy, onRequestSort, cId } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-gray-200">
      <TableRow>
        {HEAD_CELLS_INVOICES.map((headCell, i) =>
          cId ? (
            headCell.id == "name" && (
              <TableCell key={headCell.id} align={headCell.left ? "left" : "right"} padding={headCell.disablePadding ? "none" : "normal"} className="!pl-4 ">
                {headCell.icon ? (
                  <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <p>{headCell.label}</p>
                )}
              </TableCell>
            )
          ) : (
            <TableCell key={headCell.id} align={headCell.left ? "left" : "right"} padding={headCell.disablePadding ? "none" : "normal"} className="!pl-4 !font-semibold">
              {headCell.icon ? (
                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <p>{headCell.label}</p>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default MuiTableHeadCustomer;
