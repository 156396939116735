import  React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
  borderRadius: 1
};
const AddressModal = ({ open, setOpen, handleOpen, handleClose, addressd }) => {
  const [address, setAddress]=useState(null)
  
  useEffect(()=>{
    setAddress(addressd?.address?.filter((item) => item.type == addressd.type)[0])
  },[addressd])

  return (
    <div>
      {(address) && <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div>
            <div className="my-3">
                <p className="font-semibold">Attention</p>
                <p className="">{address.attention}</p>
            </div>
            <div className="my-3">
                <p className="font-semibold">Country</p>
                <p>{address.country}</p>
            </div>
            <div className="my-3">
                <p className="font-semibold">Address</p>
                <p>{address.address}</p>
            </div>
            <div className="my-3">
                <p className="font-semibold">City</p>
                <p>{address.city}</p>
            </div>
            <div className="my-3">
                <p className="font-semibold">State</p>
                <p>{address.state}</p>
            </div>
            <div className="my-3">
                <p className="font-semibold">Zip Code</p>
                <p>{address.zip_code != '0' && address.zip_code}</p>
            </div>
            <div className="my-3">
                <p className="font-semibold">Phone</p>
                <p>{address.address_phone}</p>
            </div>
            <div className="mb-2 mt-6">
                <p className="font-semibold text-f-cancel cursor-pointer" onClick={handleClose}>Close</p>
                </div>
          </div>
        </Box>
      </Modal> }
    </div>
  );
};

export default AddressModal;
