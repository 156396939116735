import { useNavigate, useParams } from "react-router-dom";
import CustomerForm from "../../components/Customers/CustomerForm";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";
import { useEffect, useState } from "react";
import { validationSchema } from "../../common/validate/Customer/AddCustomer";
import { BILLING_ADDRESS } from "../../mockData";
import Cookies from "js-cookie";
import { CustomerDetailById, UpdateCustomer } from "../../services/helpers/customers";
import { TermsList } from "../../services/helpers/common";

const CustomerUpdate = () => {
  const [initialValues, setInitialValues] = useState(null);
  const [paymentTermsList, setPaymentTermsList] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const submitForm = (values) => {
    setIsSubmit(true);
    const {
      salutation,
      first_name,
      last_name,
      company_name,
      customer_email,
      work_phone,
      mobile,
      // pan,
      GSTIN,
      customer_type,
      billing_attention,
      billing_address,
      billing_city,
      billing_state,
      billing_country,
      billing_zip_code,
      billing_address_phone,
      shipping_address,
      shipping_attention,
      shipping_city,
      shipping_country,
      shipping_state,
      shipping_zip_code,
      shipping_address_phone,
      payment_terms,
      display_name,
    } = values;
    const payload = {
      userId: Cookies.get("userId"),
      customerId: customer.customerUniqueId,
      salutation,
      first_name,
      last_name,
      company_name,
      email: customer_email,
      work_phone,
      mobile,
      // pan,
      gstin: GSTIN,
      types: customer_type === "business" ? 1 : 2,
      payment_terms,
      display_name,
      billing_address: {
        attention: billing_attention,
        address: billing_address,
        city: billing_city,
        state: billing_state,
        country: billing_country,
        zip_code: billing_zip_code,
        address_phone: billing_address_phone,
      },
      shipping_address: {
        attention: shipping_attention,
        address: shipping_address,
        city: shipping_city,
        state: shipping_state,
        country: shipping_country,
        zip_code: shipping_zip_code,
        address_phone: shipping_address_phone,
      },
    };

    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };

    UpdateCustomer(payload, paramsData, (res) => {
      setIsSubmit(false);
      if (res?.status == true) {
        setTimeout(() => {
          navigate("/customers");
        }, 200);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    customerDetailByIdHandle();
    const paramsDataP = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    TermsList(paramsDataP, (res) => {
      setPaymentTermsList(res?.data);
    });
  }, []);

  const customerDetailByIdHandle = () => {
    const queryParams = {
      customerId: params.id,
      userId: Cookies.get("userId"),
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    CustomerDetailById(paramsData, (res) => {
      // let fi = res.data.customerAddress.filter((item) => item.type == 2);
      initialValuesHandle(res.data);
      setCustomer(res.data);
      setLoading(false);
    });
  };

  const initialValuesHandle = (customer) => {
    let billing = customer?.customerAddress?.filter((item) => item.type == 1)[0];
    let shipping = customer?.customerAddress?.filter((item) => item.type == 2)[0];
    const initialValuesd = {
      customer_type: customer.customerType == 1 ? "business" : "individual",
      salutation: customer.salutation,
      first_name: customer.firstName,
      last_name: customer.lastName,
      company_name: customer.companyName,
      display_name: customer.displayName || "",
      customer_email: customer.email,
      work_phone: customer.work_phone,
      mobile: customer.mobile,
      // pan: customer.pan,
      GSTIN: customer.gstin || "", 
      payment_terms: customer.payment_terms || "",
      enable_portal: false,
      billing_attention: billing?.attention || "",
      billing_address: billing?.address || "",
      billing_city: billing?.city || "",
      billing_state: billing?.state || "",
      billing_country: billing?.country || "India",
      billing_zip_code: billing?.zip_code || "",
      billing_address_phone: billing?.address_phone || "",
      shipping_attention: shipping?.attention || "",
      shipping_address: shipping?.address || "",
      shipping_city: shipping?.city || "",
      shipping_state: shipping?.state || "",
      shipping_country: shipping?.country || "India",
      shipping_zip_code: shipping?.zip_code || "",
      shipping_address_phone: shipping?.address_phone || "",
    };
    setInitialValues(initialValuesd);
  };

  const copyBillingHandle = (s, v) => {
    BILLING_ADDRESS.forEach((i) => {
      s(`shipping_${i}`, v[`billing_${i}`]);
    });
  };

  const validateGSTIN = (e, gst, setFieldError, setFieldTouched) => {
    if(gst.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)){
    } else {
      setFieldError("GSTIN", "Please provide a valid GSTIN")
      setFieldTouched("GSTIN", true, false)
      e.preventDefault();
    }
  }

  return (
    <DashboardHeader>
      <CustomerForm
        initialValues={initialValues}
        isSubmit={isSubmit}
        paymentTermsList={paymentTermsList}
        copyBillingHandle={copyBillingHandle}
        validationSchema={validationSchema}
        submitForm={submitForm}
        loading={loading}
        heading="Edit Customer"
        submitBtn="Update"
        emailReadOnly={true}
        countryReadOnly={true}
        validateGSTIN={validateGSTIN}
      />
    </DashboardHeader>
  );
};

export default CustomerUpdate;
