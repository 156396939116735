import React from 'react'
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import UpdateInvoice from '../../components/Invoices/UpdateInvoice'

const InvoiceUpdate = () => {
  return (
    <DashboardHeader>
      <UpdateInvoice />
    </DashboardHeader>
  )
}

export default InvoiceUpdate