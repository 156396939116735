import { useEffect } from "react";
import RegisterForm from "../../components/RegisterForm";
import LocalStorage from "../../services/local_storage";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../components/Registration/Wrapper";
import SideBanner from "../../components/Registration/SideBanner";
import TextHeading from "../../components/Registration/TextHeading";
import FormWrapper from "../../components/Registration/FormWrapper";
import FormLogo from "../../components/Registration/FormLogo";

const Register = () => {
  const localstorage = new LocalStorage();
  const navigate = useNavigate();
  useEffect(() => {
    if (localstorage.getLogin().token) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <Wrapper>
      <SideBanner />
      <FormWrapper>
        <FormLogo />
        <div className="w-[96%] md:w-[90%] sm:w-[94%]">
          <TextHeading heading="Register" />
          <RegisterForm />
          <p className="text-center text-sm my-4 text-DarkSlateGray">
            Already have an account?{" "}
            <span className="hover:underline cursor-pointer hover:text-Apple font-medium" onClick={() => navigate("/")}>
              Log in
            </span>
          </p>
        </div>
      </FormWrapper>
    </Wrapper>
  );
};

export default Register;
