import React from "react";
import Address from "./Address";
import { MdExpandMore } from "react-icons/md";
import { CgDetailsMore } from "react-icons/cg";
import OtherDetails from "./OtherDetails";

const LeftSection = ({ customer }) => {
  return (
    <div className="w-full flex flex-col bg-white pt-6 px-2 min-h-screen border-r border-gray-300 border-solid">
      <div className="flex items-center">
        <div className="w-2/6 flex justify-center max-w-[80px]">
          <div className="w-10 h-10 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              src="https://w7.pngwing.com/pngs/613/636/png-transparent-computer-icons-user-profile-male-avatar-avatar-heroes-logo-black-thumbnail.png"
              alt=""
              className="w-full rounded-full align-middle  shadow-lg border border-gray-300 "
            />
          </div>
        </div>
        <div className="block w-8/12">
          <p className="font-medium">{customer.name}</p>
          <p>{customer.email}</p>
        </div>
      </div>
      <div className="flex flex-col my-4">
        <div className="py-4 flex justify-between px-2">
          <p className="uppercase text-md text-ChineseBlack font-medium">Address</p>
          <CgDetailsMore className="text-ChineseBlack font-medium text-2xl" />
        </div>
        <div>
          <Address address={customer.customerAddress} />
        </div>
        {/* <div className="py-4 flex justify-between px-2">
          <p className="uppercase text-md text-ChineseBlack font-medium">Other Details</p>
          <CgDetailsMore className="text-ChineseBlack font-medium text-2xl" />
        </div>
        <div> <OtherDetails  /> </div> */}
        <div className="py-4 flex justify-between px-2">
          <p className="uppercase text-md text-ChineseBlack font-medium">Record Info</p>
          <CgDetailsMore className="text-ChineseBlack font-medium text-2xl" />
        </div>
        <div>
          <div
            className="grid w-full pl-[0.7rem]"
            style={{
              gridGap: "10px",
              gridTemplateColumns: " 1fr 2fr",
            }}
          >
            <div >
              <p >Company Name</p>
              <p className="pt-2">Type</p>
              <p className="pt-2">Contact No</p>
            </div>
            <div>
              <p>{customer.companyName}</p>
              <p className="pt-2">{customer.customerType == 1 ? "B2B" : "B2C"}</p>
              <p className="pt-2">{customer.mobile}{customer.work_phone && ","} {customer.work_phone}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSection;
