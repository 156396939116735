const Footer = () => {
  return (
    <div className="md:ml-64 bg-blueGray-100 bg-ChineseBlack">
      <div className="text-white relative px-4 py-8 text-center">
        <p className="text-sm font-medium"><span className="text-gray-300">Copyright © 2023 </span> GST Sahayak. <span className="text-gray-300">All rights reserved. Design By</span> Tycho Technologies</p>
      </div>
    </div>
  );
};

export default Footer;
