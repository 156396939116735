import toast from "react-hot-toast";

const successToast = (string) => {
  toast.success(string, {
    // position: toast.POSITION.TOP_RIGHT,
    id: "success1",
    style: {
      border: '1px solid #1FAA59',
      padding: '16px',
      color: '#1FAA59',
    }
  });
};

const errorToast = (string) => {
  toast.error(string, {
    // position: toast.POSITION.TOP_RIGHT,
    id: "success1",
    duration: 1000,
    style: {
      border: '1px solid #B4161B',
      padding: '16px',
      color: '#B4161B',
    }
  });
};
const errorToast2 = (string) => {
  toast.error(string, {
    // position: toast.POSITION.TOP_RIGHT,
    id: "success1",
    duration: 2000,
    style: {
      border: '1px solid #B4161B',
      padding: '16px',
      color: '#B4161B',
    }
  });
};

const infoToast = (string) => {
  toast(string, {
    // position: toast.POSITION.TOP_RIGHT,
    id: "success1",
    style: {
      border: '1px solid #1B98F5',
      padding: '16px',
      color: '#1B98F5',
    }
  });
};

const warnToast = (string) => {
  toast(string, {
    // position: toast.POSITION.TOP_RIGHT,
    id: "success1",
    style: {
      border: '1px solid #1B98F5',
      padding: '16px',
      color: '#fff1d2',
    }
  });
};

export  { successToast,errorToast,errorToast2, infoToast,warnToast };
