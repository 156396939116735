import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import AddInvoice from '../../components/Invoices/AddInvoice'

const InvoiceAdd = () => {
  return (
    <DashboardHeader>
      <AddInvoice />
    </DashboardHeader>
  )
}

export default InvoiceAdd