import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocalStorage from "../../services/local_storage";
import Wrapper from "../../components/Registration/Wrapper";
import SideBanner from "../../components/Registration/SideBanner";
import TextHeading from "../../components/Registration/TextHeading";
import FormWrapper from "../../components/Registration/FormWrapper";
import FormLogo from "../../components/Registration/FormLogo";
import { Formik } from "formik";
import * as yup from "yup";
import { UserResetLink } from "../../services/helpers/users";
import { CircularProgress } from "@mui/material";
const Reset = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const localstorage = new LocalStorage();
  const navigate = useNavigate();
  useEffect(() => {
    if (localstorage.getLogin().token) {
      navigate("/dashboard");
    }
  }, []);

  const submitForm = (values) => {
    setIsSubmit(true);
    UserResetLink({ ...values, linkUrl: window.location.origin }, (res) => {
      setIsSubmit(false);
      if(res.status == true){
        setTimeout(() => {
          navigate("/");
        }, 200);
      }
    });
  };

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required").email("Email is invalid"),
  });

  return (
    <Wrapper>
      <SideBanner />
      <FormWrapper>
        <FormLogo />
        <div className="w-[96%] md:w-[90%] sm:w-[94%]">
          <TextHeading heading="Forgot Password" />
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
            {(formik) => {
              const { values, handleChange, handleSubmit, errors, touched, handleBlur } = formik;
              return (
                <form className="w-full border-[1px] border-Apple border-solid rounded-md rounded-t-none p-2" onSubmit={handleSubmit}>
                  <div className="mb-6 mt-2">
                    <label htmlFor="email" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                      Email ID
                    </label>
                    <input type="email" id="email" className="text-f-style" placeholder="Enter your Email ID" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                    {errors.email && touched.email && <span className="text-sm text-red-400">{errors.email}</span>}
                  </div>

                  <div className="flex w-full justify-center mb-4">
                  {isSubmit ? (
                      <button type="submit" className="text-f-submit w-[80px]" disabled={isSubmit}>
                        <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                      </button>
                    ) : (
                    <button type="submit" className="text-f-submit">
                      Send
                    </button> 
                    ) }
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </FormWrapper>
    </Wrapper>
  );
};

export default Reset;
