import * as yup from "yup";

export const initialValues = {
  item_type: "goods",
  item_name: "",
  unit: "",
  item_price: "",
  item_desc: "",
  item_code: "",
};

// {
//     "productId": 28,
//     "productUniqueId": "2f28c1e0-07ea-4e94-94a9-625de6ed92eb",
//     "productName": "Computer Monitors",
//     "productUnit": "km",
//     "sellingPrice": 15000,
//     "productDescription": "",
//     "productType": "Goods"
// }
export const setInitialValues = (productDetails) => {
  let Template = initialValues;
  Template.item_name = productDetails.productName;
  Template.item_desc = productDetails.productDescription;
  Template.unit = productDetails.productUnit;
  Template.item_price = productDetails.sellingPrice;
  Template.item_type = productDetails.productType;
  return Template;
};

const digitsOnlyForCode = (value) => /^[1-9]\d*(,\d+)?$/.test(value);

// const digitsOnly = (value) => /^\d+\.?\d?\d$/.test(value);
const digitsOnly = (value) => /^(0|0?[1-9]\d*)+\.?\d?\d$/.test(value);
const charOnly = (value) => /^[A-Za-z ]+$/.test(value); 

export const validationSchema = yup.object().shape({
  // item_type: yup.string().required("Item Type is required."),
  // item_name: yup.string().required("Name is required.").test("Digits only", "Item name should have characters only", charOnly),
  item_name: yup.string().required("Name is required."),
  unit: yup.string().required("Unit is required."),
  item_code: yup.string().required("Code is required.").test("lengthcode", "Invalid code", function(value,that){
    // console.log("value", value, that.parent.item_type  === "goods" )
    return that.parent.item_type  === "goods" ? (value?.length<=8)  : (value?.length<=6)
  }).test("coderegex", "decimal point not allow", digitsOnlyForCode),
  item_price: yup
    .string()
    .required("Price is required.")
    .test("Digits only", "The field should have valid price format", digitsOnly),
  item_desc: yup.string().max(200, "Description not more than 200 Alphabets")
  // .required("Description is required."),
});
