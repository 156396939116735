import { SET_USER_DATA } from "../../types/UserData";

const initialValue = {}

export const userData = (state = initialValue, action) => {
    const { type, payload } = action;
    switch(type){
        case SET_USER_DATA:
            return payload;
        default:
            return state;
    }
}