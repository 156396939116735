import React from 'react'
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import AddItem from '../../components/Items/AddItem'


const ItemAdd = () => {
  return (
    <DashboardHeader>
      <AddItem />
    </DashboardHeader>
  )
}

export default ItemAdd