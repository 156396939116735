import React from "react";
import { Formik } from "formik";
import { initialValues, validationSchema } from "../../common/validate/Register";
import { useNavigate } from "react-router-dom";
import { UserRegister } from "../../services/helpers/users";
import { BiHide, BiShow } from "react-icons/bi";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const RegisterForm = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState({
    password: false,
  });
  const navigate = useNavigate();
  const submitForm = (values) => {
    setIsSubmit(true)
    UserRegister(values, (res) => {
      setIsSubmit(false)
      if(res.status == true){
        setTimeout(() => {
          navigate("/");
        }, 200);
      }
    });
  };
  const passwordToggleHandle = (name) => {
    setPasswordToggle((prev) => {
      return {
        ...prev,
        [name]: !prev[name],
      };
    });
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
      {(formik) => {
        const { values, handleChange, handleSubmit, errors, touched, handleBlur } = formik;
        return (
          <>
            <form className="w-full border-[1px] border-Apple border-solid rounded-md rounded-t-none p-2" onSubmit={handleSubmit}>
              <div className="mb-6 mt-2">
                <label htmlFor="email" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                  Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-Apple block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none focus:shadow-Apple shadow-sm"
                  placeholder="Enter your Name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.first_name && touched.first_name && <span className="text-sm text-red-400">{errors.first_name}</span>}
              </div>
              <div className="mb-6 mt-2">
                <label htmlFor="email" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                  Email ID
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-Apple block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none focus:shadow-Apple shadow-sm"
                  placeholder="Enter your Email ID"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && <span className="text-sm text-red-400">{errors.email}</span>}
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={passwordToggle.password ? "text" : "password"}
                    id="password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-Apple block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none focus:shadow-Apple shadow-sm"
                    placeholder="Enter your Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {!passwordToggle.password ? (
                    <BiShow className="absolute top-[12px] right-[10px] cursor-pointer" onClick={() => passwordToggleHandle("password")} />
                  ) : (
                    <BiHide className="absolute top-[12px] right-[10px] cursor-pointer" onClick={() => passwordToggleHandle("password")} />
                  )}
                </div>
                {errors.password && touched.password && <span className="text-sm text-red-400">{errors.password}</span>}
              </div>
              <div className="flex w-full justify-center mb-4">
                {isSubmit ? (
                  <button type="submit" className="text-f-submit w-[80px]" disabled={isSubmit}>
                    <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="text-white bg-Apple hover:bg-AppleHover focus:outline-none focus:ring-blue-300 font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 outline-none"
                  >
                    Register
                  </button>
                )}
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
