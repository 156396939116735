import React, { useEffect, useState } from "react";
import { updateItemID } from "../../../store/actions/ItemActions";
import { useDispatch, useSelector } from "react-redux";
import { GrClose } from "react-icons/gr";
import { MdOutlineArrowBackIos } from "react-icons/md";
import LeftSection from "./LeftSection";
import { ProductDetailById } from "../../../services/helpers/products";
import Loader from "../../common/Loader";
import Cookies from "js-cookie";


const GridView = () => {
  const dispatch = useDispatch();
  const [loading, setLoading]= useState(true)
  const ItemsState = useSelector((state) => state.itemsReducers);
  const [productListByIdItem, setProductListByIdItem] = useState([])

  const productDetailsById =(productId) => {
    const queryParams = {
      productId: productId,
      userId: Cookies.get('userId')
    };
    const paramsData = { queryParams, headers: {},
    isAuthorized: true, };
    ProductDetailById(paramsData, (res) => {
      setProductListByIdItem(res?.data)
      setLoading(false)
    })
  }


  useEffect(() => {
    setLoading(true)
    if(ItemsState.id) {
      productDetailsById(ItemsState.id)
    }
    
  }, [ItemsState])

  return (
    <div className="block border-l-2 bg-white w-full">
      <div className="flex lg:hidden items-start justify-start mt-2 ml-2">
      <button className="flex font-medium items-center justify-center" onClick={() => dispatch(updateItemID({ id: "" }))}>
        <MdOutlineArrowBackIos className="text-sm mr-1 font-bold" />
        back
      </button>
      </div>
      <div className="flex justify-between mt-6 px-2">
        {loading ?<p>{""}</p>: <p className="text-xl font-semibold pl-2">{productListByIdItem?.productName}</p>}
        <div>
          <GrClose className="-lg:hidden cursor-pointer mr-2" onClick={() => dispatch(updateItemID({ id: "" }))} />
        </div>
      </div>
      <hr className="mt-10 mb-0"/>
      <div className="grid" style={{gridTemplateColumns:"1fr"}}>
        {
          loading ? <Loader /> :
        
        <LeftSection productListByIdItem={productListByIdItem} /> 
}
      </div>
    </div>
  );
};

export default GridView;
