import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";
import CustomerForm from "../../components/Customers/CustomerForm";
import { BILLING_ADDRESS } from "../../mockData";
import { AddCustomer } from "../../services/helpers/customers";
import { TermsList } from "../../services/helpers/common";
import { initialValuesAdd, validationSchema } from "../../common/validate/Customer/AddCustomer";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const CustomerAdd = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(null);
  const [paymentTermsList, setPaymentTermsList] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitForm = (values) => {
    setIsSubmit(true);
    const {
      salutation,
      first_name,
      last_name,
      company_name,
      customer_email,
      work_phone,
      mobile,
      // pan,
      GSTIN,
      customer_type,
      billing_attention,
      billing_address,
      billing_city,
      billing_state,
      billing_country,
      billing_zip_code,
      billing_address_phone,
      shipping_address,
      shipping_attention,
      shipping_city,
      shipping_country,
      shipping_state,
      shipping_zip_code,
      shipping_address_phone,
      payment_terms,
      display_name,
    } = values;
    const payload = {
      userId: Cookies.get("userId"),
      salutation,
      first_name,
      last_name,
      company_name,
      email: customer_email,
      work_phone,
      mobile,
      // pan,
      gstin: GSTIN,
      types: customer_type === "business" ? 1 : 2,
      payment_terms,
      display_name,
      billing_address: {
        attention: billing_attention,
        address: billing_address,
        city: billing_city,
        state: billing_state,
        country: billing_country,
        zip_code: billing_zip_code,
        address_phone: billing_address_phone,
        type: 1,
      },
      shipping_address: {
        attention: shipping_attention,
        address: shipping_address,
        city: shipping_city,
        state: shipping_state,
        country: shipping_country,
        zip_code: shipping_zip_code,
        address_phone: shipping_address_phone,
        type: 2,
      },
    };

    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    AddCustomer(payload, paramsData, (res) => {
      setIsSubmit(false);
      if (res?.status == true) {
        navigate("/customers");
      }
    });
  };

  useEffect(() => {
    const paramsDataP = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    TermsList(paramsDataP, (res) => {
      setPaymentTermsList(res?.data);
    });
    setInitialValues(initialValuesAdd);
  }, []);

  const copyBillingHandle = (s, v) => {
    BILLING_ADDRESS.forEach((i) => {
      s(`shipping_${i}`, v[`billing_${i}`]);
    });
  };

  const validateGSTIN = (e, gst, setFieldError, setFieldTouched) => {
    if(gst.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)){
    } else {
      setFieldError("GSTIN", "Please provide a valid GSTIN")
      setFieldTouched("GSTIN", true, false)
      e.preventDefault();
    }
  }

  return (
    <DashboardHeader>
      <CustomerForm
        initialValues={initialValues}
        isSubmit={isSubmit}
        paymentTermsList={paymentTermsList}
        copyBillingHandle={copyBillingHandle}
        validationSchema={validationSchema}
        submitForm={submitForm}
        loading={loading}
        heading="New Customer"
        submitBtn="Save"
        countryReadOnly={true}
        validateGSTIN={validateGSTIN}
      />
    </DashboardHeader>
  );
};

export default CustomerAdd;
