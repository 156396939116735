import { Field, Formik } from "formik";
import { AiOutlineDown } from "react-icons/ai";
import { CircularProgress } from "@mui/material";

const ItemForm = ({SubmitBtnText, CancelbtnHandle,initialValues, validationSchema, submitForm, isSubmit}) => {
  return (
    <>
    <div className="flex mt-8">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
          {(formik) => {
            const { values, handleSubmit, errors, touched, handleBlur, setFieldValue } = formik;
            return (
              <form className="w-full rounded-md rounded-t-none" onSubmit={handleSubmit}>
                <div className="mb-4 fr1_3 pl-5">
                  <div className="flex items-center">
                    <label htmlFor="customer_type" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                      Type
                    </label>
                  </div>
                  <div role="group" aria-labelledby="my-radio-group" className="flex gap-4 text-sm max-w-[500px]">
                    <label className="flex items-center justify-center cursor-pointer">
                      <Field type="radio" name="item_type" value="goods" />
                      <span className="pl-1"> Goods</span>
                    </label>
                    <label className="flex items-center justify-center cursor-pointer">
                      <Field type="radio" name="item_type" value="services" />
                      <span className="pl-1">Services</span>
                    </label>
                  </div>
                  {errors["item_type"] && (values["item_type"] || touched["item_type"]) && (
                    <>
                      <span> </span>
                      <span className="text-sm text-red-400">{errors["item_type"]}</span>
                    </>
                  )}
                </div>
                <div className="mb-8 fr1_3 items-center pl-5">
                  <div className="flex items-center">
                    <label htmlFor="customer_type" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                      Name<span className="text-red-600">*</span>
                    </label>
                  </div>
                  <div className="flex gap-4 text-sm max-w-[500px]">
                    <input
                      type="text"
                      id="item_name"
                      name="item_name"
                      className="text-f-customer grow shrink basis-0 leading-3"
                      placeholder="Enter your Name"
                      value={values.item_name}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors["item_name"] && (values["item_name"] || touched["item_name"]) && (
                    <>
                      <span> </span>
                      <span className="text-sm text-red-400">{errors["item_name"]}</span>
                    </>
                  )}
                </div>

                <div className="mb-8 fr1_3 items-center pl-5">
                  <div className="flex ">
                    <label htmlFor="customer_type" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                      Unit
                    </label>
                  </div>
                  <div className="max-w-[500px] relative">
                    <AiOutlineDown className="absolute top-3 right-5 text-sm" />
                    <select
                      className="appearance-none bg-gray-50 w-full py-3 px-2 outline-none text-f-customer grow shrink basis-0 leading-3"
                      id="unit"
                      name="unit"
                      value={values.unit}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                    >
                      {!values.unit && (
                        <option value="" disabled>
                          select or type to add
                        </option>
                      )}
                      <option value="box">box</option>
                      <option value="cm">cm</option>
                      <option value="dz">dz</option>
                      <option value="ft">ft</option>
                      <option value="km">km</option>
                      <option value="g">g</option>
                      <option value="in">in</option>
                      <option value="kg">kg</option>
                      <option value="lb">lb</option>
                      <option value="mg">mg</option>
                      <option value="ml">ml</option>
                      <option value="m">m</option>
                      <option value="pcs">pcs</option>
                      <option value="nos">nos</option>
                    </select>
                  </div>
                  {errors["unit"] && (values["unit"] || touched["unit"]) && (
                    <>
                      <span> </span>
                      <span className="text-sm text-red-400">{errors["unit"]}</span>
                    </>
                  )}
                </div>

                <div className="mb-8 fr1_3 items-center pl-5 ">
                  <div className="flex items-center">
                    <label htmlFor="item_code" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                      {values?.item_type === "goods" ? "HSN Code" : "SAC Code"}
                      <span className="text-red-600">*</span>
                    </label>
                  </div>
                  <div className="flex text-sm max-w-[600px]">
                    <input
                      type="number"
                      id="item_code"
                      name="item_code"
                      className="text-f-customer shrink basis-0 leading-3 w-52 min-w-[238px] rounded-l-none"
                      placeholder=""
                      value={values.item_code}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors["item_code"] && (values["item_code"] || touched["item_code"]) && (
                    <>
                      <span> </span>
                      <span className="text-sm text-red-400">{errors["item_code"]}</span>
                    </>
                  )}
                </div>

                <div className="mb-8 fr1_3 items-center pl-5">
                  <div className="flex items-center">
                    <label htmlFor="customer_type" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                      Selling Price<span className="text-red-600">*</span>
                    </label>
                  </div>

                  <div className="flex text-sm max-w-[500px]">
                    <input
                      type="text"
                      id="item_country"
                      name="item_country"
                      className="text-f-customer w-14 bg-slate-300 rounded-r-none"
                      value="INR"
                      onBlur={handleBlur}
                      readOnly
                    />
                    <input
                      type="number"
                      id="item_price"
                      name="item_price"
                      className="text-f-customer shrink basis-0 leading-3 w-52 rounded-l-none"
                      placeholder=""
                      value={values.item_price}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors["item_price"] && (values["item_price"] || touched["item_price"]) && (
                    <>
                      <span> </span>
                      <span className="text-sm text-red-400">{errors["item_price"]}</span>
                    </>
                  )}
                </div>

                <div className="mb-8 fr1_3 items-center pl-5">
                  <div className="flex items-center">
                    <label htmlFor="customer_type" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                      Description
                    </label>
                  </div>
                  <div className="flex text-sm max-w-[240px]">
                    <Field
                      component="textarea"
                      id="billing_address"
                      name="item_desc"
                      className="customer_textarea text-f-customer max-w-[500px] leading-5"
                      value={values.item_desc}
                    ></Field>
                  </div>
                  {errors["item_desc"] && (values["item_desc"] || touched["item_desc"]) && (
                    <>
                      <span> </span>
                      <span className="text-sm text-red-400">{errors["item_desc"]}</span>
                    </>
                  )}
                </div>
                <div className="flex w-full justify-center mb-4 mt-12">
                  {isSubmit ? (
                    <button type="submit" className="text-f-submit w-[80px]" disabled={isSubmit}>
                      <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                    </button>
                  ) : (
                    <button type="submit" className="text-f-submit">
                      {SubmitBtnText}
                    </button>
                  )}
                  <button
                    disabled={isSubmit}
                    type="submit"
                    className="text-f-cancel ml-4"
                    onClick={(event) => {
                      CancelbtnHandle(event);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  )
}

export default ItemForm