import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/LoginForm";
import Wrapper from "../../components/Registration/Wrapper";
import SideBanner from "../../components/Registration/SideBanner";
import TextHeading from "../../components/Registration/TextHeading";
import FormWrapper from "../../components/Registration/FormWrapper";
import FormLogo from "../../components/Registration/FormLogo";

const Login = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <SideBanner />
      <FormWrapper>
        <FormLogo />
        <div className="w-[96%] md:w-[90%] sm:w-[94%]">
          <TextHeading heading="Login" />
          <LoginForm />
          <p className="text-center text-sm my-4 text-DarkSlateGray">
            Not a Member Yet? {" "}
            <span
              className="hover:underline cursor-pointer hover:text-Apple font-medium"
              onClick={() => navigate("/register")}
            >
              Register now
            </span>
          </p>
        </div>
      </FormWrapper>
    </Wrapper>
  );
};

export default Login;
