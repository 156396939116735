import React, { useState } from "react";
import { initialValues, validationSchema } from "../../../common/validate/Item/AddItem";
import { useNavigate } from "react-router-dom";
import { AddProduct } from "../../../services/helpers/products";
import Cookies from "js-cookie";
import ItemForm from "../ItemForm";

const AddItem = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);

  const submitForm = (values) => {
    setIsSubmit(true);
    let type = values?.item_type === "goods" ? "1" : "2";
    const payload = {
      userId: Cookies.get("userId"),
      product_name: values?.item_name,
      product_unit: values?.unit,
      selling_price: values?.item_price,
      description: values?.item_desc,
      type: type,
      item_code: values?.item_code,
    };

    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };

    AddProduct(payload, paramsData, (res) => {
      setIsSubmit(false);
      if (res?.status == true) {
        setTimeout(() => {
          navigate("/items");
        }, 200);
      }
    });
  };

  const CancelbtnHandle = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  return (
    <div className="text-black relative md:pt-[4.5rem] pb-4 py-8 min-h-screen">
      <h3 className="text-2xl px-4 py-2 my-2 font-semibold text-ChineseBlack">New Item</h3>
      <hr />
      <ItemForm initialValues={initialValues} validationSchema={validationSchema}  SubmitBtnText={"Save"} CancelbtnHandle={CancelbtnHandle} submitForm={submitForm} isSubmit={isSubmit}/>
    </div>
  );
};

export default AddItem;
