import React from 'react'
import './loader.css'
const Loader = ({classes}) => {
  return (
    <div className={`stage ${classes}`}>
    <div className="dot-pulse"></div>
  </div>
  )
}

export default Loader