import classNames from "classnames";
import { Fragment, useEffect, useRef, useState } from "react";

export const TextField = ({ name, values, errors, touched, handleBlur, handleChange, iType, readonly, placeHolder }) => {
  const [labelT, setLabelT] = useState([]);

  useEffect(() => {
    let Label = name.split("_").join(" ");
    setLabelT(Label);
  }, []);
  return (
    <div className="mb-2 fr1_3 items-center">
      <label htmlFor={name} className="block text-[15px]  text-DarkSlateGray dark:text-white capitalize">
        {placeHolder || labelT}
      </label>
      <input
        type={`${iType || "text"}`}
        id={name}
        name={name}
        className={classNames(errors[name] && touched[name] && "border-red-300", "text-f-customer max-w-[500px]")}
        placeholder={placeHolder || labelT}
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readonly}
      />
      {errors[name] && touched[name] && (
        <>
          <span> </span>
          <span className="text-sm text-red-400">{errors[name]}</span>
        </>
      )}
    </div>
  );
};
export const OnlyField = ({ name, values, errors, touched, handleBlur, handleChange, iType, readonly, placeHolder }) => {
  const [labelT, setLabelT] = useState([]);

  useEffect(() => {
    let Label = name.split("_").join(" ");
    setLabelT(Label);
  }, []);
  return (
    <>
      <input
        type={`${iType || "text"}`}
        id={name}
        name={name}
        className={classNames(errors[name] && touched[name] && "border-red-400", "text-f-customer max-w-[500px]")}
        placeholder={placeHolder || labelT}
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readonly}
      />
      {errors[name] && touched[name] && (
        <>
          <span> </span>
          <span className="text-sm text-red-400">{errors[name]}</span>
        </>
      )}
    </>
  );
};

export const TextFieldDate = ({ name, values, errors, touched, handleBlur, handleChange, placeHolder }) => {
  const [labelT, setLabelT] = useState([]);
  const dateRef = useRef();

  useEffect(() => {
    let Label = name.split("_").join(" ");
    setLabelT(Label);
  }, []);
  return (
    <div className="mb-2 fr1_3 items-center">
      <label htmlFor={name} className="block text-[15px]  text-DarkSlateGray dark:text-white capitalize">
        { placeHolder ? placeHolder : labelT }
      </label>
      <input
        type="date"
        id={name}
        name={name}
        className={classNames(errors[name] && touched[name] && "!border-red-400", "text-f-customer max-w-[500px] cursor-pointer")}
        placeholder={ placeHolder ? placeHolder : labelT } 
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={dateRef}
        onClick={(e) => {
          dateRef.current.showPicker();
        }}
      />
      {errors[name] && touched[name] && (
        <>
          <span> </span>
          <span className="text-sm text-red-400">{errors[name]}</span>
        </>
      )}
    </div>
  );
};

export const TextField2 = ({ name, values, errors, touched, handleBlur, handleChange, iType, readonly }) => {
  const [labelT, setLabelT] = useState([]);

  useEffect(() => {
    let Label = name
      .replace("billing_address_", "")
      .replace("shipping_address_", "")
      .split("_")
      .filter((l) => l != "billing")
      .filter((l) => l != "shipping")
      .join(" ");
    setLabelT(Label);
  }, []);

  return (
    <>
      <div className="mb-2 fr1_3 items-center">
        <label htmlFor={name} className="block text-[15px]  text-DarkSlateGray dark:text-white capitalize">
          {labelT}
        </label>
        <div className="flex gap-1 max-w-[500px]">
          <input
            type={`${iType || "text"}`}
            id={name}
            name={name}
            className="text-f-customer"
            placeholder={labelT}
            value={values[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={readonly}
          />
          {errors[name] && touched[name] && (
            <>
              <span> </span>
              <span className="text-sm text-red-400">{errors[name]}</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const TextFieldSub = ({ name, values, errors, touched, handleBlur, handleChange, iType }) => {
  const [labelT, setLabelT] = useState([]);

  useEffect(() => {
    let Label = name
      .replace("billing_address_", "")
      .replace("shipping_address_", "")
      .split("_")
      .filter((l) => l != "billing")
      .filter((l) => l != "shipping")
      .join(" ");
    setLabelT(Label);
  }, []);

  return (
    <>
      <div className="mb-2 fr1_3 items-center">
        <label htmlFor={name} className="block text-[15px]  text-DarkSlateGray dark:text-white capitalize">
          {labelT}
        </label>
        <div className="flex gap-1 max-w-[500px]">
          <input
            type={`${iType || "text"}`}
            id={name}
            name={name}
            className="text-f-customer"
            placeholder={labelT}
            value={values[name]}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="mb-2 fr1_3 items-center">
        <div></div>
        <div className="flex gap-1 max-w-[500px]">
          {errors[name] && touched[name] && (
            <>
              <span> </span>
              <span className="text-sm text-red-400">{errors[name]}</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};
