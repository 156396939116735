// export const BASE_URL = "http://eacademyeducation.com:9000/api/";
export const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const API = {
    /*item */
    AddProduct : "product",
    UpdateProduct: "product",
    ProductList: "products",
    DeleteProduct: "product",
    ProductDetailsById: "product",
    ProductAllList: "product-list",

    /* Common */
    Terms: "getTerms",
    Tax: "taxList",

    Login: "login",
    Register: "register",
    Reset: "reset-password-sendlink",
    ResetPassword: "reset-password",
    ChangePassword: "change-password",
    UpdateProfile: "update-profile",

    UserDetail: "get-by-id",
    UploadQrCode: "upload-qrcode",
    /* Account */
    UserAccount: "userAccount",
    AccountAdd: "addAccouns",
    AccountDelete: "delete-account",

   
    /* UPI */
    UserUPI: "upi-list",
    UPIAdd: "add-upi",
    UPIDelete: "delete-upi",
    
    /* customers */
    NewUpdateDeleteCustomer: "customer",
    CustomersList: "customers",
    CustomersContactList: "customers-contact",
    CustomerDetail: "customer",
    
    /* invoices */
    AddInvoice: 'addInvoice',
    DeleteInvoice: "delete-invoicebyid",
    InvoiceList: "get-all-invoice",
    InvoiceDetailsById: "getby-invoiceid",
    InvoiceDeleteById: "generate-pdf",
    UpdateInvoice:"update-invoice",
    

}
