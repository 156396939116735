import React, { useEffect, useState } from "react";
// import Loader from "../common/Loader";
import DeviderHeading from "../DeviderHeading";
import Loader from "../../common/Loader";
import UPIList from "./UPIList";
import { UPI_MOCKDATA } from "../../../mockData";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { makeID } from "../../../common/helpers/profile";
import Cookies from "js-cookie";
import { DeleteUPIById, UserUPIAPI } from "../../../services/helpers/users";
import { errorToast, successToast } from "../../Toast";
import { Backdrop, CircularProgress } from "@mui/material";

const UPIComponent = () => {
  const [open, setOpen] = useState(false);
  const [UPIRefresh, setUPIRefrech] = useState(false)
  const [loading, setLoading] = useState(true);
  const [UPIDATA, setUPIDATA] = useState(null);

  useEffect(() => {
    let UPIWithID = UPI_MOCKDATA.map((data) => {
      return { ...data, id_r: makeID(8) };
    });

    const queryParams = {
      userId: Cookies.get("userId"),
    };

    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    UserUPIAPI(paramsData, (res) => {
      if (res.status === true) {
        if (res?.data?.upiList?.length > 0) {
          setUPIDATA(
            res?.data?.upiList?.map((data) => {
              return { ...data, id_r: makeID(8) };
            })
          );
          setLoading(false);
        } else {
          //   //   setAccountSubmitStatus(false);
          setUPIDATA(
            UPI_MOCKDATA.map((data) => {
              return { ...data, id_r: makeID(8) };
            })
          );
          setLoading(false);
        }
      } else {
        setLoading(false);
        setUPIDATA(UPIWithID);
      }
    });

    // setUPIDATA(UPIWithID);
  }, [UPIRefresh]);

  const AddUPIHandle = () => {
    setUPIDATA((prevState) => {
      return [
        ...prevState,
        {
          id_r: makeID(8),
          ...UPI_MOCKDATA[0],
        },
      ];
    });
  };

  const RemoveUPIHandle = (upiOne) => {
    setOpen(true);

    if (upiOne?.id) {
      const queryParams = {
        id: upiOne?.id,
        user_id: Cookies.get("userId"),
      };
      const paramsData = {
        queryParams,
        headers: {},
        isAuthorized: true,
      };
      DeleteUPIById(paramsData, (res) => {
        setLoading(false);
        if (res.status === true) {
          setOpen(false);
          let filterUPIDATA = UPIDATA.filter((upi, i) => upi.id_r !== upiOne?.id_r);
          setUPIDATA(filterUPIDATA);
          successToast(res.message);
        } else {
          setOpen(false);
          errorToast("Something went wrong! Please try again");
        }
      });
    } else {
      let filterUPIDATA = UPIDATA.filter((upi, i) => upi.id_r !== upiOne?.id_r);
      setUPIDATA(filterUPIDATA);
      setTimeout(() => {
        setOpen(false);
        successToast("Account deleted successfully");
      }, 1000);
    }
  };

  return (
    <>
      <div className="text-black relative md:pt-2 pb-20 py-8 px-4">
        {loading ? (
          <Loader classes="min-h-screen" />
        ) : UPIDATA && UPIDATA.length > 0 ? (
          <>
            {/* <DeviderHeading heading={"Unified Payments Interface"} /> */}
            <div className="flex justify-end mb-5">
              <button
                type="button"
                className="text-f-button flex items-center gap-1 w-auto"
                onClick={() => {
                  AddUPIHandle();
                }}
              >
                <AiOutlinePlusCircle /> Add
              </button>
            </div>
            <UPIList UPIDATA={UPIDATA} setUPIDATA={setUPIDATA} RemoveUPIHandle={RemoveUPIHandle} setLoading={setLoading} setUPIRefrech={setUPIRefrech} />
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        ) : null}
      </div>
    </>
  );
};

export default UPIComponent;
