import React, { useEffect, useState } from "react";
import ListView from "../../components/Customers/ListView";
import GridView from "../../components/Customers/GridView";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerID } from "../../store/actions/CustomerActions";

const Customers = () => {
  const [view, setView] = useState("list");
  const CustomersState = useSelector((state) => state.customersReducers);
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(updateCustomerID({ id: ""}))
  },[])
  return (
    <>
      <DashboardHeader title="Customers" view={view} setView={setView}>
        <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-white ">
          <div className={`flex ${CustomersState.id && "lg:grid"} w-full `} style={{gridTemplateColumns:"1fr 3fr"}}>
            <ListView />
            {CustomersState.id && <GridView />}
          </div>
        </div>
      </DashboardHeader>
    </>
  );
};

export default Customers;
