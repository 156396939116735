import { FaBars, FaTools, FaUsers, FaBox, FaFileInvoice } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";

import { BiDesktop, BiLogOut } from "react-icons/bi";
import Llogo from "../../assets/img/logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import LocalStorage from "../../services/local_storage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userLoginStatus } from "../../store/actions/UserActions";
const Navbar = ({ setIsLogin }) => {
  const [mobileMenu, setMobildMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const localstorage = new LocalStorage();
  const dispatch = useDispatch();

  const clickLink = (pathname) => {
    navigate(pathname);
  };

  const checkHyperLink = (pathname) => {
    if (typeof pathname == "string") {
      return location.pathname === pathname
        ? "text-Apple hover:text-AppleHover "
        : "text-blueGray-700 hover:text-blueGray-500";
    } else {
      if (pathname[1].split("/")[1] === location.pathname.split("/")[1]) {
        return "text-Apple hover:text-AppleHover";
      }
      for (let i = 0; i < pathname.length; i++) {
        if (location.pathname === pathname[i]) {
          return "text-Apple hover:text-AppleHover";
        }
      }
      return "text-blueGray-700 hover:text-blueGray-500";
    }
  };

  // useEffect(()=>{
  //   const queryParams = {
  //     userId: Cookies.get("userId"),
  //   };
  //   const paramsData = { queryParams };
  //   UserProfileAPI(paramsData, (res) => {
  //     setProfile(res?.data)
  //    })
  // },[])

  useEffect(() => {
    setMobildMenu(false);
  }, [location.pathname]);

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-20 py-4 px-6">
        <div
          style={{ transition: "all 1.5s" }}
          className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
        >
          <button
            className="bg-white m-2  cursor-pointer text-black opacity-50 md:hidden px-3 py-2 text-xl leading-none bg-transparent rounded border border-solid border-transparent border-[#e4e4e7]"
            type="button"
            onClick={() => setMobildMenu(!mobileMenu)}
          >
            {!mobileMenu ? <FaBars /> : <TfiClose />}
          </button>
          <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-md uppercase font-bold p-4 px-0 cursor-pointer">
            <div className="w-[60%] mb-2 mx-auto ">
              <img
                src={Llogo}
                className="-md:w-[152px]"
                alt="navbarlogo"
                onClick={() => {
                  clickLink("/");
                }}
              />
            </div>
          </div>
          {mobileMenu && (
            <div className="top-0; h-auto w-full md:hidden">
              <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li className="items-center ">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink(
                      "/dashboard"
                    )}`}
                    onClick={() => {
                      clickLink("/dashboard");
                    }}
                  >
                    <BiDesktop className="mr-1" /> Dashboard{" "}
                  </div>
                </li>
                <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink(
                      ["/customers", "/customer/new"]
                    )}`}
                    onClick={() => {
                      clickLink("/customers");
                    }}
                  >
                    <FaUsers className="mr-1" /> Customers
                  </div>
                </li>
                <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink(
                      ["/items", "/item/new"]
                    )}`}
                    onClick={() => {
                      clickLink("/items");
                    }}
                  >
                    <FaBox className="mr-1" /> Items
                  </div>
                </li>
                <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink(
                      ["/invoices", "/invoice/new"]
                    )}`}
                    onClick={() => {
                      clickLink("/invoices");
                    }}
                  >
                    <FaFileInvoice className="mr-1" /> Invoices
                  </div>
                </li>
                <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink(
                      "/profile"
                    )}`}
                    onClick={() => {
                      clickLink("/profile");
                    }}
                  >
                    <FaTools className="mr-1" /> Profile
                  </div>
                </li>
                <li className="items-center">
                  <div
                    className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center justify-center hover:bg-gray-100 ${checkHyperLink(
                      "/logout"
                    )}`}
                    onClick={() => {
                      localstorage.setLogout();
                      dispatch(userLoginStatus(false));
                    }}
                  >
                    <BiLogOut className="mr-1" /> Logout
                  </div>
                </li>
              </ul>
            </div>
          )}

          <div className="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded hidden">
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <div className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0">
                    {" "}
                    GST Sahayak{" "}
                  </div>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  >
                    button
                  </button>
                </div>
              </div>
            </div>

            {/* <h6 className="md:min-w-full text-blueGray-500 uppercase font-bold block pt-1 pb-4 no-underline text-xl"> Dashboard </h6> */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center ">
                <div
                  className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center ${checkHyperLink(
                    "/dashboard"
                  )}`}
                  onClick={() => {
                    clickLink("/dashboard");
                  }}
                >
                  <BiDesktop className="mr-1" /> Dashboard{" "}
                </div>
              </li>
              <li className="items-center">
                <div
                  className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center ${checkHyperLink(
                    ["/customers", "/customer/new"]
                  )}`}
                  onClick={() => {
                    clickLink("/customers");
                  }}
                >
                  <FaUsers className="mr-1" /> Customers
                </div>
              </li>
              <li className="items-center">
                <div
                  className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center ${checkHyperLink(
                    ["/items", "/item/new"]
                  )}`}
                  onClick={() => {
                    clickLink("/items");
                  }}
                >
                  <FaBox className="mr-1" /> Items
                </div>
              </li>
              <li className="items-center">
                <div
                  className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center ${checkHyperLink(
                    ["/invoices", "/invoice/new"]
                  )}`}
                  onClick={() => {
                    clickLink("/invoices");
                  }}
                >
                  <FaFileInvoice className="mr-1" /> Invoices
                </div>
              </li>
              <li className="items-center">
                <div
                  className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center ${checkHyperLink(
                    "/profile"
                  )}`}
                  onClick={() => {
                    clickLink("/profile");
                  }}
                >
                  <FaTools className="mr-1" /> Profile
                </div>
              </li>
              <li className="items-center">
                <div
                  className={`text-xs uppercase py-3 font-bold cursor-pointer flex items-center ${checkHyperLink(
                    "/logout"
                  )}`}
                  onClick={() => {
                    localstorage.setLogout();
                    dispatch(userLoginStatus(false)); /*clickLink('/')*/
                  }}
                >
                  <BiLogOut className="mr-1" /> Logout
                </div>
              </li>
              {/* <li className="items-center">
                    <div className="text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500 cursor-pointer">
                        Tables{" "}
                      </div>
                  </li>
                  <li className="items-center">
                    <div className="text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500 cursor-pointer">
                         Maps{" "}
                      </div>
                  </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
