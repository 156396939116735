import Slider1 from "../../assets/img/slider1.png";

const SideBanner = () => {
  return (
    <div className="md:w-2/3 w-full bg-[#b0f4e4] -md:h-[30rem] ">
      <img src={Slider1} className="object-contain h-full" alt="bannerLogo" />
    </div>
  );
};

export default SideBanner;
