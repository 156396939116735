import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LocalStorage from "../../services/local_storage";
import Wrapper from "../../components/Registration/Wrapper";
import SideBanner from "../../components/Registration/SideBanner";
import TextHeading from "../../components/Registration/TextHeading";
import FormWrapper from "../../components/Registration/FormWrapper";
import FormLogo from "../../components/Registration/FormLogo";
import { Formik } from "formik";
import * as yup from "yup";
import { UserResetPassword } from "../../services/helpers/users";
import {BiHide, BiShow} from 'react-icons/bi'
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const ResetPassword = () => {
  const [isSubmit, setIsSubmit] = useState(false)
  const [passwordToggle, setPasswordToggle] = useState({
    password: false,
  })
  const localstorage = new LocalStorage();
  const params = useParams()
  const navigate = useNavigate();
  useEffect(() => {
    if (localstorage.getLogin().token) {
      navigate("/dashboard");
    }
  }, []);

  const submitForm = (values) => {
    setIsSubmit(true)
    const payload = {
      password:values.password,
    }
    const path = `/${params.userid}/${params.hash}`

    UserResetPassword(path, payload, (res) => {
      setIsSubmit(false)
      if(res.status == true){
        setTimeout(()=>{navigate('/')},200)
      }
    });
  };

  const initialValues = {
    password: "",
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required("Password is required").min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
  });

  const passwordToggleHandle = (name) => {
    setPasswordToggle((prev)=>{return {
      ...prev,
      [name]: !prev[name]
    }})
  }

  return (
    <Wrapper>
      <SideBanner />
      <FormWrapper>
        <FormLogo />
        <div className="w-[96%] md:w-[90%] sm:w-[94%]">
          <TextHeading heading="Forgot Password" />
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
            {(formik) => {
              const { values, handleChange, handleSubmit, errors, touched, handleBlur } = formik;
              return (
                <form className="w-full border-[1px] border-Apple border-solid rounded-md rounded-t-none p-2" onSubmit={handleSubmit}>
                  
                  <div className="mb-6 mt-2">
                    <label htmlFor="password" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                      Password
                    </label>
                    <div className="relative">
                    <input
                      type={passwordToggle.password ? 'text': 'password'}
                      id="password"
                      className="text-f-style"
                      placeholder="Enter your password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                        {!passwordToggle.password ? 
                      <BiShow className="absolute top-[12px] right-[10px] cursor-pointer" onClick={()=>passwordToggleHandle("password")} />:
                      <BiHide className="absolute top-[12px] right-[10px] cursor-pointer" onClick={()=>passwordToggleHandle("password")} />}
                      </div>
                    {errors.password && touched.password && <span className="text-sm text-red-400">{errors.password}</span>}
                  </div>

                  <div className="flex w-full justify-center mb-4">
                  {isSubmit ? (
                      <button type="submit" className="text-f-submit w-[80px]" disabled={isSubmit}>
                        <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                      </button>
                    ) : (
                    <button type="submit" className="text-f-submit">
                      Reset Password
                    </button>) }
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </FormWrapper>
    </Wrapper>
  );
};

export default ResetPassword;
