import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  userProfileData,
  UserQrCodeUpload,
} from "../../../services/helpers/users";
import Cookies from "js-cookie";

const QRCodeUpload = () => {
  // const userData = useSelector((state) => state.userData);
  const img = useSelector((state) => state.userData?.paymentQrCode);
  // const img = "https://cdn-icons-png.flaticon.com/512/1160/1160358.png";

  const [qrCodeFile, setQrCodeFile] = useState();
  const [isRemoved, setIsRemoved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFile = (e) => {
    if (e.target.files?.length > 0) {
      setQrCodeFile(e.target.files[0]);
      setIsRemoved(false);
    }
  };

  useEffect(() => {
    setQrCodeFile();
  }, [img]);

  const handleRemove = () => {
    setQrCodeFile();
    setIsRemoved(true);
  };

  const fileUpload = () => {
    if (qrCodeFile) {
      setIsLoading(true);
      const form = new FormData();
      form.append("userId", Cookies.get("userId"));
      form.append("file", qrCodeFile);

      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };

      UserQrCodeUpload(form, paramsData, (res) => {
        if (res.status === true) {
          userProfileData();
          setIsRemoved(false);
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <div className="h-96 my-5 text-center">
      {!isRemoved && (qrCodeFile || img) ? (
        <>
          <div className="flex flex-col items-center justify-center">
            <img
              src={qrCodeFile ? URL.createObjectURL(qrCodeFile) : img}
              alt="qr_code"
              className="w-52 h-52"
            />
            <Button className="!mt-4" onClick={() => handleRemove()}>
              Remove
            </Button>
            {qrCodeFile && (
              <button
                type="submit"
                className="text-f-submit mt-4 w-24"
                onClick={() => !isLoading && fileUpload()}
              >
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    className="!w-3 !h-3"
                  />
                ) : (
                  "Update"
                )}
              </button>
            )}
          </div>
        </>
      ) : (
        <Button
          variant="text"
          component="label"
          size="large"
          className="w-52 h-52"
        >
          Select QR Code
          <input
            accept="image/png,image/gif,image/jpeg"
            type="file"
            className="hidden"
            onChange={(e) => handleFile(e)}
          />
        </Button>
      )}
    </div>
  );
};
export default QRCodeUpload;
