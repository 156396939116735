import DNav from "./DNav";

const DashboardHeader = ({ children, title, view, setView=()=>{} }) => {
  return (
    <div className="relative md:ml-64 bg-blueGray-100">
      {/* <DNav title={title} view={view} setView={setView}/> */}
      {children}
    </div>
  );
};

export default DashboardHeader;
