import { Field, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { State } from "country-state-city";
import { validationSchema } from "../../../common/validate/Item/AddItem";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";
import { CircularProgress } from "@mui/material";
import { ProductDetailById, UpdateProduct } from "../../../services/helpers/products";
import Loader from "../../common/Loader";
import Cookies from "js-cookie";
import ItemForm from "../ItemForm";

const ItemFormUpdate = () => {
  const [initialValues, setInitialValues] = useState(null);
  const [productListByIdItem, setProductListByIdItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const submitForm = (values) => {
    setIsSubmit(true);
    let type = values?.item_type == "goods" ? "1" : "2";
    const payload = {
      userId: Cookies.get("userId"),
      product_name: values?.item_name,
      product_unit: values?.unit,
      selling_price: values?.item_price,
      description: values?.item_desc,
      item_code: values?.item_code,
      type: type,
      productId: productListByIdItem?.productUniqueId,
    };

    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };

    UpdateProduct(payload, paramsData, (res) => {
      setIsSubmit(false);
      if(res?.status == true){
        navigate("/items");
      }
    });
  };

  const productDetailsById = () => {
    const queryParams = {
      userId: Cookies.get("userId"),
      productId: params.id,
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };

    ProductDetailById(paramsData, (res) => {
      const initialValuesd = {
        item_name: res?.data?.productName,
        item_desc: res?.data?.productDescription,
        unit: res?.data?.productUnit,
        item_price: res?.data?.sellingPrice,
        item_code: res?.data?.item_code,
        item_type: res?.data?.productType?.toLowerCase()
      }
      
      setLoading(false);
      setInitialValues({...initialValuesd})
      setProductListByIdItem(res?.data);
    });
  };

  useEffect(() => {
    productDetailsById();
  }, []);

  const CancelbtnHandle = (event) => {
    event.preventDefault();
    navigate(-1);
  }

  return (
    <div className="text-black relative md:pt-[4.5rem] pb-4 py-8 min-h-screen">
      <h3 className="text-2xl px-4 py-2 my-2 font-semibold text-ChineseBlack">Edit Item</h3>
      <hr />
      {loading  ? (
        <Loader />
      ) : (
        productListByIdItem && initialValues && (
        <ItemForm SubmitBtnText={"Update"} CancelbtnHandle={CancelbtnHandle} initialValues={initialValues} validationSchema={validationSchema} submitForm={submitForm} isSubmit={isSubmit} />
        )
      )}
    </div>
  );
};

export default ItemFormUpdate;
