import PageCard from "./PageCard";
import page1 from "../../assets/img/page1.png";
import page2 from "../../assets/img/page2.png";
import page3 from "../../assets/img/page3.png";
const Dashboard = () => {
  return (
    <div className="text-black relative md:pt-32 pb-4 py-8 px-4  min-h-screen">
      {/* <div>
        <div className="flex w-full">
          <PageCard imgsrc={page1} />
          <PageCard imgsrc={page2} />
        </div>
        <div className="flex w-full">
          <PageCard imgsrc={page3} />
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
