import React from "react";

const UPIForm = ({ values, handleChange, errors, touched, handleBlur, HandleChangeText, changeUPIType, UPIDetails, errorUPI, setErrorUPI, HandleFocusText }) => {
  return (
    <>
      <div className="flex gap-4 -lg:flex-col ">
        <div className="mb-6 grow shrink basis-0">
          <label htmlFor="upi_id" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
            UPI ID
          </label>
          <input
            type="text"
            name="upi_id"
            className="text-f-style"
            placeholder="Enter UPI ID"
            value={UPIDetails.upi_id}
            onChange={(e) => {
              HandleChangeText(e, UPIDetails?.id_r);
            }}
            onBlur={(e) => {
              if (e.target.value.length > 30) {
                setErrorUPI((prev) => {
                  return { ...prev, upi_id: { ...prev.upi_id, [UPIDetails?.id_r]: "UPI ID must not exceed 30 value" } };
                });
              } else if (e.target.value.length < 6 && e.target.value !== '' ) {
                setErrorUPI((prev) => {
                  return { ...prev, upi_id: { ...prev.upi_id, [UPIDetails?.id_r]: "UPI ID must be at least 6 value" } };
                });
              }
            }}
            onFocus={(e) => {
              HandleFocusText(e, UPIDetails?.id_r);
              setErrorUPI((prev) => {
                return { ...prev, upi_id: { ...prev.upi_id, [UPIDetails?.id_r]: "" } };
              });
            }}
          />
          {errorUPI["upi_id"][UPIDetails?.id_r] && <span className="text-sm text-red-400">{errorUPI["upi_id"][UPIDetails?.id_r]}</span>}
        </div>

        <div className="mb-6 grow shrink basis-0">
          <label htmlFor="upi_number" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
            UPI Number
          </label>
          <input
            type="number"
            name="upi_number"
            className="text-f-style"
            placeholder="Enter Google pay"
            value={UPIDetails.upi_number}
            onChange={(e) => {
              HandleChangeText(e, UPIDetails?.id_r);
            }}
            onFocus={(e) => {
              HandleFocusText(e, UPIDetails?.id_r);
              setErrorUPI((prev) => {
                return { ...prev, upi_number: { ...prev.upi_number, [UPIDetails?.id_r]: "" } };
              });
            }}
            onBlur={(e) => {
              if (e.target.value.length > 10) {
                setErrorUPI((prev) => {
                  return { ...prev, upi_number: { ...prev.upi_number, [UPIDetails?.id_r]: "Number must not exceed 10 digits" } };
                });
              } else if (e.target.value.length < 10 && e.target.value !== '') {
                setErrorUPI((prev) => {
                  return { ...prev, upi_number: { ...prev.upi_number, [UPIDetails?.id_r]: "Number must be 10 digits" } };
                });
              }
            }}
          />
          {errorUPI["upi_number"][UPIDetails?.id_r] && <span className="text-sm text-red-400">{errorUPI["upi_number"][UPIDetails?.id_r]}</span>}
        </div>
      </div>

      <div className="flex gap-4 -lg:flex-col">
        <div className="mb-6 grow shrink basis-0">
          <label htmlFor="upi_type" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
            Name
          </label>
          <select
            className="appearance-none bg-gray-50 w-full py-1 px-2 outline-none text-f-customer leading-[1.6rem]"
            value={UPIDetails?.upi_type}
            onChange={(e) => {
              changeUPIType(e, UPIDetails?.id_r);
            }}
          >
            {!UPIDetails?.upi_type && <option value="">Select Name</option>}
            <option value="google_pay">Google Pay</option>
            <option value="phone_pay">Phone Pay</option>
            <option value="paytm">Paytm</option>
          </select>
          {/* <input
            type="text"
            name="upi_type"
            className="text-f-style"
            placeholder="Enter your Phone Pay"
            value={UPIDetails.upi_type}
            onChange={(e) => {
              HandleChangeText(e, UPIDetails?.id_r);
            }}
            onBlur={handleBlur}
          />
          {errorUPI["upi_type"][UPIDetails?.id_r] && <span className="text-sm text-red-400">{errorUPI["upi_type"][UPIDetails?.id_r]}</span>} */}
        </div>

        <div className="mb-6 grow shrink basis-0"></div>
      </div>
    </>
  );
};

export default UPIForm;
