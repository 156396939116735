import "./App.css";
import routes from "./config/Routes";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { routeController } from "./utils/Utility";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import Cookies from "js-cookie";
import Footer from "./components/Footer";
import { Toaster } from "react-hot-toast";
import DNav from "./components/Dashboard/DNav";
import ScrollToTop from "./components/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { userLoginStatus } from "./store/actions/UserActions";
import { UserProfileAPI, userProfileData } from "./services/helpers/users";
import { setUserData } from "./store/actions/UserData";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userLoginStatusReducer } = useSelector((state) => state);

  useEffect(() => {
    if (location?.pathname === "/") {
      navigate("/login");
    }
  }, [location]);

  useEffect(() => {
    if (Cookies.get("token")) {
      dispatch(userLoginStatus(true));
      getUserProfileData();
    } else {
      dispatch(userLoginStatus(false));
    }
  }, [Cookies.get("token")]);

  const getUserProfileData = () => {
    userProfileData();
  };

  return (
    <>
      <ScrollToTop>
        <div className={`${userLoginStatusReducer && "app-wrapper-dashboard"}`}>
          <Toaster
            toastOptions={{
              position: "top-center",
            }}
          />
          {userLoginStatusReducer && (
            <>
              <Navbar />
              <DNav title="" />
            </>
          )}
          <Routes>
            {routes.map(
              ({ id, path, component, protectedRoute, type = "purchaser" }) => (
                <Route
                  key={id}
                  path={path}
                  element={routeController(component, protectedRoute)}
                />
              )
            )}
          </Routes>
          {userLoginStatusReducer && <Footer />}
        </div>
      </ScrollToTop>
    </>
  );
}

export default App;
