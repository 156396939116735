import { Navigate } from "react-router-dom";
import LocalStorage from "../../services/local_storage";

export const routeController = (component, protectedRoute = false) => {
  const localstorage = new LocalStorage();
  if (protectedRoute) {
    if (localstorage.getLogin().token) {
      return component;
    } else {
      return <Navigate to="/login" replace />;
    }
  } else {
    if (localstorage.getLogin().token) {
      return <Navigate to="/dashboard" replace />;
    } else {
      return component;
    }
  }
};
