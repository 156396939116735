import { getApiCall, postApiCall, putApiCall, deleteApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";
import { successToast, errorToast } from "../../../components/Toast";

export const AddCustomer = async (payload, paramsData, callback) => {
  const url = `${BASE_URL}${API.NewUpdateDeleteCustomer}`;

  try {
    const response = await postApiCall(url, payload, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response)
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    errorToast("status failed");
    console.log(`Error ${error}`);
    callback({})
  }
};

export const UpdateCustomer = async (payload,paramsData, callback) => {
  const url = `${BASE_URL}${API.NewUpdateDeleteCustomer}`;

  try {
    const response = await putApiCall(url, payload, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response)
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({})
  }
};

export const CustomerList = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.CustomersList}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};
export const CustomerContactList = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.CustomersContactList}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};

export const DeleteCustomerById = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.NewUpdateDeleteCustomer}`;

  try {
    const response = await deleteApiCall(url, {}, paramsData);
    if (response.status === false) {
      errorToast(response.message);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};

export const CustomerDetailById = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.CustomerDetail}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};
