import React from "react";
import { Formik } from "formik";
import { initialValues, validationSchema } from "../../common/validate/Login";
import LocalStorage from "../../services/local_storage";
import { UserLogin } from "../../services/helpers/users";
import { useNavigate } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { userLoginStatus } from "../../store/actions/UserActions";
import { CircularProgress } from "@mui/material";
const LoginForm = ({ setIsLogin }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  let localstorage = new LocalStorage();
  const dispatch = useDispatch();
  const [passwordToggle, setPasswordToggle] = useState({
    password: false,
  });
  const navigate = useNavigate();
  const submitForm = (values) => {
    setIsSubmit(true);
    UserLogin(values, (res) => {
      setIsSubmit(false);
      if (res.status == true) {
        let username = res.data.firstName + " " + res.data.lastName;
        localstorage.setLogin(true, res?.data?.loginToken, res?.data?.user_id, username, res?.data?.email);
        dispatch(userLoginStatus(true));
        setTimeout(() => {
          navigate("/dashboard");
        }, 200);
      }
    });
  };

  const passwordToggleHandle = (name) => {
    setPasswordToggle((prev) => {
      return {
        ...prev,
        [name]: !prev[name],
      };
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
      {(formik) => {
        const { values, handleChange, handleSubmit, errors, touched, handleBlur } = formik;
        return (
          <form className="w-full border-[1px] border-Apple border-solid rounded-md rounded-t-none p-2" onSubmit={handleSubmit}>
            <div className="mb-6 mt-2">
              <label htmlFor="email" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                Email ID
              </label>
              <input type="email" id="email" className="text-f-style" placeholder="Enter your Email ID" value={values.email} onChange={handleChange} onBlur={handleBlur} />
              {errors.email && touched.email && <span className="text-sm text-red-400">{errors.email}</span>}
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                Password
              </label>
              <div className="relative">
                <input
                  type={passwordToggle.password ? "text" : "password"}
                  id="password"
                  className="text-f-style"
                  placeholder="Enter your Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {!passwordToggle.password ? (
                  <BiShow className="absolute top-[12px] right-[10px] cursor-pointer" onClick={() => passwordToggleHandle("password")} />
                ) : (
                  <BiHide className="absolute top-[12px] right-[10px] cursor-pointer" onClick={() => passwordToggleHandle("password")} />
                )}
              </div>
              {errors.password && touched.password && <span className="text-sm text-red-400">{errors.password}</span>}
            </div>
            <div className="flex items-start mb-6 justify-between">
              <div className="flex"> </div>
              <div className="flex">
                <p className="text-sm text-DarkSlateGray hover:underline hover:text-Apple font-medium cursor-pointer" onClick={() => navigate("/forgot-password")}>
                  Forgot Password
                </p>
              </div>
            </div>
            <div className="flex w-full justify-center mb-4">
              {isSubmit ? (
                <button type="submit" className="text-f-submit w-[80px]" disabled={isSubmit}>
                  <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                </button>
              ) : (
                <button type="submit" className="text-f-submit">
                  Login
                </button>
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
