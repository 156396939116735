import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../AddInvoice/add.css";

import { CustomerContactList, CustomerDetailById } from "../../../services/helpers/customers";
import { ProductAllList } from "../../../services/helpers/products";
import { InvoiceDetailById, UpdateInvoiceAPI } from "../../../services/helpers/invoices";
import { errorToast2 } from "../../Toast";
import Cookies from "js-cookie";
import UpdateInvoiceForm from "./UpdateInvoiceForm";

const initialItem = {
  productUniqueId: "",
  productName: "",
  unit: "",
  rate: 0,
  sellingPrice: 0,
  productDescription: "",
  quantity: 0,
};

const UpdateInvoice = () => {
  const [onceCheckIsDeleted, setOnceCheckIsDeleted] = useState(1);
  const [deleteItem, setDeleteItem] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [subTotalV, setSubTotalV] = useState(0);
  const [loading, setLoading] = useState(true);
  const [initialValuesInvoice, setInitialValuesInvoice] = useState({});
  const params = useParams();
  const Formikref = useRef(null);
  const [itemlist, setItemList] = useState([initialItem]);
  const [address, setAddress] = useState({ type: 1, address: [] });
  const [itemListApi, setItemListApi] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const navigate = useNavigate();

  const submitForm = (values) => {
    // setIsSubmit(true);
    const { customer_id, inv_id, invoice_date, payment_terms, due_date, tax, tax_type, order_number, purchase_order, invoiceUniId } = values;
    const itemLists = itemlist
      .filter((item) => item.productUniqueId.length > 0)
      .map((item) => {
        return { productId: item.productUniqueId, quantity: item.quantity, gstPercentage: item.gst_percentage, description: item.description || " " };
      });

    let newItems = invoiceDetails?.itemDetails
      ?.filter((data) => {
        return itemLists.some((obj) => obj.productId === data.product_id) ? null : data;
      })
      ?.map((data) => {
        return { productId: data.product_id, quantity: 0, gstPercentage: 0, description: " " };
      });

    let itemDetails = newItems?.length > 0 ? [...itemLists, ...newItems] : itemLists;

    const payload = {
      userId: Cookies.get("userId"),
      invoiceId: inv_id,
      invoiceUniId: invoiceUniId,
      customerId: customer_id,
      invoiceDate: invoice_date,
      invoiceTerms: payment_terms,
      invoiceDueDate: due_date,
      // customerNotes: subject,
      tax: tax_type || "",
      taxType: tax,
      orderNumber: order_number,
      purchaseOrder: purchase_order,
      itemDetails,
    };

    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };

    if (itemDetails?.length > 0) {
      const invalidItemGST = itemDetails?.filter((item) => item.gstPercentage === undefined && item.productId !== "");

      if (invalidItemGST?.length > 0) {
        setItemList((prev) => {
          return prev.map((item, i) => {
            if (item.gst_percentage || item.gst_error) {
              let { gst_error, ...rest } = item;
              return {
                ...rest,
              };
            } else {
              return {
                ...item,
                gst_error: "Please select tax",
              };
            }
          });
        });
      } else {
        setIsSubmit(true);
        UpdateInvoiceAPI(payload, paramsData, (res) => {
          setIsSubmit(false);
          if (res?.status == true) {
            setTimeout(() => {
              navigate("/invoices");
            }, 200);
          }
        });
      }
    } else {
      errorToast2("At least select one item");
      setIsSubmit(false);
    }

  };

  // const [value, setValue] = useState(null);

  const addItemHandle = (e) => {
    e.preventDefault();
    if (itemlist.filter((data) => itemListApi.some((item) => data.productUniqueId === item.productUniqueId))?.length !== itemListApi?.length) {
      const item = itemlist.filter((item) => item.productUniqueId === "");
      if (item.length > 0) {
        alert("Please select item, first");
      } else {
        setItemList((prev) => [...prev, initialItem]);
      }
    }
  };

  const updateItemList = (index, value) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i == index) {
          // return { ...value,productUniqueId: value.productUniqueId, total: value.sellingPrice * 1 * (value.quantity * 1) };
          return {
            ...value,
            productUniqueId: value.productUniqueId,
            total: value.sellingPrice * 1 * (value.quantity ? value.quantity : 1 * 1),
            quantity: value.quantity ? value.quantity : 1,
          };
        }
        return item;
      });
    });
  };

  const updateItemQuantity = (index, value) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i == index) {
          return {
            ...item,
            quantity: value,
            total: item.sellingPrice * 1 * (value * 1),
          };
        }
        return item;
      });
    });
  };

  const updateItemDescription = (index, value) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            description: value,
          };
        }
        return item;
      });
    });
  }

  const updateItemPercentage = (index, value) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          let { gst_error, ...rest } = item;
          return {
            ...rest,
            gst_percentage: value?.percentage,
          };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    let TotalWithPercentage =
      itemlist.reduce(function (acc, item) {
        if ((item?.gst_percentage || item?.gst_percentage == 0) && item.quantity && item.sellingPrice) {
          return acc * 1 + item.sellingPrice * item.quantity + (item.gst_percentage / 100) * (item.sellingPrice * item.quantity);
        } else {
          return acc * 1;
        }
      }, 0) * 1;

    let subt =
      itemlist.reduce(function (acc, obj) {
        if (obj.productUniqueId?.length > 0) {
          return acc * 1 + obj.total * 1;
        } else return acc * 1;
      }, 0) * 1;
    setSubTotalV(TotalWithPercentage);
  }, [itemlist]);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const AddressHandle = (e, type) => {
    e.preventDefault();
    handleOpen();
    setAddress((prev) => {
      return { address: prev.address, type: type };
    });
  };

  useEffect(() => {
    const paramsData = { queryParams: { userId: Cookies.get("userId") }, headers: {}, isAuthorized: true };
    CustomerContactList(paramsData, (res) => {
      setContactList(res?.data);
    });
    const paramsDataP = {
      queryParams: {
        userId: Cookies.get("userId"),
      },
      headers: {},
      isAuthorized: true,
    };
    ProductAllList(paramsDataP, (res) => {
      setItemListApi(res?.data);
    });
  }, []);

  const getCustomerAddressHandle = (id) => {
    const queryParams = {
      customerId: id,
      userId: Cookies.get("userId"),
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    CustomerDetailById(paramsData, (res) => {
      setAddress((prev) => {
        return { address: res?.data?.customerAddress, type: prev.type };
      });
    });
  };

  useEffect(() => {
    if (contactList.length > 0) {
      const queryParams = {
        // inv_id: params.id,
        inv_uni_id: params.id,
        userId: Cookies.get("userId"),
      };
      const paramsDataI = { queryParams, headers: {}, isAuthorized: true };

      InvoiceDetailById(paramsDataI, (res) => {
        // let fi = res.data.customerAddress.filter((item) => item.type == 2);
        initialValuesHandle(res.data);
        setInvoiceDetails(res?.data);
      });
    }
  }, [contactList]);

  const initialValuesHandle = (invoice) => {
    let itemDetail = invoice.itemDetails.map((item) => {
      return {
        productUniqueId: item.product_id,
        description: item.description,
        productId: "",
        quantity: item?.quantity,
        sellingPrice: item?.selling_price,
        total: item?.subtotal_price,
        productName: item?.product_name,
        productUnit: item?.product_unit,
        productType: "",
        gst_percentage: item?.gst_percentage,
      };
    });

    let customer = contactList.filter((list) => {
      return list.customer_id == invoice.customer_id;
    });
    customer && setSelectedCustomer(customer[0]);
    const initialValues = {
      inv_id: invoice?.inv_id,
      invoiceId: invoice?.inv_id,
      invoiceUniId: invoice?.inv_uni_id,
      customer_id: invoice?.customer_id,
      invoice_date: invoice?.inv_date,
      payment_terms: invoice?.inv_terms,
      due_date: invoice?.due_date,
      tax: invoice?.gst_type,
      tax_type: invoice?.gst || "",
      subject: invoice?.inv_subject,
      order_number: invoice?.order_number || "",
      purchase_order: invoice?.purchase_order || "",
    };

    setInitialValuesInvoice(initialValues);
    setItemList(itemDetail);

    Formikref?.current?.setFieldValue("customer_id", invoice.customer_id);
    getCustomerAddressHandle(invoice.customer_id);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  useEffect(() => {
    if (itemlist && itemlist[0]?.productUniqueId && itemListApi && onceCheckIsDeleted === 1) {
      if (itemlist.map((data) => itemListApi.some((item) => data?.productUniqueId === item?.productUniqueId || !data?.productUniqueId)).includes(false)) {
        setItemList(
          itemlist.map((data) => {
            if (itemListApi.some((item) => data.productUniqueId === item.productUniqueId)) {
              return data;
            } else {
              return { ...data, is_deleted: 1 };
            }
          })
        );
        setOnceCheckIsDeleted(2);
      }
    }
  }, [itemListApi, itemlist]);

  return (
    <UpdateInvoiceForm
      setDeleteItem={setDeleteItem}
      isSubmit={isSubmit}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      subTotalV={subTotalV}
      loading={loading}
      initialValuesInvoice={initialValuesInvoice}
      Formikref={Formikref}
      itemlist={itemlist}
      setItemList={setItemList}
      address={address}
      itemListApi={itemListApi}
      contactList={contactList}
      selectedCustomer={selectedCustomer}
      setSelectedCustomer={setSelectedCustomer}
      invoiceDetails={invoiceDetails}
      setInvoiceDetails={setInvoiceDetails}
      submitForm={submitForm}
      addItemHandle={addItemHandle}
      updateItemList={updateItemList}
      updateItemQuantity={updateItemQuantity}
      handleOpen={handleOpen}
      handleClose={handleClose}
      AddressHandle={AddressHandle}
      getCustomerAddressHandle={getCustomerAddressHandle}
      updateItemPercentage={updateItemPercentage}
      updateItemDescription={updateItemDescription}
    />
  );
};

export default UpdateInvoice;
