import React from 'react'
// import Address from "./Address";
import { MdExpandMore } from 'react-icons/md'
import { CgDetailsMore } from 'react-icons/cg'
// import OtherDetails from "./OtherDetails";

const LeftSection = ({ productListByIdItem }) => {
  return (
    <div className="mx-5 my-8">
      <div className="flex w-full">
        <div className="w-1/4">
          <p className='font-semibold'>Item Type</p>
        </div>
        <div className="">
          <p>{productListByIdItem?.productType}</p>
        </div>
      </div>

      <div className="flex  w-full">
        <div className="w-1/4">
          <p className='font-semibold'>Unit</p>
        </div>
        <div className="">
          <p>{productListByIdItem?.productUnit}</p>
        </div>
      </div>

      <div className="flex  w-full">
        <div className="w-1/4">
          <p className='font-semibold'>{productListByIdItem?.productType === "Services" ? "SAC Code" : "HSN Code"}</p>
        </div>
        <div className="">
          <p>{productListByIdItem?.item_code}</p>
        </div>
      </div>

      {/* <div className="flex w-full">
        <div className="w-1/4">
          <p>Created Source</p>
        </div>
        <div className="">
          <p>User</p>
        </div>
      </div> */}

      <div>
        <p className="text-lg mt-8 mb-4 font-semibold">Sales Information</p>
      </div>
      <div className="flex w-full">
        <div className="w-1/4">
          <p className='font-semibold'>Selling Price</p>
        </div>
        <div className="">
          <p>{productListByIdItem?.sellingPrice}</p>
        </div>
      </div>

      <div className="flex w-full">
        <div className="w-1/4">
          <p className='font-semibold'>Description</p>
        </div>
        <div className="">
          <p>{productListByIdItem?.productDescription}</p>
        </div>
      </div>
    </div>
  )
}

export default LeftSection
