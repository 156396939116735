import React from "react";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";

import ChangePassword from "../../components/profile/ChangePassword";
import ProfileEditForm from "../../components/profile/ProfileEditForm";
import UPIComponent from "../../components/profile/UPIComponent";
import BankComponent from "../../components/profile/BankComponent";
import TabsSwipper from "../../components/profile/TabsSwipper";
import QRCodeUpload from "../../components/profile/QRCodeUpload";

const Profile = () => {

  let tablists = [
    {
      label: "Bank Account",
      com: <BankComponent />
    },
    {
      label: "Unified Payments Interface",
      com: <UPIComponent />
    },
    {
      label: "Upload QR Code",
      com: <QRCodeUpload/>

    }
  ]

  return (
    <DashboardHeader title="Edit Profile">
      <ProfileEditForm />
      <TabsSwipper tablist={tablists} />
      <ChangePassword />
    </DashboardHeader>
  );
};

export default Profile;
