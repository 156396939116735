import { useEffect, useRef, useState } from "react";
import { FcEditImage } from "react-icons/fc";
import Skeleton from "react-loading-skeleton";
import "./skeleton.css";

const EditProfileImage = ({ previewImage, setPreviewImage, imgfile, setImgFile, userProfile }) => {
  const [imgLoading, setImageLoading] = useState(false);
  const fileRef = useRef();
  const editIconHandle = () => {
    fileRef.current.click();
  };
  const avatarInputHandle = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    setImgFile(e.target.files[0]);
    setImageLoading(true);
    setTimeout(() => {
      setImageLoading(false);
    }, 2000);
  };

  useEffect(()=>{
    setPreviewImage(userProfile?.profile_pic)
  },[])


  return (
    <>
      <div className="flex justify-center items-center w-[200px] h-[200px] m-auto mb-8 relative">
        {imgLoading ? (
          <Skeleton circle height="100%"  containerClassName="rounded-full w-full h-full" />
        ) : (
          <>
            <img src={previewImage} alt="" className="rounded-full w-full h-full" />
            <div
              data-bs-toggle="tooltip"
              title="Change Image"
              className="absolute bottom-0 right-0 cursor-pointer"
              onClick={(e) => {
                editIconHandle();
              }}
            >
              <FcEditImage className="text-2xl" />
            </div>{" "}
          </>
        )}
        <input type="file" multiple accept="image/*" className="bg-black" ref={fileRef} onChange={avatarInputHandle} hidden />
      </div>
      <p className="text-center">
        <span className="text-center mb-8 text-DarkSlateGray text-xl font-semibold">{userProfile.email}</span>
      </p>
    </>
  );
};

export default EditProfileImage;
