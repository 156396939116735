import { deleteApiCall, getApiCall, postApiCall, putApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";
import { successToast, errorToast } from "../../../components/Toast";
import Cookies from "js-cookie";
import { store } from "../../../store";
import { setUserData } from "../../../store/actions/UserData";

export const UserLogin = async (payload, callback) => {
  const url = `${BASE_URL}${API.Login}`;

  try {
    const response = await postApiCall(url, payload);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const UserRegister = async (payload, callback) => {
  const url = `${BASE_URL}${API.Register}`;

  try {
    const response = await postApiCall(url, payload);
    if (response.status === false) {
      callback(response);
      errorToast(response.message);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const UserResetPassword = async (path, payload, callback) => {
  const url = `${BASE_URL}${API.ResetPassword}${path}`;

  try {
    const response = await postApiCall(url, payload);
    if (response.status === false) {
      callback(response);
      errorToast(response.message);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const UserResetLink = async (payload, callback) => {
  const url = `${BASE_URL}${API.Reset}`;

  try {
    const response = await postApiCall(url, payload);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const UserChangePassword = async (payload, callback) => {
  const url = `${BASE_URL}${API.ChangePassword}`;

  try {
    const response = await putApiCall(url, payload);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const UserProfileChange = async (payload, paramsData, callback) => {
  const url = `${BASE_URL}${API.UpdateProfile}`;

  try {
    const response = await putApiCall(url, payload, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const UserProfileAPI = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.UserDetail}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};

/* ==================== Account API ======================== */

export const UserAccountAPI = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.UserAccount}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};

export const UserAccountUpdate = async (payload, paramsData, callback) => {
  const url = `${BASE_URL}${API.AccountAdd}`;

  try {
    const response = await postApiCall(url, payload, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const DeleteAccountById = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.AccountDelete}`;
  try {
    const response = await deleteApiCall(url, {}, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error  ${error}`);
    callback({
      message: "Something went wrong! Please try again",
      status: false,
    });
  }
};
/* ==================== UPI API ======================== */

export const UserUPIAPI = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.UserUPI}`;
  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
      callback(response);
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};

export const UserUPIUpdate = async (payload, paramsData, callback) => {
  const url = `${BASE_URL}${API.UPIAdd}`;

  try {
    const response = await postApiCall(url, payload, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const DeleteUPIById = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.UPIDelete}`;
  try {
    const response = await deleteApiCall(url, {}, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error  ${error}`);
    callback({
      message: "Something went wrong! Please try again",
      status: false,
    });
  }
};



export const UserQrCodeUpload = async (payload, paramsData, callback) => {
  const url = `${BASE_URL}${API.UploadQrCode}`;

  try {
    const response = await postApiCall(url, payload, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
    callback({});
  }
};

export const userProfileData = () => {
  const queryParams = {
    userId: Cookies.get("userId"),
  };
  const paramsData = { queryParams, headers: {}, isAuthorized: true };
  UserProfileAPI(paramsData, (res) => {
    // console.log(res?.data)
    store.dispatch(setUserData(res?.data));
  });
};
