import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import MuiTableItem from "./MuiTableItem";
import { useEffect, useState } from "react";
import { ProductList } from "../../../services/helpers/products";
import Loader from "../../common/Loader";
import Cookies from "js-cookie";

const ListView = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [productListItem, setProductListItem] = useState([]);
  const ItemsState = useSelector((state) => state.itemsReducers);
  const clickLink = (pathname) => {
    navigate(pathname);
  };

  const opPageChange = (e, page_number) => {
    e.preventDefault();
    setPageNum(page_number);
  };

  const getProductList = () => {
    setLoading(true);
    const queryParams = {
      pageNum: pageNum,
      userId: Cookies.get("userId"),
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    ProductList(paramsData, (res) => {
      setProductListItem(res?.data?.productList);
      setTotalPageNumber(res?.data?.pagination?.last_page);
      setLoading(false);
    });
  };

  useEffect(() => {
    getProductList();
  }, [pageNum]);

  return (
    <div
      className={`w-full  ${ItemsState.id && "-lg:hidden"} shadow-xl`}
      // style={{backgroundColor:'gray'}}
    >
      <div className="flex justify-between p-2 items-center">
        <div className="text-xl">Items</div>
        <div className="flex">
          <button
            type="submit"
            className="text-f-button flex items-center gap-1 "
            onClick={() => {
              clickLink("/item/new");
            }}
          >
            <AiOutlinePlusCircle /> New
          </button>
        </div>
      </div>
      <hr />
      <div className="">
        {loading ? (
          <Loader classes="min-h-screen" />
        ) : (
          <MuiTableItem
            cId={ItemsState.id}
            productListItem={productListItem}
            pageNum={pageNum}
            totalPageNumber={totalPageNumber}
            opPageChange={opPageChange}
            getProductList={getProductList}
          />
        )}
      </div>
    </div>
  );
};

export default ListView;
