import * as yup from "yup";

export const initialValues = {
  first_name: "",
  email: "",
  password: "",
};

export const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("Name is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  password: yup.string().required("Password is required").min(6, "Password must be at least 6 characters").max(40, "Password must not exceed 40 characters"),
});
