import React from 'react'
import AddressAccordionDetails from './AddressAccordionDetails'
import { Paper } from '@mui/material'

const OtherDetails = () => {
  return (
    <Paper className='px-4 py-2'>
        <AddressAccordionDetails />
    </Paper>
  )
}

export default OtherDetails