import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "./TabPanel";

function a11yProps(index) {
  return {
    id: `bank-upi-tab-${index}`,
    "aria-controls": `bank-upi-tabpanel-${index}`,
  };
}

function TabsSwipper({ tablist }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="bank upi tabs" variant="fullWidth">
          {tablist.map((tab, i) => {
            return <Tab label={tab.label} {...a11yProps(i)} key={i}/>;
          })}

        </Tabs>
      </Box>
      {tablist.map((tab, i) => {
        return (
          <TabPanel value={value}  key={i} index={i}>
            {tab?.com}
          </TabPanel>
        );
      })}
    </Box>
  );
}

export default TabsSwipper;
