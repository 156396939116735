import DashboardC from "../../components/Dashboard";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";
const Dashboard = () => {
  return (
    <DashboardHeader title="Dashboard">
      <DashboardC />
    </DashboardHeader>
  );
};

export default Dashboard;
