import React from "react";

const AccountForm = ({ accountDetails, HandleChangeText, errorAccount, HandleFocusText, setErrorAccount }) => {
  // console.log(accountDetails)
  return (
    <>
      <div className="flex gap-4 -lg:flex-col">
        <div className="mb-6 grow shrink basis-0">
          <label htmlFor="bank_name" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
            Bank Name
          </label>
          <input
            type="text"
            name="bank_name"
            className="text-f-style"
            placeholder="Enter Bank Name"
            value={accountDetails.bank_name}
            onChange={(e) => {
              HandleChangeText(e, accountDetails?.id_r);
            }}
            onFocus={(e) => {
              HandleFocusText(e, accountDetails?.id_r);
            }}
          />
          {errorAccount["bank_name"][accountDetails?.id_r] && <span className="text-sm text-red-400">{errorAccount["bank_name"][accountDetails?.id_r]}</span>}
        </div>
        <div className="mb-6 grow shrink basis-0">
          <label htmlFor="account_number" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
            Account Number
          </label>
          <input
            type="number"
            name="account_number"
            className="text-f-style"
            placeholder="Enter Account Number"
            value={accountDetails.account_number}
            onChange={(e) => {
              HandleChangeText(e, accountDetails?.id_r);
            }}
            onFocus={(e) => {
              HandleFocusText(e, accountDetails?.id_r);
              if(errorAccount["account_number"][accountDetails?.id_r]){
                setErrorAccount((prev) => {
                  return { ...prev, account_number: { ...prev.account_number, [accountDetails?.id_r]: "" } };
                });
              }
            }}
            onBlur={(e) => {
              if (e.target.value.length > 17) {
                setErrorAccount((prev) => {
                  return { ...prev, account_number: { ...prev.account_number, [accountDetails?.id_r]: "Account Number must not exceed 17 digits" } };
                });
              } else if (e.target.value.length < 10 && e.target.value !== "") {
                setErrorAccount((prev) => {
                  return { ...prev, account_number: { ...prev.account_number, [accountDetails?.id_r]: "Account Number must be at least 10 digits" } };
                });
              }
            }}
          />
          {errorAccount["account_number"][accountDetails?.id_r] && <span className="text-sm text-red-400">{errorAccount["account_number"][accountDetails?.id_r]}</span>}
        </div>
      </div>

      <div className="flex gap-4 -lg:flex-col">
        <div className="mb-6 grow shrink basis-0">
          <label htmlFor="ifsccode" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
            IFSC Code
          </label>
          <input
            type="text"
            name="ifsc_code"
            className="text-f-style"
            placeholder="Enter IFSC Code"
            value={accountDetails.ifsc_code}
            onChange={(e) => {
              HandleChangeText(e, accountDetails?.id_r);
            }}
            onFocus={(e) => {
              HandleFocusText(e, accountDetails?.id_r);
              setErrorAccount((prev) => {
                return { ...prev, ifsc_code: { ...prev.ifsc_code, [accountDetails?.id_r]: "" } };
              });
            }}
            onBlur={(e) => {
              let ifscReg4char = /^[a-zA-Z]{4}/
              if (!ifscReg4char.test(e.target.value) && e.target.value >= 4) {
                setErrorAccount((prev) => {
                  return { ...prev, ifsc_code: { ...prev.ifsc_code, [accountDetails?.id_r]: "IFSC Code first 4 values should be alphabets" } };
                });
              } else if (e.target.value.length !== 11 && e.target.value !== "") {
                setErrorAccount((prev) => {
                  return { ...prev, ifsc_code: { ...prev.ifsc_code, [accountDetails?.id_r]: "IFSC Code must be 11 digits" } };
                });
              }
            }}
          />
          {errorAccount["ifsc_code"][accountDetails?.id_r] && <span className="text-sm text-red-400">{errorAccount["ifsc_code"][accountDetails?.id_r]}</span>}
        </div>
        <div className="mb-6 grow shrink basis-0">
          <label htmlFor="account_holder" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
            Account Holder Name
          </label>
          <input
            type="text"
            name="account_holder"
            className="text-f-style"
            placeholder="Enter Account Holder Name"
            value={accountDetails.account_holder}
            onChange={(e) => {
              const charAllow = /^[A-Za-z\s]*$/;
              if (e.target.value === "" || charAllow.test(e.target.value)) {
                HandleChangeText(e, accountDetails?.id_r);
              }
            }}
            onFocus={(e) => {
              HandleFocusText(e, accountDetails?.id_r);
            }}
          />
          {errorAccount["account_holder"][accountDetails?.id_r] && <span className="text-sm text-red-400">{errorAccount["account_holder"][accountDetails?.id_r]}</span>}
        </div>

      </div>
      <div className="mb-6 grow shrink basis-0">
        <label htmlFor="address" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
          Branch Name
        </label>
        <input
          type="text"
          name="address"
          className="text-f-style"
          placeholder="Enter branch name"
          value={accountDetails.address}
          onChange={(e) => {
            HandleChangeText(e, accountDetails?.id_r);
          }}
          onBlur={(e) => {
            if ((e.target?.value || "").length > 50) {
              setErrorAccount((prev) => {
                return { ...prev, address: { ...prev.address, [accountDetails?.id_r]: "Address not more than 50 character"} };
              });
            }
          }}
          onFocus={(e) => {
            HandleFocusText(e, accountDetails?.id_r);
            setErrorAccount((prev) => {
              return { ...prev, address: { ...prev.address, [accountDetails?.id_r]: "" } };
            });
          }}
        />
        {errorAccount["address"][accountDetails?.id_r] && <span className="text-sm text-red-400">{errorAccount["address"][accountDetails?.id_r]}</span>}
      </div>
    </>
  );
};

export default AccountForm;
