const initialState = {
    isCustomerselected: false,
    id: "",
    customerName: "",
  };
  
  function InvoicesReducers(state = initialState, action) {
    switch (action.type) {
      case "INVOICEUPDATE":
        return {
          ...state,
          customerName: action.payload.name,
        };
      case "INVOICEUPDATEID":
        return {
          ...state,
          id: action.payload.id,
        };
      default:
        return state;
    }
  }
  
  export default InvoicesReducers;
  