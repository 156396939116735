export function nameValidate(value){
    if(false){
        return {Error: "Invalid Account Name"}
    }
    return false
}

export function accountnumberValidate(value) {    
    if(false){
        return {Error: "Invalid Account Name"}
    }
    return false
    // return {Error: "Invalid Account Name"}
}

export function ifscValidate(value) {
    if(false){
        return {Error: "Invalid Ifsc Code"}
    }
    return false
}

export function holderValidate(value){
    if(false){
        return { Error: "Invalid Account Holder"}
    }
    return false
}

export function upiIDValidate(value){
    if(false){
        return {Error: "Invalid Upi Id "}
    }
    return false
}

export function upiNumberValidate(value){
    if(false){
        return {Error: "Invalid Upi Number "}
    }
    return false
}

export function upiTypeValidate(value){
    if(false){
        return {Error: "Invalid UPI Name"}
    }
    return false
}