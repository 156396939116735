import React from 'react'
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import ItemFormUpdate from '../../components/Items/update/ItemFormUpdate'
import { useDispatch, useSelector } from "react-redux";


const ItemUpdate = () => {

  return (
    <DashboardHeader>
      <ItemFormUpdate />
    </DashboardHeader>
  )
}

export default ItemUpdate