import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { MdExpandMore } from "react-icons/md";
import AddressAccordionDetails from "./AddressAccordionDetails";
import { ADDRESSDETAILS1 } from "../../../mockData";

export default function Address({address}) {
  return (
    <div>
      <Accordion className="">
        <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="!font-semibold">Billing Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <AddressAccordionDetails items={ADDRESSDETAILS1} type="1" address={address} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography className="!font-semibold">Shipping Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <AddressAccordionDetails items={ADDRESSDETAILS1} type="2" address={address} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
