import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Autocomplete, TableCell, TableRow } from "@mui/material";
import { TextField as TextFieldMUI } from "@mui/material";
import { GST_PER_ITEMS } from "../../../mockData";
import "./add.css";

const RowTable = ({ item, index, itemlist, setItemList, updateItemList, updateItemQuantity, updateItemDescription, products, setDeleteItem, updateItemPercentage, taxList, taxType }) => {
  const [quantity, setQuantity] = useState(item.quantity ? item.quantity : "0");
  const [itemName, setItemName] = useState(null);
  const [itemGst, setItemGst] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [description, setDescription] = useState(item.description ? item.description : "");
  useEffect(() => {
    let gstID = null;
    if (item.gst_percentage || item.gst_percentage == 0) {
      gstID = GST_PER_ITEMS.filter((tax) => tax.percentage == item.gst_percentage)[0];
    }
    setItemName(item);
    setItemGst(gstID);
  }, []);

  return (
    <TableRow className="">
      <TableCell align="center" className="!align-top !p-0 border-r border-gray-200">
        {!item.item_id?.length > 0 ? (
          <>
            <Autocomplete
              className="appearance-none bg-gray-50 w-full py-0 px-0 outline-none text-f-table grow shrink basis-0 leading-3 !border-none max-w-[500px] min-w-[222px]"
              id="free-solo-2-demo"
              size="small"
              disableClearable
              value={itemName}
              options={products}
              getOptionDisabled={(option) => itemlist.some((value) => value.productUniqueId === option.productUniqueId)}
              getOptionLabel={(option) => option.productName}
              renderInput={(params) => <TextFieldMUI {...params} className="!border-none !pt-0 !pb-0 " />}
              onChange={(event, newValue) => {
                setItemName(newValue);
                if (itemGst) {
                  updateItemList(index, { ...newValue, gst_percentage: itemGst?.percentage });
                } else {
                  updateItemList(index, { ...newValue });
                }
              }}
            />
            {item?.productUniqueId?.length > 0 && (
              <>
                <div className="flex gap-4 text-sm max-w-[500px]">
                  <input
                    type="text"
                    id="description"
                    name="description"
                    className="text-f-customer grow shrink basis-0 leading-3"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => {
                      if(e?.target?.value?.length < 26){
                        setDescription(e.target.value);
                        updateItemDescription(index, e.target.value);
                        errorDescription && 
                        setErrorDescription(null)
                      } else {
                        setErrorDescription("Description must be 25 characters")
                      }
                    }}
                    onBlur={()=>{
                      setErrorDescription(null)
                    }}
                  />
                </div>
                {
                  errorDescription && 
                  <span className="text-sm text-red-400">{errorDescription}</span>
                }
                {/* {errors["invoiceId"] && (values["invoiceId"] || touched["invoiceId"]) && (
                    <>
                      <span> </span>
                    </>
                  )} */}
              </>
            )}
          </>
        ) : (
          <>
            <h3 className="text-left p-1 pb-2 ">{item.item_name}</h3>
            <p className="text-left p-1 pl-2 pb-4 bg-gray-100">{item.productDescription}</p>
          </>
        )}
        {item?.is_deleted ? <p className="p-2 text-orange-600 font-semibold"> This Item has been deleted </p> : null}
      </TableCell>
      <TableCell align="center" className="!align-top !p-0 border-r border-gray-200 w-20">
        <input
          readOnly={item?.is_deleted}
          type="text"
          name="quantity"
          className="text-f-table max-w-[500px] text-right py-[11px] "
          placeholder="Quantity"
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
            updateItemQuantity(index, e.target.value);
          }}
          min="1"
          onBlur={(e) => {
            if (e.target.value.length <= 0 || e.target.value <= 0) {
              setQuantity("1");
            }
          }}
        />
        <p className="text-right p-2">{item.productUnit}</p>
      </TableCell>
      <TableCell align="center" className="!align-top border-r border-gray-200 !p-2">
        {item?.productUniqueId?.length > 0 && item?.sellingPrice.toFixed(2)}
        {/* <input readOnly value={item.sellingPrice} type="text" id="rate" name="rate" className="text-f-table max-w-[500px] text-right" placeholder="Rate" /> */}
      </TableCell>
      <TableCell align="center" className="!align-top !p-0 border-r border-gray-200">
        {/* {
          taxList?.length> 0 ?  */}
        <>
          <Autocomplete
            className="appearance-none bg-gray-50 w-full py-0 px-0 outline-none text-f-table grow shrink basis-0 leading-3 !border-none max-w-[500px] min-w-[222px]"
            size="small"
            id="free-solo-2-demo"
            disableClearable
            value={itemGst}
            options={GST_PER_ITEMS}
            // getOptionDisabled={(option) => itemlist.some((value) => value.productUniqueId === option.productUniqueId)}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextFieldMUI {...params} className="!border-none !pt-0 !pb-0 " />}
            onChange={(event, newValue) => {
              setItemGst(newValue);
              updateItemPercentage(index, newValue);
            }}
          />
          <p className="text-[.8rem] text-left p-1 pl-2  text-red-400">{item.gst_error}</p>
        </>
        {/* :
         <p className="text-left p-1 pl-2 pb-4 bg-red-300">Tax not Found</p>
        } */}
      </TableCell>
      <TableCell align="center" className="!align-top !p-2 relative">
        {itemGst?.id && item?.productUniqueId?.length > 0 && (taxType === "cgst" || taxType === "sgst")
          ? itemGst?.percentage == "0"
            ? itemGst?.percentage
            : itemGst?.percentage / 2
          : item?.productUniqueId?.length > 0
          ? "N/A"
          : ""}
      </TableCell>
      <TableCell align="center" className="!align-top !p-2 relative">
        {itemGst?.id && item?.productUniqueId?.length > 0 && (taxType === "cgst" || taxType === "sgst") ? itemGst?.percentage / 2 : item?.productUniqueId?.length > 0 ? "N/A" : ""}
      </TableCell>
      <TableCell align="center" className="!align-top !p-2 relative">
        {itemGst?.id && item?.productUniqueId?.length > 0 && taxType === "igst" ? itemGst?.percentage : item?.productUniqueId?.length > 0 ? "N/A" : ""}
      </TableCell>
      <TableCell
        align="center"
        className={`!align-top !p-2 relative overflow-hidden !pr-4 ${
          taxType !== "" && itemGst?.id && item?.productUniqueId?.length > 0 ? "app-tick-ammount-right" : "app-tick-ammount-wrong"
        }`}
      >
        {itemGst?.id &&
          item?.productUniqueId?.length > 0 &&
          taxType !== "" &&
          (itemGst?.percentage ? ((item.sellingPrice * quantity * itemGst.percentage) / 100 + item.sellingPrice * quantity).toFixed(2) : (item.sellingPrice * quantity).toFixed(2))}

        {itemlist.length > 1 && (
          <AiOutlineCloseCircle
            onClick={() => {
              if (itemlist.length > 1) {
                let currentItemList = [...itemlist];
                let deletedItem = currentItemList.splice(index, 1);
                if (deletedItem[0]?.is_deleted) {
                  setDeleteItem((prev) => {
                    if (prev) {
                      return [...prev, deletedItem[0]];
                    } else {
                      return [deletedItem[0]];
                    }
                  });
                }
                setItemList(currentItemList);
              }
            }}
            className="absolute top-[2px] right-[2px] text-lg cursor-pointer"
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default RowTable;
