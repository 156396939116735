import { getApiCall, postApiCall, putApiCall, deleteApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";

export const TermsList = async (paramsData, callback) => {
    const url = `${BASE_URL}${API.Terms}`;
  
    try {
      const response = await getApiCall(url, paramsData);
      if (response.status === false) {
      } else if (response.status === true) {
        callback(response);
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };
export const TaxListAPI = async (paramsData, callback) => {
    const url = `${BASE_URL}${API.Tax}`;
  
    try {
      const response = await getApiCall(url, paramsData);
      if (response.status === false) {
      } else if (response.status === true) {
        callback(response);
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };