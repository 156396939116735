import { errorToast, successToast } from "../../../components/Toast";
import { getApiCall, postApiCall, putApiCall, deleteApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";


export const AddProduct = async (payload, paramsData, callback) => {
    const url = `${BASE_URL}${API.AddProduct}`

    try {
        const response = await postApiCall(url, payload, paramsData);
        if (response.status === false) {
          errorToast(response.message);
          callback(response)
        } else if (response.status === true) {
          successToast(response.message);
            callback(response)
        }
      } catch (error) {
        console.log(`Error ${error}`);
        callback({})
      }
}

export const UpdateProduct = async (payload,paramsData, callback) => {
    const url = `${BASE_URL}${API.UpdateProduct}`

    try {
        const response = await putApiCall(url, payload, paramsData);
        if (response.status === false) {
          errorToast(response.message);
          callback(response)
        } else if (response.status === true) {
          successToast(response.message);
            callback(response)
        }
      } catch (error) {
        console.log(`Error ${error}`);
        callback({})
      }
}

export const ProductList = async (paramsData, callback) => {
    const url = `${BASE_URL}${API.ProductList}`

    try {
        const response = await getApiCall(url, paramsData);
        if (response.status === false) {
        } else if (response.status === true) {
            callback(response)
        }
      } catch (error) {
        console.log(`Error ${error}`);
      }
}

export const ProductAllList = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.ProductAllList}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};

export const DeleteProductById = async (paramsData,callback) => {
    const url = `${BASE_URL}${API.DeleteProduct}`

    try {
        const response = await deleteApiCall(url,{}, paramsData);
        if (response.status === false) {
          errorToast(response.message);
        } else if (response.status === true) {
          successToast(response.message);
            callback(response)
        }
      } catch (error) {
        console.log(`Error ${error}`);
      }
}

export const ProductDetailById = async (paramsData,callback) => {
    const url = `${BASE_URL}${API.ProductDetailsById}`

    try {
        const response = await getApiCall(url,paramsData);
        if (response.status === false) {
        } else if (response.status === true) {
            callback(response)
        }
      } catch (error) {
        console.log(`Error ${error}`);
      }
}