import * as yup from "yup";

export const initialValuesAdd = {
  customer_type: "business",
  salutation: "",
  first_name: "",
  last_name: "",
  company_name: "",
  display_name: "",
  customer_email: "",
  work_phone: "",
  mobile: "",
  // pan: "",
  GSTIN: "",
  payment_terms: "",
  enable_portal: false,

  billing_attention: "",
  billing_address: "",
  billing_city: "",
  billing_state: "",
  billing_country: "India",
  billing_zip_code: "",
  billing_address_phone: "",

  shipping_attention: "",
  shipping_address: "",
  shipping_city: "",
  shipping_state: "",
  shipping_country: "India",
  shipping_zip_code: "",
  shipping_address_phone: "",
};

const charOnly = (value) => /^[A-Za-z ]+$/.test(value);
export const validationSchema = yup.object().shape({
  display_name: yup.string().required("Name is required"),
  customer_email: yup.string().required("Email is Required").email("Invalid Email"),
  first_name: yup.string().test("Digits only", "First Name should have Characters only", charOnly),
  last_name: yup.string().test("Digits only", "Last Name should have Characters only", charOnly),
  mobile: yup.string().min(10, "Number must be at least 10 digits").max(10, "Number must not exceed 10 digits"),
  work_phone: yup.string().min(10, "Work must be at least 10 digits").max(10, "Work must not exceed 10 digits"),
  pan: yup.string().matches("[A-Z]{5}[0-9]{4}[A-Z]{1}", "please enter a valid Pan Number").max(10, "please enter a valid Pan Number"),
  billing_zip_code: yup.string().matches("[0-9]{6}", "please enter a valid Zip Code").max(6, "please enter a valid Zip Code"),
  shipping_zip_code: yup.string().matches("[0-9]{6}", "please enter a valid Zip Code").max(6, "please enter a valid Zip Code"),
  billing_address_phone: yup.string().min(10, "Number must be at least 10 digits").max(10, "Number must not exceed 10 digits"),
  shipping_address_phone: yup.string().min(10, "Number must be at least 10 digits").max(10, "Number must not exceed 10 digits"),
  // // aadharnumber: yup.string().length(12),
  // // pancard: yup.string().length(10),
  // gstin: yup
  //   .string()
  //   .required("GSTIN is required")
  //   .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Given GSTIN Invalid"),
});
