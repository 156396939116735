import React, { useEffect, useState } from "react";
import { initialValues, validationSchema } from "../../../common/validate/Profile/AccountDetial";
import Cookies from "js-cookie";
import { DeleteAccountById, UserAccountAPI, UserAccountUpdate } from "../../../services/helpers/users";
import Loader from "../../common/Loader";
import DeviderHeading from "../DeviderHeading";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { makeID } from "../../../common/helpers/profile";
import { Bank_Init } from "../../../mockData";
import AccountList from "./AccountList";
import { Backdrop, CircularProgress } from "@mui/material";
import { errorToast, successToast } from "../../Toast";

const BankComponent = () => {
  const [open, setOpen] = useState(false);
  const [AccountRefresh, setAccountRefrech] = useState(false)
  const [loading, setLoading] = useState(true);
  const [accountList, setAccountList] = useState(null);

  useEffect(() => {
    const queryParams = {
      userId: Cookies.get("userId"),
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    UserAccountAPI(paramsData, (res) => {
      if (res.status === true) {
        if (res.data.length > 0) {
          //   setAccountSubmitStatus(true);
          setAccountList(
            res.data.map((data) => {
              return { ...data, id_r: makeID(8) };
            })
          );

          setLoading(false);

          //do initial value for multiple accounts
          //   if(res.data.length > 2){
          //     setAccountInitialValues({...res.data[0], bank_name2: res.data[1].bank_name, account_number2: res.data[1].account_number, ifsc_code2: res.data[1].ifsc_code, account_holder2: res.data[1].account_holder });
          //   } else {
          //     setAccountInitialValues({...res.data[0], bank_name2: res.data[1].bank_name, account_number2: res.data[1].account_number, ifsc_code2: res.data[1].ifsc_code, account_holder2: res.data[1].account_holder });
          //   }
        } else {
          //   setAccountSubmitStatus(false);
          setAccountList(
            Bank_Init.map((data) => {
              return { ...data, id_r: makeID(8) };
            })
          );
          setLoading(false);
        }
      } else {
        setLoading(false);
        // errorToast("Something went wrong!")
        setAccountList(
          Bank_Init.map((data) => {
            return { ...data, id_r: makeID(8) };
          })
        );
      }
    });
  }, [AccountRefresh]);

  const AddAccountHandle = () => {
    setAccountList((prevState) => {
      return [
        ...prevState,
        {
          id_r: makeID(8),
          ...Bank_Init[0],
        },
      ];
    });
  };

  const RemoveAccountHandle = (accountOne) => {
    setOpen(true);
    if (accountOne?.id) {
      const queryParams = {
        id: accountOne?.id,
        user_id: Cookies.get("userId"),
      };
      const paramsData = {
        queryParams,
        headers: {},
        isAuthorized: true,
      };
      DeleteAccountById(paramsData, (res) => {
        setLoading(false);
        if (res.status === true) {
          setOpen(false);
          let filterUPIDATA = accountList.filter((account, i) => account.id_r !== accountOne?.id_r);
          setAccountList(filterUPIDATA);
          successToast("Account deleted successfully");
        } else {
          setOpen(false);
          errorToast("Something went wrong! Please try again");
        }
      });
    } else {
      let filterUPIDATA = accountList.filter((account, i) => account.id_r !== accountOne?.id_r);
      setAccountList(filterUPIDATA);
      setTimeout(() => {
        setOpen(false);
        successToast("Account deleted successfully");
      }, 1000);
    }
  };

  return (
    <div className="text-black relative md:pt-2 pb-20 py-8 px-4">
      {loading ? (
        <Loader classes="min-h-screen" />
      ) : accountList && accountList.length > 0 ? (
        <>
          {/* <DeviderHeading heading="Bank Account" /> */}
          <div className="flex justify-end mb-5">
            <button
              type="button"
              className="text-f-button flex items-center gap-1 w-auto"
              onClick={() => {
                AddAccountHandle();
              }}
            >
              <AiOutlinePlusCircle /> Add
            </button>
          </div>
          <AccountList accountList={accountList} setAccountList={setAccountList} setLoading={setLoading} RemoveAccountHandle={RemoveAccountHandle} setAccountRefrech={setAccountRefrech} />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : null}
    </div>
  );
};

export default BankComponent;
