import { errorToast, successToast } from "../../../components/Toast";
import { getApiCall, postApiCall, deleteApiCall } from "../../utils/axios";
import { BASE_URL, API } from "../../utils/defines";

export const AddInvoiceAPI = async (payload, paramsData, callback) => {
  const url = `${BASE_URL}${API.AddInvoice}`;

  try {
    const response = await postApiCall(url, payload, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error  ${error}`);
    callback({});
  }
};

export const UpdateInvoiceAPI = async (payload, paramsData, callback) => {
  const url = `${BASE_URL}${API.UpdateInvoice}`;

  try {
    const response = await postApiCall(url, payload, paramsData);
    if (response.status === false) {
      errorToast(response.message);
      callback(response);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error  ${error}`);
    callback({});
  }
};

export const InvoiceList = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.InvoiceList}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error  ${error}`);
  }
}

export const DeleteInvoiceById = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.DeleteInvoice}`;

  try {
    const response = await deleteApiCall(url, {}, paramsData);
    if (response.status === false) {
      errorToast(response.message);
    } else if (response.status === true) {
      successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error  ${error}`);
  }
};

export const InvoiceDetailById = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.InvoiceDetailsById}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
    } else if (response.status === true) {
      callback(response);
    }
  } catch (error) {
    console.log(`Error  ${error}`);
  }
};

export const InvoiceDownloadById = async (paramsData, callback) => {
  const url = `${BASE_URL}${API.InvoiceDeleteById}`;

  try {
    const response = await getApiCall(url, paramsData);
    if (response.status === false) {
      // errorToast(response.message);
    } else if (response.status === true) {
      // successToast(response.message);
      callback(response);
    }
  } catch (error) {
    console.log(`Error  ${error}`);
  }
};
