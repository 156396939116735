import { Field, Formik } from "formik";
import React from "react";
import { BiDownArrowAlt } from "react-icons/bi";
import { State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { TextField, TextField2, TextFieldSub } from "../common/Field/TextField";
import Loader from "../common/Loader";
import { CircularProgress } from "@mui/material";

const CustomerForm = ({
  initialValues,
  isSubmit,
  paymentTermsList,
  copyBillingHandle,
  submitForm,
  loading,
  validationSchema,
  heading,
  emailReadOnly,
  countryReadOnly,
  submitBtn,
  validateGSTIN,
}) => {
  const navigate = useNavigate();
  return (
    <div className="text-black relative md:pt-[4.5rem] pb-4 py-8 min-h-screen">
      <h3 className="text-2xl px-4 py-2 my-2 font-semibold text-ChineseBlack">{heading}</h3>
      <hr />
      <div className="flex p-4 mt-8">
        {!paymentTermsList || loading ? (
          <Loader />
        ) : (
          initialValues &&
          paymentTermsList && (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
              {(formik) => {
                const { values, handleChange, handleSubmit, errors, touched, handleBlur, setFieldValue, setFieldError, setFieldTouched } = formik;
                return (
                  <form className="w-full rounded-md rounded-t-none p-2" onSubmit={handleSubmit}>
                    {/* <InputRadioBox name="customer_type" /> */}

                    <div className="mb-3 fr1_3 items-center">
                      <label htmlFor="customer_type" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                        Customer Type
                      </label>
                      <div role="group" aria-labelledby="my-radio-group" className="flex gap-4 text-sm max-w-[500px]">
                        <label className="flex items-center justify-center cursor-pointer">
                          <Field type="radio" name="customer_type" value="business" /> <span className="pl-1"> B2B</span>
                        </label>
                        <label className="flex items-center justify-center cursor-pointer">
                          <Field type="radio" name="customer_type" value="individual" /> <span className="pl-1">B2C</span>
                        </label>
                      </div>
                    </div>
                    <div className="mb-2 fr1_3 items-center">
                      <label htmlFor="primary_contact" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                        Primary Contact
                      </label>
                      <div className="flex gap-1 max-w-[500px]">
                        <select
                          className="appearance-none bg-gray-50 w-full py-1 px-2 outline-none text-f-customer grow shrink basis-0 leading-3"
                          id="salutation"
                          name="salutation"
                          value={values.salutation}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                            setFieldValue("display_name", "");
                          }}
                        >
                          {!values.salutation && <option value="">Salutation</option>}
                          <option value="Mr">Mr.</option>
                          <option value="Mrs">Mrs.</option>
                          <option value="Ms">Ms.</option>
                          <option value="Miss">Miss.</option>
                          <option value="Dr">Dr.</option>
                        </select>

                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          className="text-f-customer grow shrink basis-0 leading-3"
                          placeholder="Enter your First Name"
                          value={values.first_name}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                            setFieldValue("display_name", "");
                          }}
                          onBlur={handleBlur}
                        />

                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          className="text-f-customer grow shrink basis-0"
                          placeholder="Enter your Last Name"
                          value={values.last_name}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                            setFieldValue("display_name", "");
                          }}
                          onBlur={handleBlur}
                        />
                      </div>

                      {((errors.first_name && touched.first_name) || (errors.last_name && touched.last_name)) && (
                        <>
                          <div className="flex gap-1 max-w-[500px]"></div>
                          <div className="block">
                            <div>
                              <span className="text-sm text-red-400">{errors.first_name}</span>
                            </div>
                            <div>
                              <span className="text-sm text-red-400">{errors.last_name}</span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <TextField
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      name="company_name"
                      values={values}
                      errors={errors}
                      touched={touched}
                      placeHolder="Company/Trade Name"
                    />

                    <div className="mb-2 fr1_3 items-center">
                      <label htmlFor="primary_contact" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                        Customer Display Name*
                      </label>

                      <select
                        className="appearance-none bg-gray-50 w-full py-1 px-2 outline-none text-f-customer max-w-[500px] leading-[1.6rem]"
                        name="display_name"
                        id="display_name"
                        value={values.display_name}
                        onChange={(e) => {
                          setFieldValue(e.target.name, e.target.value);
                        }}
                      >
                        {!values.display_name && <option value="">Select Display Name</option>}

                        {(values.salutation || values.first_name || values.last_name) && (
                          <option value={`${values.salutation} ${values.first_name} ${values.last_name}`}>
                            {values.salutation} {values.first_name} {values.last_name}
                          </option>
                        )}

                        {values.first_name && (
                          <option value={`${values.first_name} ${values.last_name}`}>
                            {values.first_name} {values.last_name}
                          </option>
                        )}

                        {values.last_name && (
                          <option value={`${values.last_name}${values.first_name && ","} ${values.first_name}`}>
                            {values.last_name} {values.first_name && ","} {values.first_name}
                          </option>
                        )}
                      </select>
                      {errors.display_name && touched.display_name && (
                        <>
                          <span></span>
                          <span className="text-sm text-red-400 pt-1">{errors.display_name}</span>
                        </>
                      )}
                    </div>

                    <TextField
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      name="customer_email"
                      values={values}
                      errors={errors}
                      touched={touched}
                      readonly={emailReadOnly}
                    />

                    <div className="mb-2 fr1_3 items-center">
                      <label htmlFor="work_phone" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                        Customer Phone
                      </label>
                      <div className="flex gap-2 max-w-[500px]">
                        <input
                          type="number"
                          id="work_phone"
                          className="text-f-customer"
                          placeholder="Work Phone"
                          value={values.work_phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <input type="number" id="mobile" className="text-f-customer" placeholder="Mobile" value={values.mobile} onChange={handleChange} onBlur={handleBlur} />
                      </div>
                      {errors.work_phone && touched.work_phone && (
                        <>
                          <span></span>
                          <span className="text-sm text-red-400 pt-1">{errors.work_phone}</span>
                        </>
                      )}
                      {errors.mobile && touched.mobile && (
                        <>
                          <span></span>
                          <span className="text-sm text-red-400 pt-1">{errors.mobile}</span>
                        </>
                      )}
                    </div>

                    <TextField handleChange={handleChange} handleBlur={handleBlur} name="GSTIN" values={values} errors={errors} touched={touched} iType="text" />

                    <div className="mb-2 fr1_3 items-center">
                      <label htmlFor="payment_terms" className="block text-[15px] text-DarkSlateGray dark:text-white">
                        Payment Terms
                      </label>
                      <select
                        className="appearance-none bg-gray-50 w-full py-1 px-2 outline-none text-f-customer max-w-[500px] leading-[1.6rem]"
                        name="payment_terms"
                        value={values.payment_terms}
                        onChange={handleChange}
                      >
                        {!values.payment_terms && <option value="">Select Terms</option>}
                        {paymentTermsList.map((term) => {
                          return (
                            <option key={term.id} id={term.id} value={term.id}>
                              {term.terms_label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <hr className="my-8" />

                    <div className="flex justify-between items-center w-full gap-4 -lg:block">
                      <div className="lg:w-1/2 -lg:mb-8 w-full">
                        <h3 className="text-DarkSlateGray font-semibold py-2 mb-2">BILLING ADDRESS</h3>

                        <TextField2 handleChange={handleChange} handleBlur={handleBlur} name="billing_attention" values={values} errors={errors} touched={touched} />

                        <div className="mb-2 fr1_3">
                          <label htmlFor="billing_address" className="block mb-2 text-[15px]  text-DarkSlateGray dark:text-white">
                            Address
                          </label>
                          <Field
                            component="textarea"
                            id="billing_address"
                            className="customer_textarea text-f-customer max-w-[500px] leading-5"
                            value={values.billing_address}
                          ></Field>
                        </div>

                        <TextField2 handleChange={handleChange} handleBlur={handleBlur} name="billing_city" values={values} errors={errors} touched={touched} />

                        <div className="mb-2 fr1_3 items-center">
                          <label htmlFor="billing_state" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                            State
                          </label>
                          <div className="flex gap-1 max-w-[500px]">
                            <select
                              className="appearance-none bg-gray-50 w-full py-1 px-2 outline-none text-f-customer max-w-[500px] leading-[1.6rem]"
                              id="billing_state"
                              name="billing_state"
                              value={values.billing_state}
                              onChange={handleChange}
                            >
                              {!values.billing_state && <option value="">State</option>}
                              {State.getStatesOfCountry("IN").map((state) => {
                                return (
                                  <option key={state.isoCode} value={state.name}>
                                    {state.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <TextField2 handleBlur={handleBlur} name="billing_country" values={values} errors={errors} touched={touched} readonly={countryReadOnly} />

                        <TextFieldSub
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          name="billing_zip_code"
                          values={values}
                          errors={errors}
                          touched={touched}
                          iType="number"
                        />

                        <TextFieldSub
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          name="billing_address_phone"
                          values={values}
                          errors={errors}
                          touched={touched}
                          iType="number"
                        />

                        {/* <TextField2 handleChange={handleChange} handleBlur={handleBlur} name="billing_fax" values={values} errors={errors} touched={touched} /> */}
                      </div>

                      <div className="lg:w-1/2 w-full">
                        <div className="flex justify-between items-center max-w-[700px] transition-all">
                          <h3 className="text-DarkSlateGray font-semibold py-2 mb-2">SHIPPING ADDRESS</h3>
                          <p
                            className="text-Apple text-sm flex justify-center items-center cursor-pointer hover:font-semibold"
                            onClick={() => copyBillingHandle(setFieldValue, values)}
                          >
                            <BiDownArrowAlt className="text-lg font-bold" />
                            <span>Copy Billing Address</span>
                          </p>
                        </div>

                        <TextField2 handleChange={handleChange} handleBlur={handleBlur} name="shipping_attention" values={values} errors={errors} touched={touched} />

                        <div className="mb-2 fr1_3">
                          <label htmlFor="shipping_address" className="block mb-2 text-[15px]  text-DarkSlateGray dark:text-white">
                            Address
                          </label>
                          <Field
                            component="textarea"
                            id="shipping_address"
                            name="shipping_address"
                            className="customer_textarea text-f-customer max-w-[500px] leading-5"
                            value={values.shipping_address}
                          ></Field>
                        </div>

                        <TextField2 handleChange={handleChange} handleBlur={handleBlur} name="shipping_city" values={values} errors={errors} touched={touched} />

                        <div className="mb-2 fr1_3 items-center">
                          <label htmlFor="shipping_state" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                            State
                          </label>
                          <div className="flex gap-1 max-w-[500px]">
                            <select
                              className="appearance-none bg-gray-50 w-full py-1 px-2 outline-none text-f-customer max-w-[500px] leading-[1.6rem]"
                              id="shipping_state"
                              name="shipping_state"
                              value={values.shipping_state}
                              onChange={handleChange}
                            >
                              {!values.shipping_state && <option value="">State</option>}
                              {State.getStatesOfCountry("IN").map((state) => {
                                return (
                                  <option key={state.isoCode} value={state.name}>
                                    {state.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <TextField2 handleBlur={handleBlur} name="shipping_country" values={values} errors={errors} touched={touched} readonly={countryReadOnly} />

                        <TextFieldSub
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          name="shipping_zip_code"
                          values={values}
                          errors={errors}
                          touched={touched}
                          iType="number"
                        />

                        <TextFieldSub
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          name="shipping_address_phone"
                          values={values}
                          errors={errors}
                          touched={touched}
                          iType="number"
                        />

                        {/* <TextField2 handleChange={handleChange} handleBlur={handleBlur} name="shipping_fax" values={values} errors={errors} touched={touched} /> */}
                      </div>
                    </div>
                    <div className="flex w-full justify-center mb-4 mt-12">
                      {isSubmit ? (
                        <button type="submit" className="text-f-submit w-[80px]" disabled={isSubmit}>
                          <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                        </button>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="text-f-submit"
                            onClick={(e) => {
                              if (values.customer_type === "business") {
                                validateGSTIN(e, values.GSTIN, setFieldError, setFieldTouched);
                              } else {
                                console.log("customer_type 2  ", errors);
                              }
                            }}
                          >
                            {submitBtn}
                          </button>
                        </>
                      )}
                      <button
                        disabled={isSubmit}
                        type="submit"
                        className="text-f-cancel ml-4"
                        onClick={(event) => {
                          event.preventDefault();
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </button>
                      {/* </>
                    )} */}
                    </div>
                  </form>
                );
              }}
            </Formik>
          )
        )}
      </div>
    </div>
  );
};

export default CustomerForm;
