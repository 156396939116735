import * as yup from "yup";

export const initialValues = {
  customer_id: '',
  invoice_date:"",
  terms:"",
  due_date:"",
  tax:"igst",
  tax_type:"",
  // subject:"",
  order_number:"",
  purchase_order: "",
  invoiceId:""
  // tax:" tds|tcs",
  // tax_type:"cgst|sgst|igst|utgst",
};


export const validationSchema = yup.object().shape({
  customer_id : yup.string().required("Customer Name is Required"),
  invoiceId : yup.string().required("Invoice id is Required"),
  invoice_date : yup.string().required("Invoice Date is Required"),
  due_date : yup.string().required("Due Date is Required"),
});