import React, { useEffect, useState } from "react";

import DashboardHeader from "../../components/Dashboard/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { updateItemID } from "../../store/actions/ItemActions";
import ListView from "../../components/Items/ListView";
import GridView from "../../components/Items/GridView";

const Items = () => {
  const [view, setView] = useState("list");
  const ItemsState = useSelector((state) => state.itemsReducers);
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(updateItemID({ id: ""}))  
  },[])
  
  return (
    <>
      <DashboardHeader title="Items" view={view} setView={setView}>
        <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-white">
          <div className={`flex ${ItemsState.id && "lg:grid"} w-full `} style={{gridTemplateColumns:"1fr 3fr"}}>
            <ListView />
            {ItemsState.id && <GridView />}
          </div>
        </div>
      </DashboardHeader>
    </>
  );
};

export default Items;
