import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";

const CustomEditor = ({ data, setData, setRaw }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editor_State) => {
    setEditorState(editor_State);
  };

  useEffect(() => {
    const html = stateToHTML(editorState.getCurrentContent());
    setData(html);
    const raw = convertToRaw(editorState.getCurrentContent());
    setRaw(raw?.blocks[0]?.text);
  }, [editorState]);

  useState(() => {
    const blocksFromHTML = htmlToDraft(data);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditorState(EditorState.createWithContent(state));
  }, []);

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName="!mb-0"
        wrapperClassName="wrapperClassName"
        editorClassName="border p-2 !m-0 !h-64"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ["inline", "fontSize", "list", "textAlign"],
          inline: { options: ["bold", "italic", "underline"] },
          list: { options: ["unordered", "ordered"] },
        }}
      />
    </>
  );
};

export default CustomEditor;
