import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import MuiTableCustomer from "./MuiTableCustomer";
import { useEffect, useState } from "react";
import { InvoiceList } from "../../../services/helpers/invoices";
import { CUSTOMER_LIST } from "../../../mockData";
import Loader from "../../common/Loader";
import Cookies from "js-cookie";

const ListView = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const InvoicesState = useSelector((state) => state.invoicesReducers);
  const [totalPageNumber, setTotalNumber] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  const clickLink = (pathname) => {
    navigate(pathname);
  };

  const [invoiceList, setInvoiceList] = useState([]);

  const getInvoiceList = () => {
    setLoading(true);
    const queryParams = {
      userId:Cookies.get("userId"),
      pageNum: pageNum,
    };
    const paramsData = { queryParams, headers: {},
    isAuthorized: true, };
    InvoiceList(paramsData, (res) => {
      setTotalNumber(res?.data?.pagination?.last_page);
      setInvoiceList(res?.data?.invoiceList);
      setLoading(false);
    });
  };

  const opPageChange = (e, page_number) => {
    e.preventDefault();
    setPageNum(page_number);
  };

  useEffect(() => {
    getInvoiceList();
    
  }, [pageNum]);

  return (
    <div
      className={`w-full  ${InvoicesState.id && "-lg:hidden"} shadow-xl`}
      // style={{backgroundColor:'gray'}}
    >
      <div className="flex justify-between p-2 items-center">
        <div className="text-xl">{!loading && "All Invoices"}</div>
        <div className="flex">
          <button
            type="submit"
            className="text-f-button flex items-center gap-1 "
            onClick={() => {
              clickLink("/invoice/new");
            }}
          >
            <AiOutlinePlusCircle /> New
          </button>
        </div>
      </div>
      <hr />
      <div className="">
        {loading ? (
          <Loader classes="min-h-screen" />
        ) : (
          <MuiTableCustomer
            totalPageNumber={totalPageNumber}
            opPageChange={opPageChange}
            pageNum={pageNum}
            cId={InvoicesState.id}
            invoiceList={invoiceList}
            getInvoiceList={getInvoiceList}
          />
        )}
      </div>
    </div>
  );
};

export default ListView;
