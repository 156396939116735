import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { initialValues, validationSchema } from "../../../common/validate/Invoices/AddInvoices";

import { useNavigate } from "react-router-dom";
import { TextFieldDate } from "../../common/Field/TextField";
import { Autocomplete, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TextField as TextFieldMUI } from "@mui/material";
import { Box } from "@mui/system";
import RowTable from "./RowTable";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "./add.css";
import AddressModal from "./AddressModal";
import { CustomerContactList, CustomerDetailById } from "../../../services/helpers/customers";
import { ProductAllList } from "../../../services/helpers/products";
import { errorToast2 } from "../../Toast";
import Cookies from "js-cookie";
import { TaxListAPI } from "../../../services/helpers/common";

const InvoiceForm = ({
  getCustomerAddressHandle,
  AddressHandle,
  handleClose,
  handleOpen,
  updateItemPercentage,
  updateItemQuantity,
  updateItemList,
  addItemHandle,
  submitForm,
  taxList,
  contactList,
  itemListApi,
  address,
  itemlist,
  subTotalV,
  modalOpen,
  isSubmit,
  setModalOpen,
  setItemList,
  updateItemDescription
}) => {
  const navigate = useNavigate();

  return (
    <div className="text-black relative md:pt-[4.5rem] pb-4 py-8 min-h-screen">
      <h3 className="text-2xl px-4 py-2 my-2 font-semibold text-ChineseBlack">New Invoice</h3>
      <hr />
      <div className="flex p-4 mt-8">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
          {(formik) => {
            const { values, handleChange, handleSubmit, errors, touched, handleBlur, setFieldValue } = formik;
            return (
              <form className="w-full rounded-md rounded-t-none p-2" onSubmit={handleSubmit}>
                {/* <InputRadioBox name="customer_type" /> */}

                <div className="mb-3 fr1_3 items-start">
                  <label htmlFor="customer_type" className="block text-[15px]  text-DarkSlateGray dark:text-white align-top">
                    Customer Name
                  </label>
                  <div className="block gap-1 max-w-[500px]">
                    <Autocomplete
                      className="appearance-none bg-gray-50 w-full py-0 px-0 outline-none grow shrink basis-0 leading-3 mui-autocomplete"
                      name="customer_id"
                      disableClearable
                      options={contactList}
                      getOptionLabel={(option) => option.display_name}
                      onChange={(event, newValue) => {
                        setFieldValue("customer_id", newValue.customer_id);
                        getCustomerAddressHandle(newValue.customer_id);
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextFieldMUI
                          {...params}
                          FormHelperTextProps={{
                            classes: {
                              error: "!text-xs !m-0 !bg-white !font-normal !text-[#ff7373]",
                            },
                          }}
                          error={errors?.customer_id && (touched?.customer_id || touched[":r1:"]) && errors?.customer_id}
                          helperText={errors?.customer_id && (touched?.customer_id || touched[":r1:"]) && errors?.customer_id}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                          className="!border-none !text-[#ff7373]"
                        />
                      )}
                    ></Autocomplete>
                    {values.customer_id && (
                      <div className="p-4 pl-0">
                        <button
                          className="text-outline-button mr-2 -md:mb-2"
                          onClick={(e) => {
                            AddressHandle(e, 1);
                          }}
                        >
                          BILLING ADDRESS
                        </button>
                        <button
                          className="text-outline-button"
                          onClick={(e) => {
                            AddressHandle(e, 2);
                          }}
                        >
                          SHIPPING ADDRESS
                        </button>
                        <AddressModal open={modalOpen} setOpen={setModalOpen} handleClose={handleClose} handleOpen={handleOpen} addressd={address} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-2 fr1_3 items-center">
                  <div className="flex items-center">
                    <label htmlFor="invoiceId" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                      Invoice No
                    </label>
                  </div>
                  <div className="flex gap-4 text-sm max-w-[500px]">
                    <input
                      type="text"
                      id="invoiceId"
                      name="invoiceId"
                      className="text-f-customer grow shrink basis-0 leading-3"
                      placeholder="Enter Invoice No"
                      value={values.invoiceId}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors["invoiceId"] && (values["invoiceId"] || touched["invoiceId"]) && (
                    <>
                      <span> </span>
                      <span className="text-sm text-red-400">{errors["invoiceId"]}</span>
                    </>
                  )}
                </div>

                <TextFieldDate handleChange={handleChange} handleBlur={handleBlur} name="invoice_date" values={values} errors={errors} touched={touched} />

                <TextFieldDate handleChange={handleChange} handleBlur={handleBlur} name="purchase_order" values={values} errors={errors} touched={touched} placeHolder="P/O Date" />

                <div className="mb-2 fr1_3 items-center">
                  <div className="flex items-center">
                    <label htmlFor="order_number" className="block text-[15px]  text-DarkSlateGray dark:text-white">
                      P/O No
                    </label>
                  </div>
                  <div className="flex gap-4 text-sm max-w-[500px]">
                    <input
                      type="text"
                      id="order_number"
                      name="order_number"
                      className="text-f-customer grow shrink basis-0 leading-3"
                      placeholder="Enter P/O No"
                      value={values.order_number}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors["order_number"] && (values["order_number"] || touched["order_number"]) && (
                    <>
                      <span> </span>
                      <span className="text-sm text-red-400">{errors["order_number"]}</span>
                    </>
                  )}
                </div>

                {/* <TextField handleChange={handleChange} handleBlur={handleBlur} name="terms" values={values} errors={errors} touched={touched} /> */}
                <div className="mb-2 fr1_3 items-center">
                  <label htmlFor="terms" className="block text-[15px] text-DarkSlateGray dark:text-white">
                    Terms
                  </label>
                  <select
                    className="appearance-none bg-gray-50 w-full py-1 px-2 outline-none text-f-customer max-w-[500px] leading-[1.6rem]"
                    name="terms"
                    value={values.payment_terms}
                    onChange={handleChange}
                  >
                    {!values.terms && <option value="">Select a Terms</option>}
                    <option value="1">Net 15</option>
                    <option value="2">Net 30</option>
                    <option value="3">Net 45</option>
                    <option value="4">Net 60</option>
                    <option value="5">Due End of the Month</option>
                    <option value="6">Due end of next month</option>
                    <option value="7">Due on Receipt</option>
                  </select>
                </div>

                <TextFieldDate handleChange={handleChange} handleBlur={handleBlur} name="due_date" values={values} errors={errors} touched={touched} />

                <div className="w-full flex flex-col my-12">
                  <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }} className={`${"!mb-0 !rounded-none"}`}>
                      <TableContainer>
                        <Table aria-labelledby="tableTitle" size={"medium"} className={`min-w-[750px]}`}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" className="!font-semibold">
                                ITEM DETAILS
                              </TableCell>
                              <TableCell align="center" className="!font-semibold max-w-[120px]">
                                Qty.
                              </TableCell>
                              <TableCell align="center" className="!font-semibold">
                                RATE{"("}₹{")"}
                              </TableCell>
                              <TableCell align="center" className="!font-semibold">
                                GST
                              </TableCell>
                              <TableCell align="center" className="!font-semibold">
                                CGST{"("}%{")"}
                              </TableCell>
                              <TableCell align="center" className="!font-semibold">
                                SGST{"("}%{")"}
                              </TableCell>
                              <TableCell align="center" className="!font-semibold">
                                IGST{"("}%{")"}
                              </TableCell>
                              <TableCell align="center" className="!font-semibold">
                                AMOUNT{"("}₹{")"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* {setSubTotalV(0) } */}
                            {itemlist?.map((item, index) => (
                              <RowTable
                                key={item.productUniqueId}
                                item={item}
                                setItemList={setItemList}
                                index={index}
                                itemlist={itemlist}
                                updateItemList={updateItemList}
                                updateItemQuantity={updateItemQuantity}
                                products={itemListApi}
                                updateItemPercentage={updateItemPercentage}
                                taxList={taxList}
                                taxType={values.tax}
                                updateItemDescription={updateItemDescription}
                              />
                            ))}
                            <TableRow>
                              <TableCell align="left" className="!align-top">
                                {itemlist.length !== itemListApi.length ? (
                                  <p
                                    className="flex justify-center items-center p-2 px-4 bg-gray-100 text-ChineseBlack text-sm font-semibold cursor-pointer"
                                    onClick={() => {
                                      itemlist.length !== itemListApi.length && addItemHandle();
                                    }}
                                  >
                                    <AiOutlinePlusCircle className="mr-1 text-AppleHover font-semibold text-lg" />
                                    Add Item
                                  </p>
                                ) : (
                                  "No Item Left"
                                )}
                              </TableCell>
                              <TableCell align="center" colSpan={7}>
                                <Table className="bg-gray-100 p-2">
                                  <TableBody>
                                    {/* <TableRow>
                                      <TableCell align="left">Sub Total</TableCell>
                                      <TableCell colSpan={2} align="right">
                                        {subTotalV}
                                      </TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                      <TableCell align="left">
                                        <div className="flex gap-4 text-sm max-w-[500px]">
                                          <label className="flex items-center justify-center cursor-pointer">
                                            <Field type="radio" name="tax" value="igst" /> <span className="pl-1">IGST</span>
                                          </label>
                                          <label className="flex items-center justify-center cursor-pointer">
                                            <Field type="radio" name="tax" value="cgst" /> <span className="pl-1">CGST/SGST</span>
                                          </label>
                                          {/* <label className="flex items-center justify-center cursor-pointer">
                                            <Field type="radio" name="tax" value="sgst" /> <span className="pl-1">SGST</span>
                                          </label> */}
                                        </div>
                                      </TableCell>
                                      <TableCell align="right">
                                        {/* {"+ "}
                                        {((values.tax === "tcs" ? 10 : values.tax === "tds" ? 20 : 0) * subTotalV) / 100} */}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="left" className="!font-bold">
                                        Total ( ₹ )
                                      </TableCell>

                                      <TableCell colSpan={2} align="right" className="!font-bold">
                                        {" = "}
                                        {typeof subTotalV === "number" ? subTotalV?.toFixed(2) + " ₹" : subTotalV}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                </div>

                {/* <div className="mb-2 fr1_3 ">
                  <label htmlFor="subject" className="block mb-2 text-[15px]  text-DarkSlateGray dark:text-white">
                    Customer Notes
                  </label>
                  <Field component="textarea" id="subject" className="customer_textarea text-f-customer max-w-[500px] leading-5" value={values.subject}></Field>
                </div> */}

                <div className="flex w-full justify-center mb-4 mt-12">
                  {isSubmit ? (
                    <button type="submit" className="text-f-submit w-[80px]" disabled={isSubmit}>
                      <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                    </button>
                  ) : (
                    <button type="submit" className="text-f-submit">
                      Save
                    </button>
                  )}
                  <button
                    disabled={isSubmit}
                    type="submit"
                    className="text-f-cancel ml-4"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default InvoiceForm;
