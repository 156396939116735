import React, { useEffect, useState } from "react";
import { updateCustomerID } from "../../../store/actions/CustomerActions";
import { useDispatch, useSelector } from "react-redux";
import { GrClose } from "react-icons/gr";
import { MdOutlineArrowBackIos } from "react-icons/md";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import { CustomerDetailById } from "../../../services/helpers/customers";
import Loader from "../../common/Loader";
import Cookies from "js-cookie";

const GridView = () => {
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const CustomersState = useSelector((state) => state.customersReducers);

  const getCustomerDetails = () => {
    const queryParams = {
      customerId: CustomersState.id,
      userId: Cookies.get('userId')
    };
    const paramsData = { queryParams, headers: {},
    isAuthorized: true, };
    CustomerDetailById(paramsData, (res) => {
      setCustomer(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    CustomersState.id && getCustomerDetails();
  }, [CustomersState]);

  return (
    <div className="block border-l-2 bg-white w-full">
      <div className="flex lg:hidden items-start justify-start mt-2 ml-2">
        <button className="flex font-medium items-center justify-center" onClick={() => dispatch(updateCustomerID({ id: "" }))}>
          <MdOutlineArrowBackIos className="text-sm mr-1 font-bold" />
          back
        </button>
      </div>
      <div className="flex justify-between mt-6 px-2">
        {loading ? (
          <p> </p>
        ) : (
          <p className="text-xl font-semibold">
            {customer.salutation} {customer.firstName} {customer.lastName}
          </p>
        )}
        <div>
          <GrClose className="-lg:hidden cursor-pointer mr-2" onClick={() => dispatch(updateCustomerID({ id: "" }))} />
        </div>
      </div>
      <hr className="mt-10 mb-0" />
      {/* <div className="grid " style={{gridTemplateColumns:"2fr 3fr"}}> */}
      <div className="flex w-full">{loading ? <Loader classes="min-h-screen" /> : <LeftSection customer={customer} />}</div>
    </div>
  );
};

export default GridView;
