export const BILLING_ADDRESS = ["attention", "address", "city", "state", "country", "zip_code", "address_phone", "fax"];

export const CUSTOMER_LIST = [
  { id: "123123test", name: "Akash yadav", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
  { id: "test121212", name: "Micheal Samuel", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
  { id: "test123", name: "Samuel Jacksan", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
  { id: "test345354", name: "Amir Syed", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
  { id: "123tes3453t", name: "Amit Singh", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
  { id: "demosdf2342", name: "Jack Ryan", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
  { id: "test1234", name: "Mayank Singh", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
  { id: "tew3st", name: "Ajeet Kumar", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
  { id: "acsdsftest", name: "Manvendar Pratap", company_name: "innov tech", email: "tech@gmail.com", work_phone: "9834938049", receibales: 4.3, unused_credits: 2.3 },
];

export const ADDRESSDETAILS1 = ["attention", "address", "city", "state", "country", "code", "phone"];

export const CUSTOMER_LIST_DROPDOWN = [
  {
    id: "971292000000014276",
    text: "demo testing",
    email: "techtycho229@gmail.com",
    phone: "",
    mobile: "",
  },
  {
    id: "971292000000014243",
    text: "demo1",
    email: "techtycho229@gmail.com",
    phone: "",
    mobile: "",
  },
  {
    id: "971292000000020001",
    text: "Dr. sdfsd sdfsdf",
    email: "",
    phone: "",
    mobile: "",
  },
];

export const ITEMS = [
  {
    item_id: "971292000000022001",
    name: "new item",
    item_name: "new item",
    unit: "ft",
    status: "active",
    source: "user",
    source_formatted: "User",
    is_linked_with_zohocrm: false,
    zcrm_product_id: "",
    description: "test",
    rate: 111.0,
    rate_formatted: "₹111.00",
    tax_id: "",
    tax_name: "",
    tax_percentage: 0,
    product_type: "goods",
    product_type_formatted: "Goods",
    has_attachment: false,
    sku: "",
    image_name: "",
    image_type: "",
    image_document_id: "",
    created_time: "2023-01-02T14:55:37+0530",
    last_modified_time: "2023-01-02T14:55:37+0530",
    show_in_storefront: false,
    quantity: 1,
  },
  {
    item_id: "971292000000019001",
    name: "Product four",
    item_name: "Product four",
    unit: "box",
    status: "active",
    source: "user",
    source_formatted: "User",
    is_linked_with_zohocrm: false,
    zcrm_product_id: "",
    description: "",
    rate: 200.0,
    rate_formatted: "₹200.00",
    tax_id: "",
    tax_name: "",
    tax_percentage: 0,
    product_type: "service",
    product_type_formatted: "Service",
    has_attachment: false,
    sku: "",
    image_name: "",
    image_type: "",
    image_document_id: "",
    created_time: "2022-12-29T10:42:27+0530",
    last_modified_time: "2022-12-29T10:42:27+0530",
    show_in_storefront: false,
    quantity: 1,
  },
  {
    item_id: "971292000000014362",
    name: "product three",
    item_name: "product three",
    unit: "box",
    status: "active",
    source: "user",
    source_formatted: "User",
    is_linked_with_zohocrm: false,
    zcrm_product_id: "",
    description: "product three description",
    rate: 12.0,
    rate_formatted: "₹12.00",
    tax_id: "",
    tax_name: "",
    tax_percentage: 0,
    product_type: "goods",
    product_type_formatted: "Goods",
    has_attachment: false,
    sku: "",
    image_name: "",
    image_type: "",
    image_document_id: "",
    created_time: "2022-08-01T14:44:51+0530",
    last_modified_time: "2022-08-01T14:44:51+0530",
    show_in_storefront: false,
    quantity: 1,
  },
  {
    item_id: "971292000000014293",
    name: "product Two",
    item_name: "product Two",
    unit: "box",
    status: "active",
    source: "user",
    source_formatted: "User",
    is_linked_with_zohocrm: false,
    zcrm_product_id: "",
    description: "product two description",
    rate: 100.0,
    rate_formatted: "₹100.00",
    tax_id: "",
    tax_name: "",
    tax_percentage: 0,
    product_type: "goods",
    product_type_formatted: "Goods",
    has_attachment: false,
    sku: "",
    image_name: "",
    image_type: "",
    image_document_id: "",
    created_time: "2022-08-01T12:49:13+0530",
    last_modified_time: "2022-08-01T12:49:13+0530",
    show_in_storefront: false,
    quantity: 1,
  },
  {
    item_id: "971292000000014234",
    name: "productOne",
    item_name: "productOne",
    unit: "box",
    status: "active",
    source: "user",
    source_formatted: "User",
    is_linked_with_zohocrm: false,
    zcrm_product_id: "",
    description: "product one description",
    rate: 100.0,
    rate_formatted: "₹100.00",
    tax_id: "",
    tax_name: "",
    tax_percentage: 0,
    product_type: "goods",
    product_type_formatted: "Goods",
    has_attachment: false,
    sku: "",
    image_name: "",
    image_type: "",
    image_document_id: "",
    created_time: "2022-08-01T12:26:48+0530",
    last_modified_time: "2022-08-01T12:26:48+0530",
    show_in_storefront: false,
    quantity: 1,
  },
];
export const ITEM_LIST = [
  { id: "123456", type: "Goods", name: "Jack", unit: "kg", selling_price: 25000, description: "hello this description" },
  { id: "123457", type: "Service", name: "Tom", unit: "kg", selling_price: 30000, description: "hello this description" },
  { id: "123458", type: "Goods", name: "Millin", unit: "kg", selling_price: 25000, description: "hello this description" },
  { id: "123459", type: "Goods", name: "Harry", unit: "kg", selling_price: 40000, description: "hello this description" },
];

export const HEAD_CELLS_CUSTOMERS = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    icon: false,
    left: true,
  },
  {
    id: "company_name",
    numeric: false,
    disablePadding: true,
    label: "Company Name",
    icon: false,
    left: true,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    icon: false,
    left: true,
  },
  {
    id: "work_phone",
    numeric: false,
    disablePadding: false,
    label: "Work Phone",
    icon: false,
    left: true,
  },

  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Action",
    icon: false,
    left: false,
  },
];

export const HEAD_CELLS_INVOICES = [
  {
    id: "invoice_id",
    numeric: false,
    disablePadding: true,
    label: "Invoice No",
    icon: false,
    left: true,
  },
  {
    id: "customer_name",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
    icon: false,
    left: true,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    icon: false,
    left: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    icon: false,
    left: true,
  },
  {
    id: "due_date",
    numeric: false,
    disablePadding: false,
    label: "Due Date",
    icon: false,
    left: true,
  },

  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    icon: false,
    left: true,
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Action",
    icon: false,
    left: false,
  },
];

export const UPI_MOCKDATA = [
  {
    upi_id: "",
    upi_number: "",
    upi_type: "",
  },
];

export const Bank_Init = [
  {
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    account_holder: "",
    address: ""
  },
];

export const Account_Error = {
  bank_name: {},
  account_number: {},
  ifsc_code: {},
  account_holder: {},
  address: {}
};

export const UPI_Error = {
  upi_id: {},
  upi_number: {},
  upi_type: {},
};

export const GST_PER_ITEMS = [
  {
      "id": 1,
      // "label": "Zero",
      "label": "0%",
      "percentage": 0,
      "status": 1
  },
  {
      "id": 2,
      // "label": "Five",
      "label": "5%",
      "percentage": 5,
      "status": 1
  },
  {
      "id": 3,
      // "label": "Twelve",
      "label": "12%",
      "percentage": 12,
      "status": 1
  },
  {
      "id": 4,
      // "label": "Eighteen",
      "label": "18%",
      "percentage": 18,
      "status": 1
  },
  {
      "id": 5,
      // "label": "Twenty Eight",
      "label": "28%",
      "percentage": 28,
      "status": 1
  },
]