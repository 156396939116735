import Llogo from "../../assets/img/logo.png";

const FormLogo = () => {
  return (
    <div className="w-[40%] mb-8">
      <img src={Llogo} className="" alt="logoForm" />
    </div>
  );
};

export default FormLogo;
