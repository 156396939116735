import { initialValuesC, validationSchemaC } from "../../common/validate/Profile";
import { Formik } from "formik";
import { UserChangePassword } from "../../services/helpers/users";
import LocalStorage from "../../services/local_storage";
import Cookies from "js-cookie";
import { BiHide, BiShow } from "react-icons/bi";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const ChangePassword = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const localstorage = new LocalStorage();
  const [passwordToggle, setPasswordToggle] = useState({
    currentpassword: true,
    newpassword: true,
  });
  const submitForm = (values) => {
    setIsSubmit(true);
    const payload = {
      email: Cookies.get("email"),
      oldPassword: values.current_assword,
      newPassword: values.newPassword,
      confirmPassword: values.newPassword,
    };
    UserChangePassword(payload, (res) => {
      setIsSubmit(false);
      // let username = res.data.firstName + " " + res.data.lastName;
      if (res.status == true) {
        localstorage.setLogin(true, Cookies.get("token"), Cookies.get("userId"), Cookies.get("userName"), Cookies.get("email"));
      }
      // localstorage.setLogout();
      // window.location.reload();
    });
  };

  const passwordToggleHandle = (name) => {
    setPasswordToggle((prev) => {
      return {
        ...prev,
        [name]: !prev[name],
      };
    });
  };

  return (
    <div className="text-black relative pt-8 px-4 pb-[12rem]  transition-all duration-1000 bg-AntiFlashWhite">
      <div className="flex w-full justify-center mb-4 mt-12">
        <button
          type="button"
          className="text-xl flex justify-between text-white bg-ChineseBlack hover:bg-ChineseBlack focus:outline-none focus:ring-blue-300 font-medium rounded px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 outline-none w-full text-left"
        >
          Change Password
        </button>
      </div>

      <div className={`transition-all duration-1000`}>
        <Formik initialValues={initialValuesC} validationSchema={validationSchemaC} onSubmit={submitForm}>
          {(formik) => {
            const { values, handleChange, handleSubmit, errors, touched, handleBlur } = formik;
            return (
              <form className="w-full  rounded-md rounded-t-none p-2" onSubmit={handleSubmit}>
                <div className="flex gap-4 -lg:flex-col">
                  <div className="mb-6 grow">
                    <label htmlFor="current_assword" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                      Current Password
                    </label>
                    <div className="relative">
                      <input
                        type={!passwordToggle.currentpassword ? "text" : "password"}
                        id="current_assword"
                        className="text-f-style"
                        placeholder="Enter confirm Password"
                        value={values.current_assword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {passwordToggle.currentpassword ? (
                        <BiShow className="absolute top-[12px] right-[10px] cursor-pointer" onClick={() => passwordToggleHandle("currentpassword")} />
                      ) : (
                        <BiHide className="absolute top-[12px] right-[10px] cursor-pointer" onClick={() => passwordToggleHandle("currentpassword")} />
                      )}
                    </div>
                    {errors.current_assword && touched.current_assword && <span className="text-sm text-red-400">{errors.current_assword}</span>}
                  </div>
                  <div className="mb-6 grow">
                    <label htmlFor="new" className="block mb-2 text-base font-medium text-DarkSlateGray dark:text-white">
                      New Password
                    </label>
                    <div className="relative">
                      <input
                        type={!passwordToggle.newpassword ? "text" : "password"}
                        id="newPassword"
                        className="text-f-style"
                        placeholder="Enter new Password"
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {passwordToggle.newpassword ? (
                        <BiShow className="absolute top-[12px] right-[10px] cursor-pointer" onClick={() => passwordToggleHandle("newpassword")} />
                      ) : (
                        <BiHide className="absolute top-[12px] right-[10px] cursor-pointer" onClick={() => passwordToggleHandle("newpassword")} />
                      )}
                    </div>
                    {errors.newPassword && touched.newPassword && <span className="text-sm text-red-400">{errors.newPassword}</span>}
                  </div>
                </div>

                <div className="flex w-full justify-center mb-4 mt-12">
                  {isSubmit ? (
                    <button type="submit" className="text-f-submit w-[80px]" disabled={isSubmit}>
                      <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
                    </button>
                  ) : (
                    <button type="submit" className="text-f-submit">
                      Change Password
                    </button>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
