import * as yup from "yup";


const charOnly = (value) => /^[A-Za-z ]+$/.test(value); 
export const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required").test("Char only", "Name should have Characters only", charOnly),
  // number: yup.string().required("Number is required").min(10, 'Number must be at least 10 digits')
  number: yup.string().min(10, 'Number must be at least 10 digits')
  .max(10, 'Number must not exceed 10 digits'),
  // aadharnumber: yup
  //   .string()
  //   .matches(
  //     "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
  //     "please enter a valid Aadhar Number"
  //   ),
  pancard: yup
    .string()
    .matches("[A-Z]{5}[0-9]{4}[A-Z]{1}", "please enter a valid Pan Number")
    .max(10,"please enter a valid Pan Number"),
  gstin: yup
    .string()
    // .required("GSTIN is required")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Given GSTIN Invalid"
    )
    // .test("GSTIN", "Given GSTIN Invalid", function (value) {
    //   return value ? value.includes(this.parent.pancard) : true;
    // })
    .max(15,"Given GSTIN Invalid"),
  website: yup.string().url(),
});

export const initialValuesC = {
  current_assword: "",
  newPassword: "",
};

export const validationSchemaC = yup.object().shape({
  current_assword: yup.string().required("Current Password is required")
  .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
  newPassword: yup.string().required("New Password is required").min(6, 'Password must be at least 6 characters')
  .max(40, 'Password must not exceed 40 characters'),
});
