export const updateItemName = (data) => ({
    type: "ITEMUPDATE",
    payload: data,
})

export const updateItemID = (data) => ({
    type: "ITEMUPDATEID",
    payload: data,
})

export const SINGLEITEM = (data) => ({
    type: "SINGLEITEM",
    payload: data,
})