import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { GrView } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MuiTableHeadCustomer from "./MuiTableHeadCustomer";
import { DeleteInvoiceById } from "../../../services/helpers/invoices";
import { Pagination } from "@mui/material";
import { updateInvoiceID } from "../../../store/actions/InvoiceAction";
import Cookies from "js-cookie";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const MuiTableInvoice = ({ cId, invoiceList, totalPageNumber, opPageChange, pageNum, getInvoiceList }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = invoiceList.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const invoiceHandle = (id) => {
    dispatch(updateInvoiceID({ id }));
  };
  const handleClick = (event, id) => {
    setSelected(id);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const invoiceDeleteHandle = (id) => {
    const choice = window.confirm("Are you sure you want to delete invoice?");
    if (choice) {
      const queryParams = {
        inv_uni_id: id,
        userId: Cookies.get('userId')
      };
      const paramsData = { queryParams, headers: {},
      isAuthorized: true, };
      DeleteInvoiceById(paramsData, (res) => {
        setTimeout(() => {
          navigate("/invoices");
          getInvoiceList();
        }, 200);
      });
    }
  };

  const invoiceEditHandle = (id) => {
    navigate("/invoice/" + id);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {invoiceList?.length > 0 ? (
        <Paper sx={{ width: "100%", mb: 2 }} className={`${"min-h-screen !mb-0 !rounded-none"}`}>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={"medium"} className={`${!cId && "min-w-[750px]"}`}>
              {!cId && (
                <MuiTableHeadCustomer
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={invoiceList.length}
                  cId={cId}
                />
              )}
              <TableBody>
                {stableSort(invoiceList, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.inv_id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={row.inv_id == selected}
                    >
                      <TableCell className="!pl-4 cursor-pointer" component="th" id={labelId} scope="row" onClick={() => invoiceHandle(row.inv_uni_id)}>
                        {row.inv_id}
                      </TableCell>

                      <TableCell className="!pl-4 cursor-pointer" component="th" id={labelId} scope="row" onClick={() => invoiceHandle(row.inv_uni_id)}>
                        {row.customer_ame}
                      </TableCell>

                      {!cId && <TableCell align="left">{row.date}</TableCell>}
                      {!cId && (
                        <TableCell align="left" className="uppercase">
                          {row.status == 0 ? "Draft" : "Paid"}
                        </TableCell>
                      )}
                      {!cId && <TableCell align="left">{row.due_date}</TableCell>}

                      {!cId && <TableCell align="left">{row?.amount?.toFixed(2)}</TableCell>}
                      {!cId && (
                        <TableCell align="right" className="!p-2">
                          <div className="flex items-center justify-end">
                            <GrView className="mr-4 text-xl text-Apple cursor-pointer" onClick={() => invoiceHandle(row.inv_uni_id)} />
                            <FiEdit className="mr-4 text-xl text-Apple cursor-pointer" onClick={() => invoiceEditHandle(row.inv_uni_id)} />
                            {/* <MdDelete className="text-xl text-red-600 cursor-pointer" onClick={() => invoiceDeleteHandle(row.inv_id)} /> */}
                            <MdDelete className="text-xl text-red-600 cursor-pointer" onClick={() => invoiceDeleteHandle(row.inv_uni_id)} />
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {totalPageNumber > 0 && (
            <div className="flex items-center justify-center py-5">
              <Pagination className="flex" count={totalPageNumber} page={pageNum} onChange={(e, page_number) => opPageChange(e, page_number)} color="primary" />
            </div>
          )}
        </Paper>
      ) : (
        <Paper sx={{ width: "100%", mb: 2 }} className={`${"min-h-screen !mb-0 !rounded-none"}`}>
          <h1 className="text-center py-12 pt-[12rem] text-lg font-bold">No data Found</h1>
        </Paper>
      )}
    </Box>
  );
};

export default MuiTableInvoice;
