const initialState = {
  isInvoiceselected: false,
  id: "",
  InvoiceName: "",
};

function CustomersReducers(state = initialState, action) {
  switch (action.type) {
    case "CustomerUpdate":
      return {
        ...state,
        InvoiceName: action.payload.name,
      };
    case "CUSTOMERUPDATEID":
      return {
        ...state,
        id: action.payload.id,
      };
    default:
      return state;
  }
}

export default CustomersReducers;
