import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { updateItemID } from "../../../store/actions/ItemActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteProductById } from "../../../services/helpers/products";
import { Pagination } from "@mui/material";
import Cookies from "js-cookie";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    icon: false,
    left: true,
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description",
    icon: false,
    left: true,
  },
  {
    id: "selling_price",
    numeric: false,
    disablePadding: false,
    label: "Rate",
    icon: false,
    left: true,
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    label: "Usage Unit",
    icon: false,
    left: true,
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Action",
    icon: false,
    left: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, cId } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="bg-gray-200">
      <TableRow>
        {headCells.map((headCell, i) =>
          cId ? (
            headCell.id == "name" && (
              <TableCell key={headCell.id} align={headCell.left ? "left" : "right"} padding={headCell.disablePadding ? "none" : "normal"} className="!pl-4">
                {headCell.icon ? (
                  <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <p>{headCell.label}</p>
                )}
              </TableCell>
            )
          ) : (
            <TableCell key={headCell.id} align={headCell.left ? "left" : "right"} padding={headCell.disablePadding ? "none" : "normal"} className="!pl-4 !font-semibold">
              {headCell.icon ? (
                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <p>{headCell.label}</p>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

const MuiTableItem = ({ cId, opPageChange, productListItem, pageNum, totalPageNumber, getProductList }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState("");
  // const [productId, setProductId] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = productListItem.map((n) => n.productName);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const itemViewHandle = (id) => {
    dispatch(updateItemID({ id }));
  };
  const handleClick = (event, id) => {
    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }

    setSelected(id);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const itemDeleteHandle = (productId) => {
    const choice = window.confirm("Are you sure you want to delete Item?");
    if (choice) {
      const queryParams = {
        productId: productId,
        userId: Cookies.get("userId"),
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      DeleteProductById(paramsData, (res) => {
        setTimeout(() => {
          navigate("/items");
          getProductList();
        }, 200);
      });
    }
  };
  const itemEditHandle = (id) => {
    navigate("/item/" + id);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {productListItem?.length > 0 ? (
        <Paper sx={{ width: "100%", mb: 2 }} className={`${"min-h-screen !mb-0 !rounded-none"}`}>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={"medium"} className={`${!cId && "min-w-[750px]"}`}>
              {!cId && (
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={productListItem.length}
                  cId={cId}
                />
              )}
              <TableBody>
                {stableSort(productListItem, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.productUniqueId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.productName}
                      selected={row.productUniqueId == selected}
                    >
                      <TableCell className="!pl-4 cursor-pointer" component="th" id={labelId} scope="row" onClick={() => itemViewHandle(row.productUniqueId)}>
                        {row.productName}
                      </TableCell>
                      {!cId && <TableCell align="left">{row.productDescription}</TableCell>}
                      {!cId && <TableCell align="left">{typeof row.sellingPrice == 'number' ? row.sellingPrice?.toFixed(2) : row.sellingPrice}</TableCell>}
                      {!cId && <TableCell align="left">{row.productUnit}</TableCell>}
                      {!cId && (
                        <TableCell align="right" className="!p-2">
                          <div className="flex items-center justify-end">
                          <GrView className="mr-4 text-xl text-Apple cursor-pointer" onClick={() => itemViewHandle(row.productUniqueId)} />
                            <FiEdit
                              className="mr-2 text-xl text-Apple cursor-pointer"
                              onClick={() => {
                                itemEditHandle(row.productUniqueId);
                              }}
                            />
                            <MdDelete className="text-xl text-red-600 cursor-pointer" onClick={() => itemDeleteHandle(row.productUniqueId)} />
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {totalPageNumber > 0 && (
            <div className="flex items-center justify-center py-5">
              <Pagination className="flex" count={totalPageNumber} page={pageNum} onChange={(e, page_number) => opPageChange(e, page_number)} color="primary" />
            </div>
          )}
        </Paper>
      ) : (
        <Paper sx={{ width: "100%", mb: 2 }} className={`${"min-h-screen !mb-0 !rounded-none"}`}>
          <h1 className="text-center py-12 pt-[12rem] text-lg font-bold">No data Found</h1>
        </Paper>
      )}
    </Box>
  );
};

export default MuiTableItem;
