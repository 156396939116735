const initialState = {
    isItemselected: false,
    id: "",
    itemName: "",
    item: {}
  };
  
  function ItemsReducers(state = initialState, action) {
    switch (action.type) {
      case "ITEMUPDATE":
        return {
          ...state,
          itemName: action.payload.name,
        };
      case "ITEMUPDATEID":
        return {
          ...state,
          id: action.payload.id,
        };
        case "SINGLEITEM":
          return {
            ...state,
            item: action.payload.item,
          };
          
      default:
        return state;
    }
  }
  
  export default ItemsReducers;
  