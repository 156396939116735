import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GrClose } from "react-icons/gr";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { InvoiceDownloadById } from "../../../services/helpers/invoices";
import { updateInvoiceID } from "../../../store/actions/InvoiceAction";
import Loader from "../../common/Loader";
import { BiCloudDownload } from "react-icons/bi";
import "./style.css";
import Cookies from "js-cookie";

const GridView = () => {
  const [loading, setLoading] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  // const [invoiceDetailsParse, setInvoiceDetailsParse] = useState({});
  const dispatch = useDispatch();
  const InvoiceState = useSelector((state) => state.invoicesReducers);

  const getInvoiceDetails = () => {
    const queryParams = {
      inv_uni_id: InvoiceState.id,
      userId: Cookies.get("userId"),
    };
    const paramsData = { queryParams, headers: {}, isAuthorized: true };
    // InvoiceDetailById(paramsData, (res) => {
    InvoiceDownloadById(paramsData, (res) => {
      setInvoiceDetails(res.data);
      setLoading(false);
    });
    // pdfDownloadHandle()
  };

  useEffect(() => {
    setLoading(true);
    InvoiceState.id && getInvoiceDetails();
  }, [InvoiceState]);

  const pdfDownloadHandle = () => {
    window.open(invoiceDetails.invoiceLink, "_blank");
  };

  return (
    <>
      <div className="block border-l-2 bg-white w-full overflow-x-auto">
        <div className="flex lg:hidden items-start justify-start mt-2 ml-2">
          <button className="flex font-medium items-center justify-center" onClick={() => dispatch(updateInvoiceID({ id: "" }))}>
            <MdOutlineArrowBackIos className="text-sm mr-1 font-bold" />
            back
          </button>
        </div>
        <div className="flex justify-between mt-6 px-2">
          <p className="text-xl font-semibold"> </p>
          <div>
            <GrClose className="-lg:hidden cursor-pointer mr-2" onClick={() => dispatch(updateInvoiceID({ id: "" }))} />
          </div>
        </div>
        <hr className="mt-10 mb-0" />
        <div className="flex flex-col ">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="mx-5 my-2">
                <div className="flex justify-end">
                  <button className="text-f-button flex items-center" onClick={pdfDownloadHandle}>
                    <BiCloudDownload className="mr-2 text-lg" /> Download pdf
                  </button>
                </div>
              </div>
              <div className="p-1 pdfgenerate">
                <div dangerouslySetInnerHTML={{ __html: invoiceDetails.invoiceView }}></div>
              </div>
              {/* <div className="p-1">
                <TestGrid />
              </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GridView;
