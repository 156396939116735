import { AccordionDetails } from "@mui/material";
import React, { useEffect, useState } from "react";

const AddressAccordionDetails = ({items, type, address}) => {
  const [addressd, setAddress] = useState(null)

  useEffect(()=>{
    let add = address?.filter((add)=> { return add.type == type})
    add && setAddress(add[0])
  },[])

  return (
    <div
      className="grid w-full"
      style={{
        gridGap: "10px",
        gridTemplateColumns: " 1fr 2fr",
      }}
    > {addressd && 
    <>
      <div>
        {items?.map((item,i)=>{
            return <p key={i} className="capitalize">{item}</p>    
        })}
        
      </div>
      <div>
        <p className="">{addressd.attention }  &nbsp; </p>
        <h5 className="">{addressd.address } &nbsp;</h5>
        <p className="">{addressd.city } &nbsp;</p>
        <h5 className="">{addressd.state } &nbsp;</h5>
        <h5 className="">{addressd.country } &nbsp;</h5>
        <h5 className="">{addressd.zip_code != '0' &&  addressd.zip_code } &nbsp;</h5>
        <h5 className="">{addressd.address_phone } &nbsp;</h5>
      </div>
      </> 
      }
    </div>
  );
};

export default AddressAccordionDetails;
