import * as yup from "yup";

export const initialValues = {
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    account_holder: "",
    // account_email:"",
    // address:"",
    
    bank_name2: "",
    account_number2: "",
    ifsc_code2: "",
    account_holder2: "",
    // account_email2:"",
    // address2:"",
    
  };
  
  const charOnly = (value) => /^[A-Za-z ]+$/.test(value); 

  export const validationSchema = yup.object().shape({
    bank_name: yup.string().test("Char only", "Bank Name should have Characters only", charOnly).max(80, "Bank Name characters limit exceeded"),
    bank_name2: yup.string().test("Char only", "Bank Name should have Characters only", charOnly).max(80, "Bank Name characters limit exceeded"),
    
    account_number: yup.string().min(12, "Invalid Account Number").max(18, "Invalid Account Number"),
    account_number2: yup.string().min(12, "Invalid Account Number").max(18, "Invalid Account Number"),
    
    ifsc_code: yup.string().min(11, "Invalid IFSC Code").max(11, "Invalid IFSC Code"),
    ifsc_code2: yup.string().min(11, "Invalid IFSC Code").max(11, "Invalid IFSC Code"),

    account_holder: yup.string().test("Char only", "Holder name should have Characters only", charOnly).max(80, "Holder Name characters limit exceeded"),
    account_holder2: yup.string().test("Char only", "Holder name should have Characters only", charOnly).max(80, "Holder Name characters limit exceeded"),
    // number: yup.string().required("Number is required").min(10, 'Number must be at least 10 digits')

});
