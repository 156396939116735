import AccountForm from "./AccountForm";
import { CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Account_Error } from "../../../mockData";
import Cookies from "js-cookie";
import { MdExpandMore } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { accountnumberValidate, holderValidate, ifscValidate, nameValidate } from "../../../common/helpers/accountValidate";
import { UserAccountUpdate } from "../../../services/helpers/users";
import { errorToast } from "../../Toast";

const AccountList = ({ accountList, setAccountList, setLoading, RemoveAccountHandle, setAccountRefrech }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorAccount, setErrorAccount] = useState(Account_Error);
  const submitForm = (e) => {
    setErrorAccount((prev) => Account_Error);

    let newAccountDATA = accountList.filter((data) => data.bank_name !== "" || data.account_number !== "" || data.ifsc_code !== "" || data.account_holder !== "");

    newAccountDATA.forEach((data, index) => {
      const BankName = nameValidate(data.bank_name);
      const AccountNumber = accountnumberValidate(data.account_number);
      const IFSC = ifscValidate(data.ifsc_code);
      const HolderName = holderValidate(data.account_holder);
      if (BankName) {
        setErrorAccount((prev) => {
          return {
            ...prev,
            bank_name: {
              ...prev.bank_name,
              [data.id_r]: BankName?.Error,
            },
          };
        });
      }
      if (AccountNumber) {
        setErrorAccount((prev) => {
          return {
            ...prev,
            account_number: {
              ...prev.account_number,
              [data.id_r]: AccountNumber?.Error,
            },
          };
        });
      }
      if (IFSC) {
        setErrorAccount((prev) => {
          return {
            ...prev,
            ifsc_code: {
              ...prev.ifsc_code,
              [data.id_r]: IFSC?.Error,
            },
          };
        });
      }
      if (HolderName) {
        setErrorAccount((prev) => {
          return {
            ...prev,
            account_holder: {
              ...prev.account_holder,
              [data.id_r]: HolderName?.Error,
            },
          };
        });
      }
    });
    setTimeout(() => {
      if (errorExpandedHandle() === null) {
        if (newAccountDATA.length > 0) {
          setIsSubmit(true);
          let bankDetails = newAccountDATA.map((account) => {
            const { id_r, ...acc } = account;
            return { ...acc, id: account?.id || "" };
          });
          const payload = {
            user_id: Cookies.get("userId"),
            bankDetails: bankDetails,
          };
          const paramsData = {
            queryParams: {},
            headers: {},
            isAuthorized: true,
          };
          UserAccountUpdate(payload, paramsData, (res) => {
            setIsSubmit(false);
            if (res.status === true) {
              setAccountRefrech((prev) => !prev);
            }
          });
        } else {
          errorToast("Please fill account details");
        }
      } else {
        errorToast("Please check account details");
      }
    }, 200);
  };

  const HandleChangeText = (e, id) => {
    let value = e.target.value;
    let name = e.target.name;
    setAccountList((prev) => {
      let newPrev = prev.map((accountPrev, i) => {
        let checkChnage = accountPrev.id_r === id;
        return checkChnage
          ? {
              ...accountPrev,
              [name]: value,
            }
          : { ...accountPrev };
      });
      return newPrev;
    });
    // console.log("erorrr  ===>", errorUPI)
  };

  const HandleFocusText = (e, id) => {};

  const errorExpandedHandle = () => {
    let errorCount = [];
    Object.keys(errorAccount).forEach((err, i) => {
      Object.keys(errorAccount[err]).length > 0 && errorCount.push(i);
    });
    return errorCount.length == 0 ? null : errorCount.length;
  };

  useEffect(()=>{
    setErrorAccount((prev) => Account_Error)
  },[accountList])

  return (
    <div>
      <form
        className="w-full  rounded-md rounded-t-none "
        onSubmit={(e) => {
          e.preventDefault();
          submitForm(e);
        }}
      >
        {accountList.map((accountDetails, i) => {
          return (
            <div key={accountDetails.id_r}>
              <Accordion  defaultExpanded={errorExpandedHandle() !== null ? true : i === 0 && true}>
                <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel2a-content" id="panel2a-header" className="!bg-BrightGray !rounded-t-sm">
                  <Typography>Account {i + 1}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {accountList.length > 1 && (
                    <div className="flex justify-end mt-2">
                      <button
                        type="button"
                        className="text-f-button flex items-center gap-1 bg-red-300 hover:bg-red-500 w-auto"
                        onClick={() => {
                          RemoveAccountHandle(accountDetails);
                        }}
                      >
                        <IoClose stroke="white" />
                      </button>
                    </div>
                  )}
                  <AccountForm
                    key={accountDetails.id_r}
                    accountDetails={accountDetails}
                    HandleChangeText={HandleChangeText}
                    errorAccount={errorAccount}
                    setErrorAccount={setErrorAccount}
                    HandleFocusText={HandleFocusText}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}

        <div className="flex w-full justify-center mb-4 mt-12">
          {isSubmit ? (
            <button type="submit" className="text-f-submit w-[140px]" disabled={isSubmit}>
              <CircularProgress color="inherit" style={{ width: "20px", height: "20px" }} />
            </button>
          ) : (
            // accountSubmitStatus ?
            <button type="submit" className="text-f-submit">
              Update Account
            </button>
            // :
            // <button type="submit" className="text-f-submit">
            //   Add
            // </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default AccountList;
